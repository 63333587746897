import React, { useState } from "react";
import { Modal, Row, Col, Button, Select } from "antd";
import { connect } from "react-redux";
import { getBreakTypeList, getRoleList, getUserRoleId } from "redux/selectors";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { intersection } from "lodash";
import { ButtonFilled, ButtonOutlined } from "reusableComponent/UIButtons";

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
  }
  .ant-modal-title {
    font-weight: 600;
    font-size: 1.1rem;
  }
  .ant-modal-body {
    padding-bottom: 75px;
  }
  .ant-modal-footer {
    border-top: none;
    padding-bottom: 20px;
  }
`;

const StyledStartButton = styled(Button)`
  width: 100px;
`;

const StyledCancelButton = styled(Button)`
  width: 100px;
`;

const StyledErrorMessage = styled.div`
  color: ${COLORS.ERROR};
  padding: 10px 5px;
`;

function BreakModal({
  isModalVisible,
  closeModal,
  startBreak,
  breakTypeList,
  roleList,
  userRoleId,
}) {
  const [breakType, setBreakType] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const filterrolelist = roleList?.filter((val) => val?.roleId === userRoleId);
  const filterdepartmentid = filterrolelist[0]?.department;
  const filterlocationid = filterrolelist[0]?.locationId;

  const filterbreaks = [];
  breakTypeList?.forEach((item) => {
    if (item?.departmentId?.length > 0 && item?.locationId?.length > 0) {
      if (
        intersection(item?.departmentId, filterdepartmentid)?.length > 0 &&
        intersection(item?.locationId, filterlocationid)?.length > 0
      ) {
        filterbreaks.push(item);
      }
    } else {
      if (intersection(item?.locationId, filterlocationid)?.length > 0) {
        filterbreaks.push(item);
      }
    }
  });

  const handleOk = () => {
    if (breakType) {
      startBreak(breakType);
    } else {
      setErrorMessage("Select break type");
    }
  };

  return (
    <StyledModal
      title="Choose your Break"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={closeModal}
      width={400}
      footer={[
        <Row key="footer">
          <div className="d-flex justify-content-end w-100">
            <div>
              <ButtonOutlined onClick={closeModal}>Cancel</ButtonOutlined>
            </div>
            <div>
              <ButtonFilled onClick={handleOk}>Confirm</ButtonFilled>
            </div>
          </div>
        </Row>,
      ]}
    >
      <Select
        style={{ width: "100%" }}
        placeholder="Select Break"
        options={filterbreaks}
        onChange={(breakId) => {
          setBreakType(breakId);
        }}
        fieldNames={{
          label: "breakName",
          value: "breakId",
        }}
      />

      <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
    </StyledModal>
  );
}

const mapStateToProps = (state) => ({
  breakTypeList: getBreakTypeList(state),
  userRoleId: getUserRoleId(state),
  roleList: getRoleList(state),
});

export default connect(mapStateToProps, null)(BreakModal);
