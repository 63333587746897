import React, { useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { styled } from "@mui/system";
import NewSubTab from "./recruitmentTab/NewSubContent.js";
import { find, get } from "lodash";
import { Divider } from "@mui/material";

const CustomizeMainTab = styled(Tab)({
  textTransform: "none",
  background: "#E3E8FC",
  marginRight: "15px",
  borderRadius: "15px 15px 0px 0px",
  color: "#1F243B",
  "&.MuiTabs-indicator": {
    display: "none",
  },
  "&.Mui-selected": {
    color: "#2D53DA",
    borderBottom: "none",
  },
});

const TabStyle = styled("div")({
  ".recruitment_tab_name": {
    cursor: "pointer",
  },
});

function RecruitmentTab({
  candidateList,
  processList,
  jobList,
  getData,
  goBack,
  selectedItem,
  activeTab,
  setShowComponent,
  onboardCandidate,
  index = 0,
}) {
  const [value, setValue] = useState(0);

  const urlParams = new URLSearchParams(window.location.search);
  const [Process, setProcess] = React.useState([]);
  const getTabName = urlParams.get("id");
  const getTableRecord = urlParams.get("record");

  if (getTabName || getTableRecord) {
    if (getTabName) {
      localStorage.setItem("tab", getTabName);
      urlParams.delete("id");
    }
    if (getTableRecord) {
      localStorage.setItem("record", getTableRecord);
      urlParams.delete("record");
    }

    urlParams.delete("id");
    const newUrl =
      window.location.pathname +
      (urlParams.toString() ? `?${urlParams.toString()}` : "");
    window.history.replaceState({}, document.title, newUrl);
  }

  let tabname = localStorage.getItem("tab");
  let record = localStorage.getItem("record");

  const handleClick = () => {
    goBack();
  };

  const getProcessListByJobId = () => {
    const process = find(processList, { processId: selectedItem?.processId });
    if (process) {
      const steps = get(process, "steps", []);
      setProcess(steps);
      return steps;
    }
    return [];
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const movetoChange = (value) => {
    setValue(value);
  };

  useEffect(() => {
    getProcessListByJobId();
  }, []);

  return (
    <React.Fragment>
      <TabStyle>
        <h6>
          <span className="recruitment_tab_name" onClick={handleClick}>
            Back
          </span>{" "}
          <NavigateNextIcon /> {tabname === "Job" ? record : "View details"}
        </h6>

        <Box sx={{ width: "100%" }} className="mt-4">
          <TabContext value={value} onChange={handleChange}>
            {activeTab.toString() === "1" && (
              <>
                <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
                  <TabList
                    variant="scrollable"
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    {Process?.map((process, index) => (
                      <CustomizeMainTab
                        label={process[index]}
                        value={index}
                        key={index}
                      />
                    ))}
                  </TabList>
                </Box>
                <div>
                  <NewSubTab
                    candidateList={candidateList}
                    jobList={jobList}
                    processList={processList}
                    getData={getData}
                    activeTab={activeTab}
                    processValue={value}
                    setShowComponent={setShowComponent}
                    onboardCandidate={onboardCandidate}
                    selectedItem={selectedItem}
                    movetoChange={movetoChange}
                    goBack={goBack}
                  />
                </div>
              </>
            )}
            {activeTab.toString() !== "1" && (
              <div>
                <Divider className="my-3" />
                <NewSubTab
                  candidateList={candidateList}
                  jobList={jobList}
                  processList={processList}
                  getData={getData}
                  setShowComponent={setShowComponent}
                  onboardCandidate={onboardCandidate}
                  activeTab={activeTab}
                  goBack={goBack}
                  index={index}
                />
              </div>
            )}
          </TabContext>
        </Box>
      </TabStyle>
    </React.Fragment>
  );
}

export default RecruitmentTab;
