import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/system";
import CandidateInfo from "./CandidateInfoJSON.json";
import { Row, Col } from "react-bootstrap";
import { Divider } from "@mui/material";
import NotesContent from "./NotesContent";
import ReferenceCheck from "./ReferenceCheck.json";
import moment from "moment";
import { find, get } from "lodash";

const CustomizeTab = styled(Tab)({
  color: "#1F243B",
  textTransform: "none",
  borderBottom: 2,
  borderColor: "divider",
  "&.MuiTabs-indicator": {
    display: "none",
  },
  "&.Mui-selected": {
    color: "#2D53DA",
    borderBottom: "none",
  },
});

const CustomizeTabPanel = styled(TabPanel)({
  padding: 0,
});

const RecruitmentStyle = styled("div")({
  ".second-tab": {},
  ".recruitment_status": {
    color: "#08C108",
    fontSize: "14px",
  },
  ".box-style": {
    overflowX: "auto",
    width: "100%",
  },
  "@media (max-width: 769px)": {
    ".second-tab": {
      width: "350px",
    },
    ".box-style": {
      width: "350px",
    },
  },
  "@media (max-width: 426px)": {
    ".second-tab": {
      width: "330px",
    },
    ".box-style": {
      width: "330px",
    },
  },
  "@media (max-width: 376px)": {
    ".second-tab": {
      width: "280px",
    },
    ".box-style": {
      width: "280px",
    },
  },
  "@media (max-width: 320px)": {
    ".second-tab": {
      width: "230px",
    },
    ".box-style": {
      width: "230px",
    },
  },
});

function RecruitmentTab({ item, jobList, processList, getProcess }) {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getProcessName = () => {
    const process = find(processList, { processId: item?.processId });

    if (process) {
      const steps = get(process, "steps", []);
      const currentStatus = item?.currentStatus || "0";
      return get(
        steps,
        `[${currentStatus}][${currentStatus}]`,
        "Unknown Status"
      );
    }
  };

  return (
    <React.Fragment>
      <RecruitmentStyle>
        <span className="recruitment_status">{getProcessName()}</span>
        <div className="second-tab">
          <Box className="mt-2 ">
            <TabContext value={value}>
              <div className="box-style">
                <Box
                  sx={{
                    borderBottom: 2,
                    borderColor: "divider",
                    display: "inline-block",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <CustomizeTab label="Candidate Info" value="1" />
                    {item?.screeningQuestion?.length > 0 && (
                      <CustomizeTab label="Screening Answers" value="2" />
                    )}
                    <CustomizeTab label="Resume/CV" value="3" />
                    <CustomizeTab label="Reference Check" value="4" />
                  </TabList>
                </Box>
              </div>
              <CustomizeTabPanel value="1">
                <Row>
                  {CandidateInfo.map((data, idx) => {
                    return (
                      <Col md={6}>
                        <div className="mt-3">
                          <label className="text-secondary fw-bold">
                            <small>{data.label}</small>
                          </label>
                          <div>
                            {data.name === "createdAt"
                              ? moment(item[data.name]).format("DD MMM YYYY")
                              : item[data.name]}
                          </div>
                        </div>
                      </Col>
                    );
                  })}

                  <h6 className="fw-semibold mt-3">
                    <strong>About us</strong>
                  </h6>
                  <div>{item?.aboutUs}</div>
                </Row>
              </CustomizeTabPanel>
              <CustomizeTabPanel value="2">
                {item?.screeningQuestion?.map((e, idx) => {
                  return (
                    <section className="border border-1 rounded my-3">
                      <div className="p-2 m-0">
                        <h6 className="fw-semibold mt-3">
                          <strong>{e.question}</strong>
                        </h6>

                        <p>{e.answer}</p>
                      </div>
                    </section>
                  );
                })}
              </CustomizeTabPanel>
              <CustomizeTabPanel value="3">
                <embed
                  title="PDF Viewer"
                  src={item?.resume}
                  type="application/pdf"
                  width="100%"
                  height="600px"
                  className="pdf-viewer-embed"
                />
              </CustomizeTabPanel>
              <CustomizeTabPanel value="4">
                <Row>
                  {ReferenceCheck.map((data, idx) => {
                    return (
                      <Col md={6}>
                        <div className="mt-3">
                          <label className="text-secondary fw-bold">
                            <small>{data.label}</small>
                          </label>
                          <div>{item[data.name] ? item[data.name] : "-"}</div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </CustomizeTabPanel>
              <Divider className="my-4" />
              <NotesContent item={item} />
            </TabContext>
          </Box>
        </div>
      </RecruitmentStyle>
    </React.Fragment>
  );
}

export default RecruitmentTab;
