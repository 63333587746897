import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Spin } from "antd";
import CurrentAppraisal from "molecules/CurrentAppraisal";
import AppraisalHistory from "molecules/AppraisalHistory";
import TabsList from "molecules/TabsList";
import { CALL_API, CALL_API_WITH_HEADER } from "common/API";
import AppraisalForm from "./AppraisalForm";
import AppraisalResponse from "./AppraisalResponse";
import EditAppraisal from "./EditAppraisal";
import { connect } from "react-redux";
import { getAllDepartmentList, getMenuList, getUserRoleId } from "redux/selectors";
import ViewAppraisalResponse from "organisms/ViewAppraisalResponse";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import { Pageview } from "@mui/icons-material";
import { useLocation } from 'react-router-dom';
import { isEmpty } from "lodash";

const Appraisal = ({ AllDepartmentList, userRoleId, menuList }) => {
  const [appraisal, setAppraisal] = useState([]);
  const [history, setHistory] = useState([]);
  const [response, setResponse] = useState([]);
  const [displayLocationLoader, setDisplayLocationLoader] = useState(false);
  const [responseLoader, setResponseLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageView, setPageView] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [viewResponseData, setViewResponseData] = useState([]); 
  const [selectItem, setSelectItem] = useState(false);
  const [tabId, setTabId] = useState(1);

  const backStyle = { color: "black" };
  const pointerStyle = { cursor: "pointer", };
  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const location = useLocation();
  const currentPath = location.pathname;



  const fetchData = async () => {
    setDisplayLocationLoader(true);
    try {
      const res = await CALL_API_WITH_HEADER(`appraisalform`, "get", {}, {
        menupath : currentPath === "/myappraisal" ? "myfolder" : "hrresources"
      });
      setDisplayLocationLoader(false);
      if(res && !isEmpty(res.data)){
        const filterresponse = res?.data?.filter((item)=>!item.deleteStatus)
        setAppraisal(filterresponse);
      } 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
    fetchHistoryData();
  }, []);

  const fetchHistoryData = async () => {
    setDisplayLocationLoader(true);
    try {
      const res = await CALL_API_WITH_HEADER(`appraisalform/appraisalhistory`, "get", {}, {
        menupath : currentPath === "/myappraisal" ? "myfolder" : "hrresources"
      });
      setDisplayLocationLoader(false);
      if(res && !isEmpty(res.data)){
        setHistory(res.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchResponse = async (id) => {
    setResponseLoader(true);
    try {
      const res = await CALL_API(`appraisalform/response/${id}`, "get");
      if(res && !isEmpty(res.data)){
        setResponse(res.data);
      }
      setResponseLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataBYId = async (id) => {
    setLoading(true);
    try {
      const res = await CALL_API(`appraisalform/${id}`, "get");
      setLoading(false);
      if(res && !isEmpty(res.data)){
        setSelectedItem(res.data);
      }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  let items = [
    {
      id: 1,
      label: "Current Appraisal",
      component: (
        <CurrentAppraisal
          appraisal={appraisal}
          displayLocationLoader={displayLocationLoader}
          setPageView={setPageView}
          setSelectedItem={setSelectedItem}
          fetchDataBYId={fetchDataBYId}
          setAppraisal={setAppraisal}
          fetchResponse={fetchResponse}
          Menulist={Menulist}
        />
      ),
    },
    {
      id: 2,
      label: "Appraisal History",
      component: (
        <AppraisalHistory
          displayLocationLoader={displayLocationLoader}
          setPageView={setPageView}
          history={history}
          AllDepartmentList={AllDepartmentList}
          appraisal={appraisal}
          fetchDataBYId={fetchDataBYId}
          setViewResponseData={setViewResponseData}
        />
      ),
    },
  ];

  const handleTabSelect = (key) => {
    setTabId(key)
  };
  return (
    <section className="tabs_and_tableui">
      {pageView === "historyResponse"  &&(
        <label className="mb-2" onClick={()=>{setPageView(false);setTabId(2)}}>
                  Appraisal History <ArrowForwardIosIcon style={{fontSize:"14px"}}/> <span style={backStyle}><b>{selectItem}</b></span>{" "}
                  </label>
              )}
      {pageView !== "response" && pageView !== "historyResponse" &&(
        <h5>Appraisal</h5>
      )}
     
      { pageView === "appraisalView"  && (
        <Row className="page-title">
          <Col sm={12} md={12}>
            <label className="bg-transparent border-0 mb-3 text-left styled-cursor">
              <span style={pointerStyle} onClick={() => setPageView(false)}>
                Appraisal{" "}
              </span>{" "}
              
              {pageView === "appraisalView" && (
                <span>
                  <ArrowForwardIosIcon style={{fontSize:"14px"}}/> <span style={backStyle}>{selectedItem?.title}</span>{" "}
                </span>
              )}
              {pageView === "historyResponse" && (
                
                <span>
                  <ArrowForwardIosIcon style={{fontSize:"14px"}}/> <span style={backStyle}>{selectItem}</span>{" "}
                </span>
              )}
            </label>
          </Col>
        </Row>
      )}

      {pageView === "appraisalView" ? (
        <Spin spinning={loading}>
          <AppraisalForm
            selectedItem={selectedItem}
            setPageView={setPageView}
            allDepartment={AllDepartmentList}
            setSelectedItem={setSelectedItem}
            fetchResponse={fetchResponse}
            fetchHistoryData={fetchHistoryData}
            Menulist={Menulist}
          />
        </Spin>
      ) : pageView === "response" ? (
        <Spin spinning={loading}>
          <AppraisalResponse
            selectedItem={selectedItem}
            setPageView={setPageView}
            displayLocationLoader={responseLoader}
            response={response}
            fetchResponse={fetchResponse}
          />
        </Spin>
      ) : pageView === "edit" ? (
        <Spin spinning={loading}>
          <EditAppraisal
            pageView={pageView}
            selectedItem={selectedItem}
            setPageView={setPageView}
            fetchData={fetchData}
          />
        </Spin>
      ) : pageView === "historyResponse" ? (
        <Spin spinning={loading}>
          <ViewAppraisalResponse
            pageView={pageView}
            setPageView={setPageView}
            response={viewResponseData}
            appraisal={appraisal}
            setSelectedItem={setSelectItem}
          />
        </Spin>
      ) : (
        <TabsList items={items} handleTabSelect={handleTabSelect} tabId={tabId}/>
      )}
    </section>
  );
};

const mapStateToProps = (state) => ({
  AllDepartmentList: getAllDepartmentList(state),
  menuList: getMenuList(state),
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps)(Appraisal);
