import React, { useState, useEffect } from "react";
import { Row, Col, Form, Switch, Checkbox } from "antd";
import { connect } from "react-redux";
import { getClientid, getLocations } from "redux/selectors";
import { CALL_API } from "common/API";
import { STATUS_CODE, VALIDATE_CHARACTER } from "common/Constants";
import { getMySqlDate } from "utils/Date";
import EditInformation from "molecules/EditInformation";
import EditSelectTable from "molecules/EditSelectTable";
import TabsList from "molecules/TabsList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormModal from "./FormModal";

const EditHoliday = ({
  isModalVisible,
  closeModal,
  ClientId,
  getHolidayList,
  HolidayDetails,
  locationList,
  // Note : need for delete
  deleteHoliday,
  viewOnly
}) => {
  const [form] = Form.useForm();

  const [selectedLocationId, setSelectedLocationId] = useState([]);
  const [values, setValues] = useState({});
  const [submit, setsubmit] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [selectAllLocationChecked, setSelectAllLocationChecked] =
    useState(false);

  useEffect(() => {
    setValues({ name: HolidayDetails.holiday_name });
    setSelectedLocationId(HolidayDetails.location_id);
  }, [HolidayDetails]);

  useEffect(() => {
    form.setFieldsValue({
      Holiday_name: HolidayDetails.HolidayName,
      description: HolidayDetails.description,
    });

    setSelectAllLocationChecked(
      selectedLocationId.length === locationList.length
    );
  }, [HolidayDetails, selectedLocationId, locationList]);

  const locatincolumns = [
    {
      title: "Location Name",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id) => {
        const check = selectedLocationId?.includes(location_id) ? true : false;
        return (
          <Switch
            disabled={viewOnly}
            size="small"
            checked={check}
            onChange={(checked) => getLocationId(location_id, checked)}
          />
        );
      },
    },
  ];

  const getLocationId = (location_id, checked) => {
    const newLocationId = checked
      ? [...selectedLocationId, location_id]
      : selectedLocationId.filter((item) => item !== location_id);
    setSelectedLocationId(newLocationId);
    setsubmit(true);
  };

  const handleSelectAllLocationChange = (e) => {
    const checked = e.target.checked;
    setSelectAllLocationChecked(checked);
    const allDepaetmentIds = locationList.map((item) => item.location_id);
    setSelectedLocationId(checked ? allDepaetmentIds : []);
    setsubmit(true);
  };

  useEffect(() => {
    if (submit) {
      handleSubmit();
    }
  }, [selectedLocationId, values]);

  const handleSubmit = async () => {
    const { name, start_date, end_date } = values;
    const containsSpecialCharacters = !VALIDATE_CHARACTER.test(name);
    if (containsSpecialCharacters) { // Handle the case where HolidayName contains special characters
      return; // Prevent further execution
    }
    const { code } = await CALL_API(
      `holiday/${ClientId}/${HolidayDetails.holiday_id}`,
      "patch",
      {
        holidayname: name,
        color: "#EB4747",
        startdate: getMySqlDate(start_date),
        enddate: getMySqlDate(end_date),
        locationid: selectedLocationId,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      getHolidayList();
    }
  };

  const items = [
    {
      id: 1,
      label: "Basic",
      component: (
        <>
          {viewOnly ? (
            <Row className="styled-card">
              <Col>
                <b>Holiday Name</b> : {values.name}
              </Col>
            </Row>
          ) : (
            <EditInformation
              details={HolidayDetails}
              setValues={setValues}
              setsubmit={setsubmit}
              title="holiday"
            />
          )}
        </>
      ),
    },
    {
      id: 2,
      label: "Location",
      component: (
        <>
          {!viewOnly && (
            <div className="mb-3 d-flex justify-content-between">
              {locationList?.length > 0 && (
                <Checkbox
                  className=""
                  checked={selectAllLocationChecked}
                  onChange={handleSelectAllLocationChange}
                >
                  Select All
                </Checkbox>
              )}
            </div>
          )}

          <EditSelectTable
            columns={locatincolumns}
            tabledata={locationList}
            setOpenModal={() => setOpenLocationModal(true)}
            Name="Add Location"
          />
          <FormModal
            isModalVisible={openLocationModal}
            closeModal={() => setOpenLocationModal(false)}
            Name="Location"
          />
        </>
      ),
    },
  ];

  return (
    <>
      {isModalVisible && (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => {
              closeModal(false);
            }}
          >
            Holiday <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className=" pb-0 pt-1">{values.name}</b>
          <TabsList items={items} />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  locationList: getLocations(state),
});
export default connect(mapStateToProps)(EditHoliday);
