import {
  Button,
  Table,
  Tabs,
  Avatar,
  Badge,
  message,
  Modal,
  Slider,
  Spin,
} from "antd";
import AvatarEditor from "react-avatar-editor";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import TabPane from "antd/lib/tabs/TabPane";
import { dateInDetail, getKenyanDateTime, getMySqlDate } from "utils/Date";
import COLORS from "common/Colors";
import { Col, Row } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { startCase } from "lodash";
import moment from "moment";
import { CALL_API } from "common/API";
import { LEAVE_ICON, STATUS_CODE } from "common/Constants";
import { setProfilePic, setUserList } from "redux/actions";
import { bindActionCreators } from "redux";
import MainStyledDropzone from "../pages/Dropzone";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ViewMore from "organisms/ViewMore";
import LeaveTrackerChart from "./LeaveTracker";
import {
  getUserList,
  getClientid,
  getAllDepartmentList,
  getLocations,
  getRoleList,
  getSelectedDepartmentId,
  getUserInfo,
  getLeaveCategoryList,
  getMenuList,
  getUserRoleId,
} from "redux/selectors";
import Icon from "atoms/Icon";
import { ButtonFilled, ButtonOutlined } from "reusableComponent/UIButtons";
import RejectReasonModal from "molecules/RejectReasonModal";
import EditEmployee from "organisms/EditEmployee";
import { useLocation } from 'react-router-dom';

const ProfileDetails = ({
  userList,
  ClientId,
  roleList,
  updateUserList,
  selectedDepartmentId,
  leaveCategoryList,
  saveProfilePic,
  userInfo,
  userRoleId,
  menuList
}) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [type, setType] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setType(searchParams.get('type'))
  }, []); 

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const [filteruser, setFilterUser] = useState({});
  const [filterrole, setFilterRole] = useState([]);

  useEffect(() => {
    const filteruser = userList?.find((item) => item?.userid === id);
    const filterrole = roleList?.find((item) =>
      filteruser?.roleid.includes(item?.roleId)
    );
    setFilterUser(filteruser);
    setFilterRole(filterrole);
  }, [userList]);

  const [activeTab, setActiveTab] = useState("0");
  const [btnLoader, togglebtnLoader] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [zoomin, setZoomin] = useState(1);
  const [rotatein, setRotatein] = useState(0);
  const [uniqueId, setUniqueId] = useState(generateUniqueId());
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [leaveList, setLeaveList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const havingCancel = false
  const [leaveDetails, setLeaveDetails] = useState({});
  const [displayDeclineReasonModal, toggleDeclineReasonModalVisibility] =
    useState(false);
  const [EmployeeDetails, setEmployeeDetails] = useState({});
  const [displayEditEmployeeModal, toggleEditEmployeeModal] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const objectUrl = React.useMemo(
    () => (selectedImage ? URL.createObjectURL(selectedImage) : null),
    [selectedImage]
  );

  const editorRef = useRef(null);
  const settingNewCropImage = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage();
      const croppedImage = canvas.toDataURL();
      return croppedImage;
    }
  };


  useEffect(() => {
    setEmployeeDetails({
      userid: filteruser?.userid || "",
      fullname: filteruser?.fullname || "",
      firstname: filteruser?.firstname || "",
      lastname: filteruser?.lastname || "",
      startdate: filteruser?.startdate || "",
      dateofbirth: filteruser?.dateofbirth || "",
      enddate: filteruser?.enddate || "",
      bandname: filteruser?.bandname || "",
      bandid: filteruser?.bandid || "",
      overtimestatus: filteruser?.overtimestatus || "",
      salary: filteruser?.salary || "",
      profileurl: filteruser?.profileurl || null,
      gender: filteruser?.gender || "",
      age: filteruser?.age || "",
      maritalstatus: filteruser?.maritalstatus || "",
      nationality: filteruser?.nationality || "",
      facerecognition: filteruser?.facerecognition || "",
      roleid: filteruser?.roleid || "",
      uan: filteruser?.uan || "",
      pan: filteruser?.pan || "",
      address: filteruser?.presentaddress || "",
      phoneno: filteruser?.phoneno || "",
      institutename: filteruser?.institutename || "",
      degree: filteruser?.degree || "",
      specialization: filteruser?.specialization || "",
      yearofcompletion: filteruser?.yearofcompletion || "",
      companyname: filteruser?.companyname || "",
      jobtitle: filteruser?.jobtitle || "",
      professionalsince: filteruser?.professionalsince || "",
      hireddate: filteruser?.hireddate || "",
      relevant: filteruser?.relevant || "",
      employeesponser: filteruser?.employeesponser || "",
      statusname: filteruser?.statusname || "",
      jobdescription: filteruser?.jobdescription || "",
      emergencycontactname: filteruser?.emergencycontactname || "",
      emergencycontactnumber: filteruser?.emergencycontactnumber || "",
      statusid: filteruser?.statusid || "",
      employementtype: filteruser?.employementtype || "",
      baselocationid: filteruser?.baselocationid || "",
      salutation: filteruser?.salutation || "",
      probationstartdate: filteruser?.probationstartdate || "",
      probationenddate: filteruser?.probationenddate || "",
      contractstartdate: filteruser?.contractstartdate || "",
      contractenddate: filteruser?.contractenddate || ""
    });
  }, [filteruser]);

  const tabTitles = [
    "Employee Details",
    "Leave Tracker",
    "Education Details",
    "Work Experience",
  ];

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const wrokExperenceList = [
    {
      Companyname: filteruser?.companyname || "-",
      jobTitle: filteruser?.jobtitle || "-",
      fromDate: filteruser?.professionalsince? moment(filteruser?.professionalsince).format("DD-MM-YYYY") : "-",
      jobDescription: filteruser?.jobdescription || "-",
      relevant: filteruser?.relevant || "-",
    },
  ];
  const wrokExperenceColumns = [
    {
      title: "Company name",
      dataIndex: "Companyname",
      key: "Companyname",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "From Date",
      dataIndex: "fromDate",
      key: "fromDate",
    },
    {
      title: "Relevant",
      dataIndex: "relevant",
      key: "relevant",
      align: "center",
    },
  ];
  const LeaveApprovalColumns = [
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "10%",
      render: (_, record) => {
        if (record.new_start_date === "null" || record.new_start_date === null || record.new_start_date === "") {
          const dateFormat = moment(record.start_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_start_date).format(
            "DD MMM YYYY"
          );
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "10%",
      render: (_, record) => {
        if (record.new_end_date === "null" || record.new_end_date === "" || record.new_end_date === null) {
          const dateFormat = moment(record.end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leave_category_name",
      key: "leave_category_name",
      width: "10%",
    },
    {
      title: "Duration",
      dataIndex: "number_of_days",
      key: "number_of_days",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_number_of_days === "null" ||
          record.new_number_of_days === null ||
          record.new_number_of_days === ""
        ) {
          return `${record.number_of_days} days`;
        } else {
          return `${record.new_number_of_days} days`;
        }
      },
    },
    {
      title: "Description",
      dataIndex: "reason",
      key: "reason",
      width: "30%",
      className: "normal-column",
      render: (reason) => {
        return (
          <>
            <ViewMore value={reason} />
          </>
        );
      },
    },
    
  ];
  if ( Menulist?.find(
    (item) => item?.key === "allapprovals" && item?.permission?.edit)) {
    LeaveApprovalColumns.push(
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (_, record) => (
          <>
            <StyledProfileDetails>
              <div className="leave_approval_actions">
                <ButtonOutlined
                  onClick={() => {
                    setIsModalOpen(true);
                    viewLeave(record, true);
                  }}
                >
                  Approve
                </ButtonOutlined>
                <ButtonOutlined
                  onClick={() => {
                    toggleDeclineReasonModalVisibility(true);
                    viewLeave(record, true);
                  }}
                >
                  Decline
                </ButtonOutlined>
              </div>
            </StyledProfileDetails>
          </>
        ),
      },
    );
  }
  const LeaveHistoryColumns = [
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "10%",
      render: (_, record) => {
        if (record.new_start_date === "null" || record.new_start_date === "" || record.new_start_date === null) {
          const dateFormat = moment(record.start_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_start_date).format(
            "DD MMM YYYY"
          );
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "10%",
      render: (_, record) => {
        if (record.new_end_date === "null" || record.new_end_date === "" || record.new_end_date === null) {
          const dateFormat = moment(record.end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leave_category_name",
      key: "leave_category_name",
      width: "10%",
    },
    {
      title: "Duration",
      dataIndex: "number_of_days",
      key: "number_of_days",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_number_of_days === "null" ||
          record.new_number_of_days === null ||
          record.new_number_of_days === ""
        ) {
          return `${record.number_of_days} days`;
        } else {
          return `${record.new_number_of_days} days`;
        }
      },
    },
    {
      title: "Description",
      dataIndex: "reason",
      key: "reason",
      width: "30%",
      className: "normal-column",
      render: (reason) => {
        return (
          <>
            <ViewMore value={reason} />
          </>
        );
      },
    },
  ];
  const EducationList = [
    {
      instituteName: filteruser?.institutename || "-",
      degreeDiploma: filteruser?.degree || "-",
      specialization: filteruser?.specialization || "-",
      yearofCompletion:filteruser?.yearofcompletion?
        moment(filteruser?.yearofcompletion).format("DD-MM-YYYY") : "-",
    },
  ];
  const EducationColumns = [
    {
      title: "Institute Name",
      dataIndex: "instituteName",
      key: "instituteName",
    },
    {
      title: "Degree/Diploma",
      dataIndex: "degreeDiploma",
      key: "degreeDiploma",
    },
    {
      title: "Specialization",
      dataIndex: "specialization",
      key: "specialization",
    },
    {
      title: "Year of Completion",
      dataIndex: "yearofCompletion",
      key: "yearofCompletion",
      align: "center",
    },
  ];

  const IdentityInfoList = [
    {
      type: "UAN",
      details: filteruser?.uan || "-",
    },
    {
      type: "PAN",
      details: filteruser?.pan || "-",
    },
  ];

  const IdentityInfoColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
  ];

  const ApproveOk = async (reason) => {
    return new Promise(async (resolve) => {
      await approveRejectCancelLeave(5, reason, havingCancel);
      resolve();
    }).catch((err) => console.log("[ApproveOk] Error-->", err));
  };

  const approveHandleCancel = () => {
    setIsModalOpen(false);
  };

  const approveHandleOk = () => {
    setLoading(true);
    ApproveOk();
  };

  const viewLeave = (leaveObject) => {
    setLeaveDetails({
      ...leaveObject,
    });
  };

  const closeDeclineReasonModal = async (reason) => {
    if (reason) await approveRejectCancelLeave("6", reason);
    toggleDeclineReasonModalVisibility(false);
  };
  // Note : 3rd params needed
  const approveRejectCancelLeave = async (
    statusId,
    declined_reason = "",
    is_leave_cancel_request
  ) => {
    return new Promise(async (resolve) => {
      const { code } = await CALL_API(
        `approve-reject-cancel-leave/${ClientId}`,
        "post",
        {
          departmentId: selectedDepartmentId,
          user_leave_id: leaveDetails?.user_leave_id,
          status_id: statusId.toString(),
          declined_reason,
          is_cancel_request: leaveDetails.having_cancel_request === "1" ? true : false,
          start_date: leaveDetails?.new_start_date
            ? getMySqlDate(leaveDetails?.new_start_date)
            : getMySqlDate(leaveDetails?.start_date),
          end_date: leaveDetails?.new_end_date
            ? getMySqlDate(leaveDetails?.new_end_date)
            : getMySqlDate(leaveDetails?.end_date),
        }
      );

      if (code === STATUS_CODE.SOMETHING_WENT_WRONG) {
        message.error(`Oops!! something went wrong.`);
      } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
        message.error(`Invalid payload. Please enter correct data.`);
      } else if (code === STATUS_CODE.RECORD_EXIST) {
        message.error(`Leave aleady exist.`);
      } else if (code === STATUS_CODE.SUCCESS) {
        message.success(`Your leave application has been Approved.`);
        getLeaveList();
      }
      resolve();
      setLoading(false);
      setIsModalOpen(false);
    });
  };

  const LeaveApprovalData = leaveList.filter((val) => {
    if (val?.status_id === "4" || (val?.status_id === "5" && val?.having_cancel_request === "1")) {
      return val;
    }
  });

  const LeaveHistoryData = leaveList.filter((val) => {
    if (val?.status_id === "5" && (val?.having_cancel_request === "0" || val?.having_cancel_request === "")) {
      return val;
    }
  });

  const filteredCategoriesbygender = filteruser?.gender?.toLowerCase() === "male" ? leaveCategoryList?.filter((item)=>(item.eligible)?.toLowerCase() !== "female") : leaveCategoryList?.filter((item)=>(item.eligible)?.toLowerCase() !== "male")

  useEffect(() => {
    getLeaveList();
  }, [selectedDepartmentId]);

  const getLeaveList = async () => {
    const { leaves = [] } = await CALL_API(
      `leave-list/${ClientId}`,
      "post",
      {
        status_id: "",
        department_id: selectedDepartmentId,
        selected_role_id: 2,
        start_date: "",
        end_date: "",
        user_id: id,
      }
    );
    const filteredLeaves = leaves.filter((item) => item.userid === id);
    setLeaveList(filteredLeaves.reverse());
  };

  const getUserLeaveData = async () => {
    setLoading(true);
    const response = await CALL_API(
      `employee-leave-details/${ClientId}`,
      "post",
      {
        year: dateInDetail(getKenyanDateTime()).year,
        userId: id,
      }
    );

    setLeaveData(response);
    setLoading(false);
  };

  useEffect(() => {
    getUserLeaveData();
  }, [ClientId]);

  const breadStyle = { color: "#636980", cursor: "pointer" }

  const breadcrumbs = [
    <label
      key="2"
      style={breadStyle}
      onClick={() => history.goBack()}
    >
      People
    </label>,
    <Typography key="3" color="text.primary">
      {filteruser?.fullname}
    </Typography>,
  ];

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      updateUserList(userList);
      let profileUpdate = userList?.filter(val => val?.userid === userInfo.userid)
      if (profileUpdate?.length !== 0) {
        saveProfilePic(profileUpdate[0]?.profileurl)
      }
    }
  };
  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };

  const deleteEmployee = async () => {
    togglebtnLoader(true);
    await CALL_API(`add-users/${ClientId}`, "delete", {
      user_id: id,
      status_id: "0",
      status_name: "inactive",
      terminated_date: moment(new Date()).format('YYYY-MM-DD')
    });
    togglebtnLoader(false);
    fetchMasterData();
    message.success("This Employee has been terminated.This employee will be removed within a maximum of 8 hours");
    history.goBack();
  };

  const reinstateEmployee = async () => {
    togglebtnLoader(true);
    await CALL_API(`add-users/${ClientId}`, "delete", {
      user_id: id,
      status_id: "1",
      status_name: "active",
      terminated_date: ""
    });
    togglebtnLoader(false);
    fetchMasterData();
    message.success("The employee's employment has been restored");
    history.goBack();
  };
  function generateUniqueId() {
    const usedIds = [];
    let uniqueId = "";
    while (uniqueId.length !== 6) {
      const randomNumber = Math.floor(Math.random() * 900000) + 100000;
      if (!usedIds.includes(randomNumber.toString())) {
        usedIds.push(randomNumber.toString());
        uniqueId += randomNumber.toString();
      }
    }
    return uniqueId;
  }

  let userId = id;
  let splitUserId = userId.split("@");
  let userIdWithoutDomain = splitUserId[0];
  let updatedUserId = userIdWithoutDomain + uniqueId;

  const handleUploadProfile = async () => {
    let x = await settingNewCropImage();
    try {
      setUniqueId(generateUniqueId());
      const obj = {
        userid: updatedUserId,
        filetype: selectedImage.type,
        content: x,
        filename: uniqueId,
        emailid: id,
      };
      setConfirmLoading(true);
      const { code } = await CALL_API(
        `profile-upload/${ClientId}`,
        "post",
        obj
      );

      if (code === "SUCCESS") {
        setShowImageModal(false);
        fetchMasterData();
        setConfirmLoading(false);
      }
      setConfirmLoading(false);
    } catch (error) {
      console.error("[handleUploadProfile] Error -->", error);
      setConfirmLoading(false);
    }
  };

  const WorkInfoDetails = [
    {
      data: "User Name",
      value: filteruser?.fullname || "-",
    },
    {
      data: "Primary Industry",
      value: filteruser?.companyname || "-",
    },
    {
      data: "Professional Since",
      value: moment(filteruser?.professionalsince).format("YYYY") || "-",
    },
    {
      data: "Hired Date",
      value: moment(filteruser?.hireddate).format("DD-MMMM-YYYY") || "-",
    },
    {
      data: "Status",
      value: filteruser?.statusid === "1" ? "Active" : "In Active" || "-",
    },
    {
      data: "Qualification",
      value: filteruser?.degree || "-",
    },
    {
      data: "Employee Sponsor",
      value: filteruser?.employeesponser || "-",
    },
  ];
  const personalDet = [
    {
      data: "salutation",
      value: filteruser?.salutation || "-",
    },
    {
      data: "First Name",
      value: filteruser?.firstname || "-",
    },
    {
      data: "Last Name",
      value: filteruser?.lastname || "-",
    },
    {
      data: "Full Name",
      value: filteruser?.fullname || "-",
    },
    {
      data: "Date Of Birth",
      value: filteruser?.dateofbirth || "-",
    },
    {
      data: "Marital Status",
      value: filteruser?.maritalstatus
        ? filteruser?.maritalstatus.charAt(0).toUpperCase() +
        filteruser?.maritalstatus.slice(1)
        : "-",
    },
    {
      data: "Age",
      value: filteruser?.age || "-",
    },
    {
      data: "Nationality",
      value: filteruser?.nationality
        ? filteruser?.nationality.charAt(0).toUpperCase() +
        filteruser?.nationality.slice(1)
        : "-",
    },
    {
      data: "Gender",
      value: filteruser?.gender
        ? filteruser?.gender.charAt(0).toUpperCase() +
        filteruser?.gender.slice(1)
        : "-",
    },
    {
      data: "Present Address",
      value: filteruser?.presentaddress || "-",
    },
    {
      data: "Permanent Address",
      value: filteruser?.permanentaddress || "-",
    },
    {
      data: "Emergency Contact Name",
      value: filteruser?.emergencycontactname || "-",
    },
    {
      data: "Emergency Contact Number",
      value: filteruser?.emergencycontactnumber || "-",
    },
    {
      data: "Band Name",
      value: filteruser?.bandname || "-",
    },
    {
      data: "Employement Type",
      value: filteruser?.employementtype || "-",
    }
  ];
  
  if (filteruser?.statusid === "0") {
    personalDet.push({
      data: "Terminated Date",
      value: filteruser?.terminateddate? moment(filteruser?.terminateddate).format("DD-MM-YYYY") : "-",
    });
  }

  if (filteruser?.employementtype === "Probation") {
    personalDet.push(
      {
        data: "Probation Start Date",
        value: filteruser?.probationstartdate
          ? moment(filteruser?.probationstartdate).format("DD-MM-YYYY")
          : "-",
      },
      {
        data: "Probation End Date",
        value: filteruser?.probationenddate
          ? moment(filteruser?.probationenddate).format("DD-MM-YYYY")
          : "-",
      }
    );
  }

  if (filteruser?.employementtype === "Contract") {
    personalDet.push(
      {
        data: "Contract Start Date",
        value: filteruser?.contractstartdate
          ? moment(filteruser?.contractstartdate).format("DD-MM-YYYY")
          : "-",
      },
      {
        data: "Contract End Date",
        value: filteruser?.contractenddate
          ? moment(filteruser?.contractenddate).format("DD-MM-YYYY")
          : "-",
      }
    );
  }
  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedImage(file);
  };
  const CropPopModal = () => {
    setShowImageModal(true);
  };
  const CloseImageModal = async () => {
    setShowImageModal(false);
    setZoomin(1);
    setRotatein(0);
  };
  const resetSelectedImage = () => {
    setSelectedImage(null);
  };
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    deleteEmployee();
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const cursorstyle = { cursor: "pointer" };
  return (
    <>
      {!displayEditEmployeeModal && (
        <>
          <StyledProfileDetails>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
            <Row className="Broker_profile_flex">
              <Col xl={8}>
                <Col className="styled_col">
                  {type === "hr" && (
                    <>
                      {filteruser?.profileurl === null ? (
                        <>
                          <Badge
                            count={
                              Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "team" && subItem?.permission.edit)) &&
                              <Icon
                                style={cursorstyle}
                                name="profileEdit"
                                className="camera_select_icon"
                                onClick={CropPopModal}
                              />
                            }
                          >
                            <StyledAvatar size="default">
                              {startCase(filteruser.fullname).charAt(0)}
                            </StyledAvatar>
                          </Badge>
                        </>
                      ) : (
                        <>
                          <Badge
                            count={
                              Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "team" && subItem?.permission.edit)) &&
                              <Icon
                                style={cursorstyle}
                                name="profileEdit"
                                className="camera_select_icon"
                                onClick={CropPopModal}
                              />
                            }
                          >
                            <StyledAvatar
                              src={filteruser?.profileurl}
                              size="default"
                              className="StyledImage"
                            ></StyledAvatar>
                          </Badge>
                        </>
                      )}
                    </>
                  )}
                  {type === "manage" && (
                    <>
                      {filteruser?.profileurl === null ? (
                        <>
                          <Badge
                            count={
                              Menulist?.find(item => item?.key === "manage" && item?.subMenu?.find(subItem => subItem?.key === "employee" && subItem?.permission.edit)) &&
                              <Icon
                                style={cursorstyle}
                                name="profileEdit"
                                className="camera_select_icon"
                                onClick={CropPopModal}
                              />
                            }
                          >
                            <StyledAvatar size="default">
                              {startCase(filteruser.fullname).charAt(0)}
                            </StyledAvatar>
                          </Badge>
                        </>
                      ) : (
                        <>
                          <Badge
                            count={
                              Menulist?.find(item => item?.key === "manage" && item?.subMenu?.find(subItem => subItem?.key === "employee" && subItem?.permission.edit)) &&
                              <Icon
                                style={cursorstyle}
                                name="profileEdit"
                                className="camera_select_icon"
                                onClick={CropPopModal}
                              />
                            }
                          >
                            <StyledAvatar
                              src={filteruser?.profileurl}
                              size="default"
                              className="StyledImage"
                            ></StyledAvatar>
                          </Badge>
                        </>
                      )}
                    </>
                  )}

                  <div className="w-100 bg-red d-flex justify-content-between">
                    <div>
                      <div className="px-3">
                        <div className="">
                          <p className="m-0 broker_tittle">
                            {filteruser?.fullname || "-"}
                          </p>
                        </div>
                        <div className="user_details ">
                          <p className="common_color_style">Role: </p>
                          <p className="common_color_style">
                            {" "}
                            {filterrole?.roleName || "-"}
                          </p>
                        </div>
                        <div className="contact_details">
                          <p className="common_color_style">
                            Employee ID:{" "}
                            <span className="common_color_style">
                              {filteruser?.employeeid || "-"}
                            </span>
                          </p>
                        </div>
                        <p className="common_color_style">
                          Email ID:{" "}
                          <span className="common_color_style">
                            {id || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Col>
              {type === "hr" && (
                <Col xl={4} className="credit_box_container">
                  {Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "team" && subItem?.permission.delete)) && (
                    <>
                      {filteruser?.statusid?.toString() === "1" && (
                        <div>
                          <ButtonOutlined onClick={showModal}>
                            <Icon name="terminate" className="Remove_select_icon" />
                            Terminate
                          </ButtonOutlined>
                        </div>
                      )}
                      {filteruser?.statusid?.toString() === "0" && (
                        <div>
                          <ButtonOutlined
                            onClick={reinstateEmployee}
                            loading={btnLoader}
                          >
                            Reinstate
                          </ButtonOutlined>
                        </div>
                      )}
                    </>
                  )}
                  {Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "team" && subItem?.permission.edit)) && (
                    <ButtonFilled onClick={() => toggleEditEmployeeModal(true)}>
                      <Icon name="profileEdit" className="Edit_select_icon" />
                      Edit Info
                    </ButtonFilled>
                  )}
                </Col>

              )}
              {type === "manage" && (
                <Col xl={4} className="credit_box_container">

                  {Menulist?.find(item => item?.key === "manage" && item?.subMenu?.find(subItem => subItem?.key === "employee" && subItem?.permission.delete)) && (
                    <>
                      {filteruser?.statusid?.toString() === "1" && (
                        <div>
                          <ButtonOutlined onClick={showModal}>
                            <Icon name="terminate" className="Remove_select_icon" />
                            Terminate
                          </ButtonOutlined>
                        </div>
                      )}
                      {filteruser?.statusid?.toString() === "0" && (
                        <div>
                          <ButtonOutlined
                            onClick={reinstateEmployee}
                            loading={btnLoader}
                          >
                            Reinstate
                          </ButtonOutlined>
                        </div>
                      )}
                    </>
                  )}
                  {Menulist?.find(item => item?.key === "manage" && item?.subMenu?.find(subItem => subItem?.key === "employee" && subItem?.permission.edit)) && (
                    <ButtonFilled onClick={() => toggleEditEmployeeModal(true)}>
                      <Icon name="profileEdit" className="Edit_select_icon" />
                      Edit Info
                    </ButtonFilled>
                  )}
                </Col>
              )}

            </Row>
            <Tabs
              defaultActiveKey={activeTab}
              className="mt-3"
              onChange={handleTabChange}
            >
              {tabTitles.map((title, index) => (
                <TabPane tab={title} key={index.toString()}>
                  {activeTab === "0" && (
                    <>
                      <Row className="personal_details_box">
                        {personalDet.map((data, index) => {                        
                          return (
                            <Col xs={12} lg={6} key={index}>
                              <StyledRow className="my-1 employee_work_info">
                                <Col
                                  sm={12}
                                  md={6}
                                  className="my-1 text-nowrap"
                                >
                                  <b className="label_Color">{data.data}: </b>
                                </Col>
                                <Col
                                  sm={12}
                                  md={6}
                                  className="my-1 employess_detail_values text-wrap"
                                >
                                  <b className="label_value_Color">
                                    {(data.data === "Date Of Birth")? moment(data.value).format("DD-MM-YYYY") : data.value}
                                  </b>
                                </Col>
                              </StyledRow>
                            </Col>
                          );
                        })}
                      </Row>
                      <br />
                      <p className="employee_detail_headings">
                        Work Information:
                      </p>
                      <Row className="employee_work_info_box">
                        {WorkInfoDetails.map((data, index) => {
                          return (
                            <Col xs={12} lg={6} key={index}>
                              <StyledRow className="my-1 employee_work_info">
                                <Col
                                  sm={12}
                                  md={6}
                                  className="my-1 text-nowrap"
                                >
                                  <b className="label_Color">{data.data}: </b>
                                </Col>
                                <Col
                                  sm={12}
                                  md={6}
                                  className="my-1 employess_detail_values"
                                >
                                  <b className="label_value_Color">
                                  {(data.data === "Hired Date")? moment(data.value).format("DD-MM-YYYY") : data.value}
                                  </b>
                                </Col>
                              </StyledRow>
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  )}
                  {/* //Need for future */}
                  {/* {activeTab === "1" && (
                    <Row className="personal_details_box">
                      <StyledTable
                        className="mb-5"
                        dataSource={IdentityInfoList}
                        columns={IdentityInfoColumns}
                        rowClassName={(record, index) =>
                          index % 2 === 0 ? "" : "non-white"
                        }
                        rowKey="role_id"
                        pagination={
                          IdentityInfoList.length > 20
                            ? paginationOptions
                            : false
                        }
                      />
                    </Row>
                  )} */}
                  {activeTab === "1" && (
                    <>
                      <Row gutter={16}>
                        {filteredCategoriesbygender.map((data) => (
                          <>
                            {leaveData?.find(
                              (item) => item[data?.leave_category_short_name]
                            )?.[data?.leave_category_short_name] && (
                                <Col
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  xl={6}
                                  key={data.leave_category_short_name}
                                >
                                  <Spin spinning={loading}>
                                    <LeaveTrackerChart
                                      iconName={LEAVE_ICON[7]}
                                      title={data.leave_category_name.toUpperCase()}
                                      data={
                                        leaveData?.find(
                                          (item) => item[data?.leave_category_short_name]
                                        )?.[data?.leave_category_short_name].map(item => ({
                                          ...item,
                                          color: item.color,
                                          type: item.type
                                        })) ?? [] ?? []
                                      }
                                    />
                                  </Spin>
                                </Col>
                              )}
                          </>
                        ))}
                      </Row>
                      <br />
                      <p className="leave_title_content">Leave Approvals:</p>
                      <Row className="personal_details_box">
                        <StyledTable
                          className="mb-5"
                          dataSource={LeaveApprovalData}
                          columns={LeaveApprovalColumns}
                          rowClassName={(record, index) =>
                            index % 2 === 0 ? "" : "non-white"
                          }
                          rowKey="role_id"
                          pagination={
                            LeaveApprovalData.length > 20
                              ? paginationOptions
                              : false
                          }
                        />
                      </Row>
                      <br />
                      <p className="leave_title_content">Leave History:</p>
                      <Row className="personal_details_box">
                        <StyledTable
                          className="mb-5"
                          dataSource={LeaveHistoryData}
                          columns={LeaveHistoryColumns}
                          rowClassName={(record, index) =>
                            index % 2 === 0 ? "" : "non-white"
                          }
                          rowKey="role_id"
                          pagination={
                            LeaveHistoryData.length > 20
                              ? paginationOptions
                              : false
                          }
                        />
                      </Row>
                    </>
                  )}
                  {activeTab === "2" && (
                    <>
                      <Row className="personal_details_box">
                        <StyledTable
                          className="mb-5"
                          dataSource={EducationList}
                          columns={EducationColumns}
                          rowClassName={(record, index) =>
                            index % 2 === 0 ? "" : "non-white"
                          }
                          rowKey="role_id"
                          pagination={
                            EducationList.length > 20
                              ? paginationOptions
                              : false
                          }
                        />
                      </Row>
                    </>
                  )}
                  {activeTab === "3" && (
                    <Row>
                      <Col xs={24}>
                        <StyledTable
                          className="mb-5"
                          dataSource={wrokExperenceList}
                          columns={wrokExperenceColumns}
                          rowClassName={(record, index) =>
                            index % 2 === 0 ? "" : "non-white"
                          }
                          rowKey="role_id"
                          pagination={
                            wrokExperenceList.length > 20
                              ? paginationOptions
                              : false
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {activeTab === "4" && (
                    <div className="text-center">
                      <h2>Page under Constraction</h2>
                    </div>
                  )}
                </TabPane>
              ))}
            </Tabs>
          </StyledProfileDetails>
          <StyledModal
            title="Profile Pic"
            open={showImageModal}
            onCancel={() => {
              CloseImageModal();
              resetSelectedImage();
            }}
            confirmLoading={confirmLoading}
            footer={[
              <ButtonOutlined
                key="cancel"
                onClick={() => {
                  CloseImageModal();
                  resetSelectedImage();
                }}
              >
                Cancel
              </ButtonOutlined>,
              <ButtonFilled
                key="save"
                onClick={() => {
                  handleUploadProfile();
                }}
                disabled={!selectedImage}
                loading={confirmLoading}
              >
                Set
              </ButtonFilled>,
            ]}
          >
            {selectedImage ? (
              <div>
                <AvatarEditor
                  ref={editorRef}
                  image={objectUrl}
                  width={200}
                  height={200}
                  border={50}
                  color={[255, 255, 255, 0.6]}
                  scale={zoomin}
                  rotate={rotatein}
                  borderRadius={250}
                />
                <div className="editor_div">
                  <label>Zoom:</label>
                  <Slider
                    defaultValue={zoomin}
                    onChange={setZoomin}
                    min={1}
                    max={20}
                  />
                </div>
                <div className="editor_div">
                  <label>Rotate:</label>
                  <Slider
                    defaultValue={rotatein}
                    onChange={setRotatein}
                    min={0}
                    max={360}
                  />
                </div>
                <MainStyledDropzone onDrop={handleDrop} />
              </div>
            ) : (
              <MainStyledDropzone onDrop={handleDrop} />
            )}
          </StyledModal>

          <StyledNewModal
            title="Terminating!"
            open={open}
            onCancel={handleCancel}
            footer={[
              <ButtonOutlined key="cancel" onClick={handleCancel}>
                Cancel
              </ButtonOutlined>,
              <ButtonFilled key="save" type="primary" onClick={handleOk}>
                Terminate
              </ButtonFilled>,
            ]}
          >
            <div className="content_style">
              On terminating the employee, their access to company resources
              will be revoked and their performance cannot be tracked.
            </div>
          </StyledNewModal>

          <Modal
            title="Approve leave"
            open={isModalOpen}
            onOk={approveHandleOk}
            onCancel={approveHandleCancel}
            footer={[
              <Button key="back" onClick={approveHandleCancel}>
                No
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={approveHandleOk}
              >
                Yes
              </Button>,
            ]}
          >
            <p>Are you sure you want to approve leave?</p>
          </Modal>

          <RejectReasonModal
            isOpenModal={displayDeclineReasonModal}
            closeModal={closeDeclineReasonModal}
          />
        </>
      )}

      {displayEditEmployeeModal && (
        <EditEmployee
          isModalVisible={displayEditEmployeeModal}
          closeModal={() => toggleEditEmployeeModal(false)}
          getEmployeeList={fetchMasterData}
          EmployeeDetails={EmployeeDetails}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  ClientId: getClientid(state),
  departmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  roleList: getRoleList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  userInfo: getUserInfo(state),
  leaveCategoryList: getLeaveCategoryList(state),
  menuList: getMenuList(state),
  userRoleId: getUserRoleId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
      saveProfilePic: setProfilePic,
    },
    dispatch
  );
const StyledNewModal = styled(Modal)`
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-title {
    color: #1f243b !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
  .ant-modal-body {
    padding: 0px 17px 30px 24px;
  }
  .ant-modal-header {
    border-bottom: none !important;
    border-radius: 40px 40px 0 0;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-footer {
    border-top: none !important;
  }
  .content_style {
    color: #636980;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const StyledModal = styled(Modal)`
.ant-btn[disabled] {
    cursor: not-allowed;
    color: #00000085 !important;
}
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-header {
    border-bottom: none !important;
    border-radius: 40px 40px 0 0;
  }
  .ant-modal-footer {
    border-top: none !important;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .modal {
    display: flex;
    justify-content: center;
    border-radius: 10px;
  }
  .ant-modal-body > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .editor_div {
    width: 75%;
  }
`;
const StyledProfileDetails = styled.div`
  .terminate {
    margin-left: 20px !important;
  }
  .Icon_style {
    font-size: "20px";
  }
  .StyledImage {
    background-color: white;
    vertical-align: middle;
  }
  .ant-badge .ant-scroll-number-custom-component {
    transform: translate(0%, 0%) !important ;
    bottom: 0px !important;
    top: 89px;
  }
  .styled_col {
    display: flex;
    align-items: center;
    @media (max-width: 428px) {
      flex-direction: column;
    }
  }
  .camera_select_icon {
    font-size: 22px;
  }
  .camera_select_icon rect {
    fill: #1890ff;
  }
  .Remove_select_icon {
    margin-left: -10px !important;
    margin-right: 6px !important;
    margin-top: -2px !important;
  }
  .Edit_select_icon {
    height: 30px !important;
    width: 29px !important;
    margin-left: -10px !important;
    margin-top: -2px !important;
  }
  .Edit_select_icon rect {
    fill: none;
  }
  .leave_approval_actions {
    display: flex;
    gap: 10px;
  }
  .leave_tracker_container {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 27px 0px #00000017;
    border-radius: 14px;
  }
  .leave_tracker_chart {
    display: flex;
    gap: 1rem;
    @media (max-width: 890px) {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
  .css-1ps7utq {
    margin-bottom: 0 !important;
  }
  .employee_work_info_box {
    width: 100%;
    margin: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
  }
  .employess_detail_values {
    text-wrap: nowrap;
  }
  .info_identity_container {
    width: 100%;
    margin: auto;
  }
  .personal_details_box {
    width: 100%;
    margin: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
  }
  .employee_work_info {
    border-bottom: 1px solid #f8f8f8;
  }
  .employess_detail_summary {
    display: flex;
    flex-direction: column;
  }
  .employee_detail_headings {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #1f243b;
    margin-left: 10px;
  }
  .leave_title_content {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #1f243b;
  }
  .Broker_profile_flex {
    align-items: center;
    padding: 2rem;
    border-radius: 5px;
  }
  .Broker_profile_box {
    width: max-content;
    flex: unset;
    display: flex;
    align-items: end;
    gap: 0.6rem;
    padding-left: 0;
  }
  .broker_profile_img {
    width: 150px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
  }
  .profile_verified_img {
    width: 20px;
  }
  .broker_name {
    text-align: left;
    font: normal normal bold 24px/30px Circular Std;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .broker_tittle {
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
  .common_color_style {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #636980;
    margin-top: 5px;
  }

  .user_details {
    display: flex;
    width: max-content;
    gap: 5px;
    text-align: left;
    font: normal normal bold 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
    p {
      margin-bottom: 0.5rem;
    }
  }
  .contact_details {
    display: flex;
    gap: 0.6rem;
    text-wrap: nowrap;
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    color: #ffffff;
    p {
      margin-bottom: 0rem;
    }
  }
  .Engaged {
    font: normal normal medium 14px/20px Circular Std;
    letter-spacing: 0.07px;
    color: #3b3938;
    opacity: 1;
    margin-top: 5px;
  }
  .activity_content {
    text-align: center;
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    color: #3b3938;
    opacity: 1;
  }
  .employee_bond_text {
    text-align: center;
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    color: #3b3938;
    opacity: 1;
  }
  .contact_details {
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    color: #ffffff;
  }
  .contact_det_header {
    font: normal normal bold 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
  .contact_det_content {
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
  .broker_score_hr {
    margin: 0rem;
    background: #00000029;
    color: #00000029;
    opacity: 1;
  }
  .vr {
    display: inline-block;
    align-self: stretch;
    width: 2px;
    height: 26px !important;
    background: #000000;
    color: #000000;
    opacity: 1;
  }
  .credit_box_container {
    display: flex;
    justify-content: end;
    gap: 0.8rem;
    padding-right: 0;
    .credits_box {
      .user_score_title {
        width: 100%;
        text-align: center;
        font: normal normal normal 16px/20px Circular Std;
        letter-spacing: 0px;
        color: #000000;
        opacity: 0.8;
        margin-bottom: 0rem;
        .user_score_status {
          font: normal normal bold 24px/30px Circular Std;
          letter-spacing: 0px;
          color: #3da000;
          opacity: 1;
        }
        .user_working_hour {
          font: normal normal bold 24px/20px Circular Std;
          letter-spacing: 0px;
          color: #000000;
          small {
            font: normal normal normal 16px/20px Circular Std;
            letter-spacing: 0px;
            color: #000000;
          }
        }
      }
    }
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .credits_box {
    display: flex;
    flex: 1 0 200px;
    height: 150px;
    flex-direction: column;
    text-align: center;
    justify-content: space-evenly;
    background: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    text-align: left;
    h2 {
      font: normal normal bold 36px/46px Circular Std;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 0rem;
    }
    .active_status {
      font: normal normal normal 16px/20px Circular Std;
      letter-spacing: 0px;
      color: #000000;
      opacity: 0.8;
    }
  }
  .reactive_button {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #000000;
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .employess_detail_values {
      text-wrap: wrap;
    }
  }
  .ant-tabs-nav-wrap {
    border-bottom: 2px solid #e2e2e8;
    color: #636980 !important;
  }
  .ant-tabs-tab-active {
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3019;
  }
  .ant-tabs-ink-bar {
    background-color: #2d53da;
  }
  .ant-tabs-tab {
    color: #636980;
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20.83px;
  }
  
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2d53da;
  }
`;

const StyledTable = styled(Table)`
  padding: 0;
  margin-bottom: 0;
  table {
    table-layout: auto !important;
  }
  .ant-table-wrapper {
    margin-bottom: 0;
    border: 2px solid red !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #e2e2e8;

    border-radius: 8px;
  }
  .ant-table-cell {
    height: 50px;
  }
  .ant-table-thead .ant-table-cell {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #1f243b;
  }
  .ant-table-tbody > tr > td {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3019;
    color: ${COLORS.TEXT.PRIMARY};
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    white-space: normal;
  }
`;
const StyledAvatar = styled(Avatar)`
  font-size: 3rem;
  height: 120px;
  width: 120px;
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  .ant-avatar-string {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  .my-1 {
    display: flex;
    word-wrap: break-word;
  }
  .label_Color {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3019;
    color: ${COLORS.TEXT.PRIMARY};
  }
  .label_value_Color {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #1f243b;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
