import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Avatar,
  Modal,
  Skeleton,
  Tooltip,
  Popover,
} from "antd";
import { Link } from "react-router-dom";
import logo from "../assets/tymeplushrmobileheader.svg";
import userlogo from "../assets/tymeplushrmobileheader.svg";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import OutlinedInput from "@mui/material/OutlinedInput";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import {
  Select as MUISelect,
  Chip,
  Box,
  createTheme,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useMedia } from "react-use";
import { startCase, get } from "lodash";
import { connect } from "react-redux";
import {
  getUserName,
  getSelectedDepartmentId,
  getDepartmentList,
  getRoleName,
  getDepartmentName,
  getLoadingDataFirstTime,
  getUserRoleId,
  getRoleList,
  getUserRoleList,
  getNotificationList,
  getUserInfo,
  getProfilePic,
  getAllDepartmentList,
  getClientid,
  getMenuList,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import {
  setDepartmentId,
  setNotificationList,
  clearStore,
} from "redux/actions";
import styled from "@emotion/styled";
import COLORS from "common/Colors";

// Components
import CustomIcon from "atoms/Icon";
import SwitchUserModal from "organisms/SwitchUserModal";
import { DEVICE } from "common/Device";
import DashboardTop from "./DashboardTop";
import LeaveForm from "organisms/LeaveForm";
import Subscription from "./Subscription";
import { Products } from "common/Constants";
import { ButtonOutlined } from "reusableComponent/UIButtons";

const AntHeader = Layout.Header;
const StyledCol = styled(Col)`
  text-align: right;

  @media ${DEVICE.mobileL} {
    margin-left: 0px;
  }
  @media ${DEVICE.mobileM} {
    margin-left: 0px;
    text-align: none;
  }
`;

const StyleSubmitButton = styled(ButtonOutlined)`
  margin-right: 0.7rem;

  @media (min-width: 320px) and (max-width: 374px) {
    margin-top: 65px;
  }
  @media (min-width: 375px) and (max-width: 424px) {
    margin-top: 65px;
    padding: 3px;
    font-size: 12px !important;
  }
  @media (min-width: 425px) and (max-width: 550px) {
    margin-top: 65px;
  }
  @media (max-width: 325px) {
    padding: 3px;
    font-size: 12px !important;
  }
`;

const StyledIconContainer = styled.div`
  padding-left: 10px;

  padding-right: 10px;
  display: block;
  white-space: pre;
  @media ${DEVICE.tablet} {
    margin-top: -50px;
    text-align: right;
  }
  @media ${DEVICE.mobileL} {
    .apply-leave-sm {
      font-size: 12px;
      padding-left: 3px;
      padding-right: 3px;
    }
    .switch-role-sm {
      font-size: 12px;
      padding-left: 3px;
      padding-right: 3px;
    }
    .select {
      margin-top: 65px;
      text-align: right;
    }
    @media (max-width: 426px) {
      display: flex;
      white-space: pre;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-content: flex-end;
      align-items: flex-end;
    }
  }
`;

const StyledAntHeader = styled(AntHeader)`
  padding: 0px;
  box-shadow: 0px 2px 5px 0px #b1b1b1;
  background: #ffffff;
  z-index: 9;
  height: auto;
  .left-header-skeleton {
    margin: 10px;
  }
  .right-header-skeleton {
    margin: 10px;
  }
  .icon {
    color: black;
  }
  .ant-popover-inner {
    margin: 0 10px;
  }
  @media (max-width: 430px) {
    line-height: 24px !important;
  }
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 0.5rem;
  margin-bottom: 5px;
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
`;

const StyleSuperHODDiv = styled.div`
  padding-left: 5px;
  padding-top: 10px;
  .hide {
    display: inline;
  }
  .dept_info {
    min-width: 300px;
    max-width: 300px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      min-width: 200px;
      margin-top: 13px;
    }
  }
  span {
    line-height: 20px;
    display: block;
  }
  img {
    display: none;
  }
  @media ${DEVICE.tablet} {
    .hide {
      display: none;
    }
    span {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    img {
      display: inline;
    }
  }
  @media (max-width: 1250px) {
    span {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  @media (max-width: 320px) {
    span {
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const menuIconStyle = `
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
`;

const StyledMenuUnfoldOutlined = styled(MenuUnfoldOutlined)`
  ${menuIconStyle}
`;
const StyledMenuFoldOutlined = styled(MenuFoldOutlined)`
  ${menuIconStyle}
`;

const StyledMenuToggle = styled.div`
  display: none;
  @media ${DEVICE.tablet} {
    display: inline-flex;
    z-index:100;
    position: relative;
    float: left;
    margin-left: 3px;
    padding: 10px;
    .anticon-menu-fold,
    .anticon-menu-unfold {
      padding: 2px 0px 0px 0px;
    }
  }

  ${({ collapsed }) => collapsed && `margin-left: -10px`}
`;
const StyledMenuToggle1 = styled.div`
  display: inline;
  float: left;
  padding: 10px;
  .anticon-menu-fold,
  .anticon-menu-unfold {
    padding: 2px 0px 0px 0px;
  }

  ${({ collapsed }) => collapsed && `margin-left: -10px`}
  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const StyledHeaderIcons = styled.div`
  display: inline-block;
  @media ${DEVICE.tablet} {
    margin-top: -30px;
    position: absolute;
    top: -54px;
    right: 0px;
  }

  .ant-popover-inner {
    margin: 0 10px !important;
  }
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      fontFamily: "Segoe UI Symbol",
      fontSize: "14px",
      overflow: "auto",
    },
  },
};

const customizeMenu = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "0px",
          whiteSpace: "unset",
        },
      },
    },
  },
});
const ontlineInput = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontFamily: "Segoe UI Symbol",
          borderWidth: "1px",
          maxHeight: "35px",
          "&:focus": {
            borderWidth: "1px",
          },
        },
      },
    },
  },
});
const theme = createTheme({
  components: {
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontFamily: "Segoe UI Symbol",
          color: "#0000000",
          fontWeight: 400,
          padding: "5px",
        },
      },
    },
  },
});

const Header = ({
  setPageTitle,
  selectedDepartmentId,
  departmentName,
  updateDepartmentId,
  userName,
  collapsed,
  toggleCollapse,
  isLoadingDataFirstTime,
  userRoleId,
  roleList,
  showDrawer,
  userInfo,
  profilePic,
  allDepartmentList,
  clientId,
  setshowplan,
  showplan,
  isLoggedOut,
  menuList
}) => {

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const [userRoleModal, setUserRoleModal] = useState(false);
  const [deptFullList, setDeptFullList] = useState([]);
  const [displayLeaveModal, toggleLeaveModal] = useState(false);

  const filterUserRoleList =
    roleList?.filter((item) => userInfo?.role_id?.includes(item.roleId)) || [];

  const filterRoleList = roleList?.filter(
    (item) => item?.roleId === userRoleId
  );

  const filterDepartmentId = filterRoleList[0]?.department;
  const filterDepartment = allDepartmentList?.filter((item) =>
    filterDepartmentId?.includes(item?.department_id)
  );

  const logoutUser = () => {
    Modal.confirm({
      icon: "",
      content: "Are you sure you want to logout?",
      okText: "Logout",
      cancelText: "Cancel",
      cancelButtonProps: {
        style: { borderColor: "#2D53DA", color: "#2D53DA" },
      },
      onOk: () => {
        setTimeout(() => {
          window.localStorage.removeItem("token");
          isLoggedOut();
          window.location.href = "https://www.tymeplushr.com";
        }, 500);
      },
    });
  };

  useEffect(() => {
    intialFunction();
  }, [departmentName]);

  const intialFunction = async () => {
    const newArr = [];
    await filterDepartment?.forEach((element) => {
      selectedDepartmentId.forEach((e) => {
        if (element.department_id === e) {
          newArr.push(element.department_name);
        }
      });
    });
    setDeptFullList(newArr);
  };

  const handleCheckBoxChange = (event) => {
    let a = event.target.value;
    const newArr = [];
    filterDepartment.forEach((element) => {
      a.forEach((e) => {
        if (element.department_name === e) {
          newArr.push(element.department_id);
        }
      });
      updateDepartmentId(newArr);
    });
    setDeptFullList(typeof a === "string" ? a.split(",") : a);
  };

  const getNotificationList = async () => {
    if (clientId) {
    }
  };

  useEffect(() => {
    getNotificationList();
    const timer = setInterval(() => {
      getNotificationList();
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  const headerSkeleton = (classname) => (
    <Skeleton.Input className={classname} active={true} size="small" />
  );

  const ExtramediumDevice = useMedia("(min-width: 1200px)");

  const mediumDevice = useMedia("(min-width: 900px)");

  const imgStyle = { height: "30px", marginTop: "5px" };
  const customIconStyle = { height: "16px", cursor: "pointer" };

  const [showSwitchApp, setShowSwitchApp] = useState(false);

  const id = showSwitchApp ? "simple-popper" : undefined;

  return (
    <StyledAntHeader>
      <Row>
        <Col xs={24} sm={24} md={24} lg={6} xl={4} className="d-flex">
          {isLoadingDataFirstTime && headerSkeleton("left-header-skeleton")}
          {!isLoadingDataFirstTime && (
            <StyleSuperHODDiv>
              <Row>
                <Col>
                  <Row>
                    <Link to={`dashboard`} onClick={() => setPageTitle("")}>
                      <img style={imgStyle} src={userRoleId === "1" ? logo : userlogo} alt="Tymeplus" />
                    </Link>
                  </Row>

                  <StyledMenuToggle1 className="hide" collapsed={collapsed}>
                    {React.createElement(
                      collapsed
                        ? StyledMenuFoldOutlined
                        : StyledMenuUnfoldOutlined,
                      {
                        onClick: toggleCollapse,
                      }
                    )}
                  </StyledMenuToggle1>

                  <StyledMenuToggle collapsed={collapsed}>
                    {React.createElement(
                      collapsed
                        ? StyledMenuFoldOutlined
                        : StyledMenuUnfoldOutlined,
                      {
                        onClick: showDrawer,
                      }
                    )}
                  </StyledMenuToggle>
                </Col>
              </Row>
            </StyleSuperHODDiv>
          )}
          <DashboardTop />
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={4}>
          <div></div>
        </Col>
        <StyledCol xs={24} sm={24} md={24} lg={12} xl={16}>
          <StyledIconContainer>
            {isLoadingDataFirstTime && (
              <>
                {headerSkeleton("right-header-skeleton")}
                {headerSkeleton("right-header-skeleton")}
              </>
            )}
            {!isLoadingDataFirstTime && (
              <>
                {showplan && (
                  <Subscription
                    className="bg-white"
                    onClick={() => setshowplan(true)}
                    showplan={showplan}
                    setshowplan={setshowplan}
                  />
                )}
                {userRoleId !== "1" && filterUserRoleList?.length > 1 && (
                  <StyleSubmitButton
                    className="switch-role-sm mx-2"
                    onClick={() => setUserRoleModal(true)}
                  >
                    Switch Role
                  </StyleSubmitButton>
                )}
                {userRoleId !== "1" && (Menulist?.find(
            (item) =>
              item?.key === "mycalendar" && item?.permission?.create
          )) && (
                  <ButtonOutlined
                    className="apply-leave-sm"
                    onClick={() => toggleLeaveModal(true)}
                  >
                    Apply for Leave
                  </ButtonOutlined>
                )}
                {deptFullList.length != 0 && (
                  <FormControl
                    className="select"
                    sx={{
                      m: 1,
                      width: ExtramediumDevice
                        ? "120px"
                        : mediumDevice
                          ? "60px"
                          : "100px",
                      marginTop: 2,
                      fontFamily: "Segoe UI Symbol",
                    }}
                  >
                    <MUISelect
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={deptFullList}
                      onChange={handleCheckBoxChange}
                      input={
                        <OutlinedInput
                          theme={ontlineInput}
                          sx={{ height: 50 }}
                        />
                      }
                      defaultValue={selectedDepartmentId}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.1,
                            maxHeight: 30,
                            overflow: "hidden",
                          }}
                        >
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              sx={{
                                borderRadius: "4px",
                                width: "65px",
                                height: "29px",
                              }}
                              className="text-truncate"
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {filterDepartment?.map((name) => (
                        <MenuItem
                          disableGutters={true}
                          key={name?.department_name}
                          value={name?.department_name}
                          theme={customizeMenu}
                          selected={false}
                          disableTouchRipple
                          disabled={
                            deptFullList.length === 1 &&
                            deptFullList[0] === name.department_name
                          }
                        >
                          <Checkbox
                            checked={
                              deptFullList?.indexOf(name.department_name) > -1
                            }
                          />
                          <ListItemText
                            disableTypography={true}
                            theme={theme}
                            primary={name?.department_name}
                          />
                        </MenuItem>
                      ))}
                    </MUISelect>
                  </FormControl>
                )}
                <StyledHeaderIcons>
                  <Link
                    className="mx-2"
                    to={`my-profile`}
                    onClick={() => setPageTitle("Notification")}
                  >
                    <Tooltip placement="bottom" title={userName}>
                      {profilePic === null ||
                        Array.isArray(profilePic) ||
                        profilePic === "" ? (
                        <>
                          <StyledAvatar size="default">
                            {startCase(get(userInfo, "name", "").charAt(0))}
                          </StyledAvatar>
                        </>
                      ) : (
                        <>
                          <StyledAvatar
                            src={profilePic}
                            size="default"
                          ></StyledAvatar>
                        </>
                      )}
                    </Tooltip>
                  </Link>
                  {/* // Note : need to add release Notes */}
                  {/* <Link
                    className="mx-2"
                    to={`released-notes`}
                    onClick={() => setPageTitle("Release Notes")}
                  >
                    <Tooltip placement="bottom" title={"Release Notes"}>
                      <InfoIcon className="icon" />
                    </Tooltip>
                  </Link> */}
                  <Popover
                    placement="bottomRight"
                    content={
                      <div className="p-4">
                        <Row>
                          {Products?.map((val, index) => {
                            if (val?.access) {
                              return (
                                <Col
                                  md={8}
                                  key={index}
                                  className="items"
                                  onClick={() => {
                                    val?.url && window.open(val.url, "_self");
                                  }}
                                >
                                  <div className="logo d-flex justify-content-center align-items-center">
                                    <img
                                      width={60}
                                      height={60}
                                      src={val?.logo}
                                      alt={`${val?.name} Logo`}
                                    />
                                  </div>
                                  <p className="d-flex justify-content-center align-items-center text-center fw-medium">
                                    {val?.name}
                                  </p>
                                </Col>
                              );
                            }
                          })}
                        </Row>
                      </div>
                    }
                    trigger="click"
                    open={showSwitchApp}
                  >
                    {/* This code will need in future not now, so I have commented */}
                    {/* <IconButton
                      className="icon-btn"
                      onClick={(event) => {
                        setShowSwitchApp((prev) => !prev);
                      }}
                      aria-describedby={id}
                    >
                      {!showSwitchApp ? <AppsIcon /> : <CloseIcon />}
                    </IconButton> */}
                  </Popover>
                  {!userRoleId === 1 && (
                    <Link
                      className="mx-2"
                      to={`close-account`}
                      onClick={() => setPageTitle("Close Account")}
                    >
                      <Tooltip placement="bottom" title={"close account"}>
                        <SettingsIcon className="icon" />
                      </Tooltip>
                    </Link>
                  )}

                  <CustomIcon
                    className="m-2"
                    name="logout"
                    onClick={logoutUser}
                    style={customIconStyle}
                  />
                </StyledHeaderIcons>
              </>
            )}
          </StyledIconContainer>
        </StyledCol>
      </Row>

      <SwitchUserModal
        onDashboardPg={true}
        userRoleModal={userRoleModal}
        closeModal={() => setUserRoleModal(false)}
      />

      <LeaveForm
        isModalVisible={displayLeaveModal}
        closeModal={() => toggleLeaveModal(false)}
        leaveDetails={{}}
      />
    </StyledAntHeader>
  );
};

const mapStateToProps = (state) => ({
  userName: getUserName(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  departmentList: getDepartmentList(state),
  roleName: getRoleName(state),
  departmentName: getDepartmentName(state),
  isLoadingDataFirstTime: getLoadingDataFirstTime(state),
  userRoleId: getUserRoleId(state),
  roleList: getRoleList(state),
  userRoleList: getUserRoleList(state),
  notificationList: getNotificationList(state),
  userInfo: getUserInfo(state),
  profilePic: getProfilePic(state),
  allDepartmentList: getAllDepartmentList(state),
  clientId: getClientid(state),
  menuList: getMenuList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDepartmentId: setDepartmentId,
      saveNotificationList: setNotificationList,
      removeStoreData: clearStore,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
