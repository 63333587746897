import { Col, Row } from 'antd'
import React from 'react'
import UploadFiles from './UploadFiles'

function DetailListing({ item, goback, MenuList }) {
    const backStyle = { cursor: "pointer" }
    return (
        <div>
            <h6><span style={backStyle} onClick={goback}>Documents</span> <strong> {">"} {item?.fullname}</strong></h6>
            <hr />
            <Row gutter={16}>
                    <Col md={12} sm={24} xs={24}>
                        <h6>Employee Documents</h6>
                        <UploadFiles confidential={false} item={item} MenuList={MenuList}/>
                    </Col>
               
                    <Col md={12} sm={24} xs={24}>
                        <h6>Confidential Documents</h6>
                        <UploadFiles confidential={true} item={item} MenuList={MenuList}/>
                    </Col>
            </Row>
        </div>
    )
}

export default DetailListing