const queryKeys = {
  getActivityLog: (queryparams) => ({
    key: `get-activity-log-${queryparams}`,
    url: `activitylog${queryparams}`,
  }),
  getRoleDataList: (clientId) => ({
    key: `get-role-list`,
    url: `owner-roles/${clientId}`,
  }),
 
};

export default queryKeys;
