import React, { useRef, useState } from 'react';
import { Card, Row } from 'antd';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RoundIcon from 'atoms/RoundIcon';
import AddEditPolicy from './AddEditPolicy';
import COLORS from 'common/Colors';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CALL_API } from 'common/API';
import { Spinner } from 'react-bootstrap';

function Details({ selectedRow, ClientId, userInfo, goBack, getPolicyData, deletePolicy, Menulist }) {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);

  const deleteData = async (id) => {
    deletePolicy(id);
    goBack();
    await CALL_API(`hrpolicy/${ClientId}?policyId=${selectedRow?.policyId}`, 'delete', {});
  };
  const generatePDF = () => {
    setLoading(true);
    const reportElement = containerRef.current;
    const reportWidth = 595.28;
    const reportHeight = 841.89;
    const margin = 20;

    html2canvas(reportElement, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new JsPDF('p', 'pt', [reportWidth, reportHeight]);

      const contentWidth = reportWidth - 2 * margin;
      const contentHeight = (canvas.height / canvas.width) * contentWidth;

      let yOffset = 0;
      let remainingHeight = contentHeight;

      while (remainingHeight > 0) {
        if (yOffset > 0) {
          pdf.addPage();
        }

        const pageHeight = Math.min(remainingHeight, reportHeight - 2 * margin);

        pdf.addImage(imgData, 'PNG', margin, margin + yOffset, contentWidth, pageHeight);

        yOffset += pageHeight;
        remainingHeight -= pageHeight;
      }

      pdf.save(`${selectedRow?.policyTitle}.pdf`);
      setLoading(false);
    });
  };



  const Menu = () => {
    const iconStyle = { color: 'white', fontSize: '18px' };
    return (
      <div>
        <Row gutter={16} className="d-flex gap-2">
          <RoundIcon onClick={generatePDF}>
            {loading ? <Spinner animation="border" variant="light" size="sm" /> : <LocalPrintshopIcon style={iconStyle} />}
          </RoundIcon>
          {Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "Policies" && subItem?.permission.edit)) && (
            <RoundIcon onClick={() => setIsEdit(true)}>
              <EditIcon style={iconStyle} />
            </RoundIcon>
          )}
          {Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "Policies" && subItem?.permission.delete)) && (
            <RoundIcon onClick={() => deleteData(selectedRow?.policyId)}>
              <DeleteIcon style={iconStyle} />
            </RoundIcon>
          )}
        </Row>
      </div>
    );
  };

  const titleStyle = { color: `${COLORS.PRIMARY}`, cursor: 'pointer' };

  return (
    <div>
      <h6>
        <strong style={titleStyle} onClick={() => goBack()}>
          HR Policies
        </strong>{' '}
        <span> {">"} {selectedRow?.policyTitle}</span>
      </h6>
      <br />
      {!isEdit && (
        <div ref={containerRef}>
          <Card type="inner" title={selectedRow?.policyTitle} extra={<Menu />}>
            <div dangerouslySetInnerHTML={{ __html: selectedRow?.policyDetails }} />
          </Card>
        </div>
      )}
      {isEdit && (
        <div>
          <AddEditPolicy ClientId={ClientId} userInfo={userInfo} value={selectedRow} goBack={() => goBack()} getPolicyData={() => getPolicyData()} />
        </div>
      )}
    </div>
  );
}

export default Details;
