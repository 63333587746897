export const getContractJson = (data) => {
  const ContractForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [12, 12, 12, 12, 12, [, 4]],
        fields: [
          // Row 1
          [
            {
              id: 1,
              label: "Contract title",
              type: "text",
              name: "title",
              Placeholder: "Contract title",
              value: data["title"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Contract title`,
                },
              ],
            },
            {
              id: 2,
              label: "Date",
              type: "date",
              name: "date",
              Placeholder: "Date",
              value: data["date"],
              validationRules: [
                {
                  required: true,
                  message: `Please select the Date`,
                },
              ],
            },
          ],
          [
            {
              id: 2,
              label: "Recipient Name",
              type: "text",
              name: "recipientName",
              Placeholder: "Recipient Name",
              value: data["recipientName"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Recipient Name`,
                },
              ],
            },
            {
              id: 2,
              label: "Recipient Address",
              type: "text",
              name: "recipientAddress",
              Placeholder: "Recipient Address",
              value: data["recipientAddress"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Recipient Address`,
                },
              ],
            },
          ],
          [
            {
              id: 2,
              label: "Contract Details",
              type: "editor",
              name: "contractDetails",
              Placeholder: "Contract Detailse",
              value: data["contractDetails"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Contract Detailse`,
                },
              ],
            },
          ],
          [
            {
              id: 10,   
              type: "div",
              position: "end",    
            },
            {
              id: 10,
              type: "div",
              position: "end",
            },
            {
              id: 10,
              type: "div",
              position: "end",
            },
            {
              id: 10,   
              type: "div",
              position: "end",    
            },
            {
              id: 10,
              type: "div",
              position: "end",
            },
            {
              id: 10,
              type: "div",
              position: "end",
            },
            {
              id: 11,
              label: "Cancel",
              type: "button",
              position: "end",
              background: "white", // Assuming you want a gray background
              textColor: "black",
              event: "click",
            },
            {
              id: 10,
              label: "Create",
              type: "button",
              position: "end",
              background: "blue",
              textColor: "white",
              event: "submit",
            },
          ],
        ],
      },
    ],
  };

  return {
    ContractForm,
  };
};
