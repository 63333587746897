import React from "react";
import { Row, Col } from "antd";
import { Pie } from "@ant-design/plots";
import styled from "@emotion/styled";
import RoundIcon from "atoms/RoundIcon";
import Icon from "atoms/Icon";

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b6b;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 15px;
  .styled-circle {
    svg {
    }
  }
  .report-title {
    font-weight: bold;
    font-size: 0.8rem;
    padding-top: 3px;
    padding-left: 10px;
    text-transform: uppercase;
  }
  .report-title1 {
    font-weight: bold;
    font-size: 0.7rem;
    padding-top: 3px;
  }
  .legends {
    padding-top: 2rem;
    padding-left: 5px;
    div {
      padding-bottom: 5px;
    }
  }

  .legends {
    display: flex;
    flex-direction: column;
  }

  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .legend-item span {
    margin-left: 8px;
  }

  .legend-item .data {
    margin-left: auto;
    margin-right: 5px;
  }
`;

const StyledTitle = styled.div`
  font-size: 1.02rem;
  font-weight: 600;
  color: #141414;
  margin-right: auto;
`;

const StyledColorBox = styled.span`
  ${({ color }) => color && `background: ${color};`}
  padding: 0px 8px;
  margin-right: 10px;
  border-radius: 5px;
  width: 15px;
  height: 15px;
`;

function getRandomColor() {
  const getHighComponent = () =>
    Math.floor(Math.random() * 156 + 100)
      .toString(16)
      .padStart(2, "0");
  const getLowComponent = () =>
    Math.floor(Math.random() * 100)
      .toString(16)
      .padStart(2, "0");

  const components = [
    getHighComponent(),
    getHighComponent(),
    getLowComponent(),
  ];

  for (let i = components.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [components[i], components[j]] = [components[j], components[i]];
  }

  return `#${components.join("")}`;
}
function lightenColor(color, percent) {
  const num = parseInt(color.slice(1), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt;
  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  )
    .toString(16)
    .slice(1)}`;
}

function LeaveDonutChart({ iconName, title, data }) {
  const availableColor = getRandomColor();
  const consumedColor = lightenColor(availableColor, 30);

  const config = {
    width: 120,
    height: 120,
    appendPadding: 6,
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.70,
    color: [availableColor, consumedColor],
    tooltip: false,
    legend: false,
    label: false,
    statistic: {
      title: false,
      content: {
        style: { fontSize: 13 },
        content: `${data[1]?.value}/${data[0]?.value - data[1]?.value}`,
      },
    },
  };

  return (
    <StyledChartContainer>
      <Row>
        <Col span={24}>
          <StyledTitle>{title}</StyledTitle>
        </Col>
        <Col xs={24} sm={12}>
          <Pie {...config} />
        </Col>
        <Col xs={24} sm={12} className="legends">
          <div className="legend-item">
            <StyledColorBox color={availableColor}></StyledColorBox>
            <span>Available</span>
            <span className="data">{data[0]?.value - data[1]?.value}</span>
          </div>
          <div className="legend-item">
            <StyledColorBox color={consumedColor}></StyledColorBox>
            <span>Consumed</span>
            <span className="data">{data[1]?.value}</span>
          </div>
        </Col>
      </Row>
    </StyledChartContainer>
  );
}

export default LeaveDonutChart;
