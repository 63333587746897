import React from "react";
import styled from "@emotion/styled";
import { Row, Col, Button } from "antd";
import {
  getLocations,
  getAllDepartmentList,
  getRoleList,
} from "redux/selectors";
import { connect } from "react-redux";
import moment from "moment";

const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
  font-weight: bold;
`;

const StyledCol = styled(Col)`
  margin:10px;

  .info_heading{
    font-size: 14px;
    font-weight: 600;
    color: #636980;
  }
  .info_datas{
    font-weight: 500;
    color: #1F243B;
  }
  .inreview_title{
    font-size: 16px;
    font-weight: 600;
    width: 26%;
  }
  .inreview_boxes_container{
    display: flex;
    gap: 1rem;
  }
  .roles_location_review{
    width: max-content;
    min-height: 28px;
    font-size: 14px;
    font-weight: 400;
    color: #1F243B;
    padding: 5px 12px;
    border-radius: 4px;
    color: #1F243B;
    background-color: #F4F4F4;
    margin-bottom: 0;
  }
  .roles_location_review_section{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .roles_location_review_wrap{
    width: 100%;
    display: flex;
    gap: 10px;
  }

  @media(min-width: 992px) and (max-width: 1200px){
      .inreview_col_section{
          width: 30%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
      }
  }
  @media(min-width: 768px) and (max-width: 991px){
      .inreview_col_section{
          width: 50%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
      }
  }
  @media(min-width: 481px) and (max-width: 767px){
      .inreview_col_section{
          width: 50%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
      }
  }
  @media(min-width: 320px) and (max-width: 480px){
      .inreview_boxes_container{
          flex-direction: column; 
      }
      .inreview_col_section{
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
      }
  }
`;

const InReview = ({
  BasicInformation,
  department,
  location,
  role,
  departmentList,
  locationList,
  roleList,
  create,
  setStep,
  step,
  loading
}) => {
  const departmentdata =
    departmentList?.filter((item) =>
      department?.includes(item.department_id)
    ) || null;
  const locationdata =
    locationList?.filter((item) => location?.includes(item.location_id)) ||
    null;
  const roledata =
    roleList?.filter((item) => role?.includes(item.roleId)) || null;

    function convertToTitleCase(input) {
      // Replace underscores with spaces and split the string into an array of words
      var words = input.replace(/_/g, ' ').split(' ');
  
      // Capitalize the first letter of each word
      for (var i = 0; i < words.length; i++) {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
  
      // Join the words back together with space to form the title case string
      var titleCaseString = words.join(' ');
  
      return titleCaseString;
  }
  
  console.log("BasicInformation---->", BasicInformation)
  return (
    <>
      <Row className="p-2 flex-column">
        <StyledCol>
          <div className="inreview_boxes_container">
            <h5 className="inreview_title">Basic</h5>
            <Row className="w-100">
              {BasicInformation && Object.entries(BasicInformation).map(([key, value]) => {
                return (
                  <>
                    <Col className="inreview_col_section" xl={8} key={key}>
                      <b className="info_heading">{convertToTitleCase(key.charAt(0).toUpperCase() + key.slice(1))}</b>
                      <p className="info_datas">{(key === "start_date" || key === "end_date") ? moment(value).format('DD MMMM YYYY') : value === false ? "false" : value === true ? "true" : value}</p>
                    </Col>
                  </>
                )
              })}
            </Row>
          </div>
        </StyledCol>

        {roledata.length > 0 && (
          <StyledCol>
            <div className="inreview_boxes_container">
              <h5 className="inreview_title">Role</h5>
              <Row className="roles_location_review_wrap">
                {roledata?.map((item, key) => {
                  return (
                    <>
                      <Col className="roles_location_review_section" key={key}>
                        <p className="roles_location_review" key={item}>{item?.roleName}</p>
                      </Col>
                    </>
                  )
                })}
              </Row>
            </div>
          </StyledCol>
        )}

        {locationdata?.length > 0 && (
          <StyledCol>
            <div className="inreview_boxes_container">
              <h5 className="inreview_title">Location</h5>
              <Row className="roles_location_review_wrap">
                {locationdata?.map((item, key) => {
                  return (
                    <>
                      <Col className="roles_location_review_section" key={key}>
                        <p className="roles_location_review" key={item}>{item?.location_name}</p>
                      </Col>
                    </>
                  )
                })}
              </Row>
            </div>
          </StyledCol>
        )}

        {departmentdata?.length > 0 && (
          <StyledCol>
            <div className="inreview_boxes_container">
              <h5 className="inreview_title">Department</h5>
              <Row className="roles_location_review_wrap">
                {departmentdata?.map((item, key) => {
                  return (
                    <>
                      <Col className="roles_location_review_section" key={key}>
                        <p className="roles_location_review" key={item}>{item?.department_name}</p>
                      </Col>
                    </>
                  )
                })}
              </Row>
            </div>
          </StyledCol>
        )}

        {/* {locationdata?.length > 0 && (
          <StyledCol xl={8}>
            <h5>Location</h5>
            <ul>
              {locationdata?.map((item) => (
                <li key={item}>{item?.location_name}</li>
              ))}
            </ul>
          </StyledCol>
        )} */}
        {/* {departmentdata.length > 0 && (
          <StyledCol xl={8}>
            <h5>Department</h5>
            <ul>
              {departmentdata?.map((item) => (
                <li key={item}>{item?.department_name}</li>
              ))}
            </ul>
          </StyledCol>
        )} */}
        {/* <StyledCol xl={8}>
          <h5>Basic Information</h5>
          {Object.entries(BasicInformation).map(([key, value]) => (
            <Row>
              <Col xl={12} key={key}><b>{key.charAt(0).toUpperCase() + key.slice(1)}:</b></Col><Col xl={12}>{(key === "start_date" || key === "end_date") ? moment(value).format('MMMM D, YYYY') : value == false ? "false" : value == true ? "true" : value}</Col>
            </Row>
          ))}
        </StyledCol> */}
        {/* {roledata.length > 0 && ( 
          <StyledCol xl={8}>
            <h5>Role</h5>
            <ul>
              {roledata?.map((item) => (
                <li key={item}>{item?.roleName}</li>
              ))}
            </ul>
          </StyledCol>
        )} */}
      </Row>
      <div className='w-100 text-end'>
        <StyledButton type="primary" className='mt-3 mx-2' onClick={() => setStep(step - 1)}>Back</StyledButton>
        <StyledButton className='mt-3' onClick={create} loading={loading} type="primary">Create</StyledButton>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  locationList: getLocations(state),
  departmentList: getAllDepartmentList(state),
  roleList: getRoleList(state),
});

export default connect(mapStateToProps)(InReview);
