import React, { useState } from "react";
import { Row, Form, Button, Table, Switch, Checkbox, message } from "antd";
import COLORS from "common/Colors";
import { getAllDepartmentList, getClientid } from "redux/selectors";
import StepsProgress from "../../organisms/StepsProgress";
import styled from "@emotion/styled";
import { CALL_API } from "common/API";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getAddBandJson } from "../Band/AddBandjson/index";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import InReview from "molecules/InReview";
import { connect } from "react-redux";
import { setBandList } from "redux/actions";
import { STATUS_CODE } from "common/Constants";
import { bindActionCreators } from "redux";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
  }
  .ant-table-tbody > tr > td {
    border: 1px solid #f0f0f0;
  }
   .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 5px 10px;
    height: 0px !important;
  }
  .ant-table-thead .ant-table-cell {
    background-color: #f4f4f4;
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    white-space: normal;
  }
`;

const MainComponent = styled(Form)`
  .ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .outer_row {
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 0px 5px 5px 5px;
    padding: 15px;
  }
  .in_styl {
    border-radius: 5px;
  }
  .ant-form-item-label > label {
    font-size: 16px;
    font-weight: bold;
  }

  .ant-table-thead > tr > th:nth-child(2) {
    text-align: center;
  }
  .employee-count-container {
    text-align: center;
  }
  .employee-count-container div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-table-tbody > tr > td {
    font-size: 16px;
    color: #000000;
    border-right: 1px solid #d9d9d9;
  }
  .wid_adjust {
    width: 49%;
  }
  .step_styl {
    display: flex;
    justify-content: center;
  }
  .final_div {
    display: flex;
    justify-content: flex-end;
  }
  .adjust_btn {
    padding-left: 5px;
  }
`;
const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
  float: right;
  margin-top: 20px;
  font-weight: bold;
`;
const Addband = ({
  departmentList,
  clientId,
  onClose,
  fetchData,
  totaldata,
  saveBandList
}) => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormdata] = useState();
  const [step, setStep] = useState(0);
  const [selectAllDepartmentChecked, setSelectAllDepartmentChecked] =
    useState(false);
  const [selectDepartmentId, setSelectDepartmentId] = useState([]);
  const [data, setData] = useState({});
  const { AddBandForm } = getAddBandJson(data);
  const [loading, setLoading] = useState(false);

  const stepdata = [
    { id: 1, title: "Basic" },
    { id: 2, title: "Department" },
    { id: 2, title: "Review" },
  ];

  const columns = [
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "department_id",
      key: "department_id",
      width: "10%",
      align: "center",
      render: (department_id, record) => {
        return (
          <Switch
            size="small"
            checked={
              selectAllDepartmentChecked ||
              selectDepartmentId.includes(department_id)
            }
            onChange={(e) => {
              getDepartmentId(department_id, e);
            }}
          />
        );
      },
    },
  ];

  const getDepartmentId = (department_id, checked) => {
    const newDepartmentId = checked
      ? [...selectDepartmentId, department_id]
      : selectDepartmentId.filter((item) => item !== department_id);
    setSelectDepartmentId(newDepartmentId);
  };
  const handleSelectAllDepartment = (e) => {
    const checked = e.target.checked;
    setSelectAllDepartmentChecked(checked);
    const allDepartmentIds = departmentList.map((item) => item.department_id);
    setSelectDepartmentId(checked ? allDepartmentIds : []);
  };
  const nextForm = () => {
    setShowForm(true);
  };
  const secondnextForm = () => {
    setStep(2);
    setShowForm(true);
  };
  const backForm = () => {
    onClose(true);
  };
  const handlePrevious = () => {
    setShowForm(false);
    setStep(0);
  };
  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitForm = (event) => {
    setFormdata(event);
    setStep(1);
    nextForm();
  };

  const fetchMasterData = async (fetch_master_data) => {
    const { code, bandList } = await CALL_API(
      `master-data/${clientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
          saveBandList(bandList);
    }
  };

  const basicInformationData = {
    "Band Name": data.bandname,
    "Salary From": data.salaryfrom,
    "Salary To": data.salaryto,
    "Experience From":
      data.experiencefrom && data.experiencefrom.isValid()
        ? data.experiencefrom.format("yyyy")
        : "",
    "Experience To":
      data.experienceto && data.experienceto.isValid()
        ? data.experienceto.format("yyyy")
        : "",
  }
  const obj = {
    bandname: data.bandname,
    departmentid: selectDepartmentId || "",
    salaryfrom: data.salaryfrom,
    salaryto: data.salaryto,
    experiencefrom:
      data.experiencefrom && data.experiencefrom.isValid()
        ? data.experiencefrom.format("yyyy")
        : "",
    experienceto:
      data.experienceto && data.experienceto.isValid()
        ? data.experienceto.format("yyyy")
        : "",
  };
  const onSubmit = async () => {
    setLoading(true)
    const response = await CALL_API(`band/${clientId}`, "post", obj);
    if (response.code === "SUCCESS") {
      fetchData();
      message.success("Band Created Successfully");
      onClose(true);
      fetchMasterData();
      setStep(0);
    } else {
      message.error("Error creating band. Please try again.");
    }
    setLoading(true)
  };

  return (
    <>
      <MainComponent>
        <Row>
          <>
            <label
              className="bg-transparent border-0 mb-3 text-left styled-cursor"
              onClick={() => backForm()}
            >
              <b className="">Band</b> <ArrowForwardIosIcon className="px-1" />
            </label>{" "}
            <b className="pb-0">Add Band</b>
          </>
        </Row>
        <StepsProgress stepdata={stepdata} title="Add Band:" step={step} />

        {showForm ? (
          step === 1 ? (
            <>
              <Checkbox
                className="mt-3"
                checked={selectAllDepartmentChecked}
                onChange={handleSelectAllDepartment}
              >
                Select All
              </Checkbox>
              <div className="outer_row">
                <StyledTable
                  columns={columns}
                  dataSource={departmentList}
                  rowKey="break_id"
                  pagination={false}
                />
                <Row className="final_div">
                  <div>
                    <StyledButton
                      type="primary"
                      htmlType=""
                      onClick={() => {
                        handlePrevious();
                      }}
                    >
                      Back
                    </StyledButton>
                  </div>
                  <div className="adjust_btn">
                    <StyledButton
                      type="primary"
                      htmlType=""
                      onClick={() => {
                        secondnextForm();
                      }}
                    >
                      Next
                    </StyledButton>
                  </div>
                </Row>
              </div>
            </>
          ) : (
            <>
              <InReview
                step={step}
                setStep={setStep}
                BasicInformation={basicInformationData}
                department={selectDepartmentId}
                setShowForm={setShowForm}
                create={onSubmit}
                loading={loading}
              />
            </>
          )
        ) : (
          <Form>
            <div className="outer_row">
              <GridBuilder
                formStructureJson={AddBandForm}
                handleChange={handleFormChange}
                formSubmit={handleSubmitForm}
                initialValues={data}
              />
            </div>
          </Form>
        )}
      </MainComponent>
    </>
  );
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveBandList: setBandList,
    },
    dispatch
  );
const mapStateToProps = (state) => ({
  departmentList: getAllDepartmentList(state),
  clientId: getClientid(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(Addband);
