import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Spin, message } from 'antd';
// Redux
import { connect } from 'react-redux';
import {
    getUserRoleId,
    getClientid,
    getMenuList,
} from 'redux/selectors';
import styled from 'styled-components';
import Dropzone from 'molecules/Dropzone';
import COLORS from 'common/Colors';
import { get, map, includes } from 'lodash';
import Document from './Document';
import { CALL_API } from 'common/API';
import { STATUS_CODE } from 'common/Constants';
import { DEVICE } from 'common/Device';

const StyledDocumentPageTitle = styled.h2`
  font-weight: 500;
  margin-bottom: 0px;
`;

const StyleDocumentRow = styled(Row)`
  padding-top: 20px;
`;

const StyledDocumentDiv = styled.div`
  margin-top: 20px;
  border: solid 1px ${COLORS.PRIMARY};
  border-radius: 5px;
  padding: 10px;
  height: 100%;
  .nodata{
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
      letter-spacing: 2px;
      font-weight: bold;
      color:${COLORS.GREY};
    }
  }
  ${({ height }) => height && `height: ${height}px; min-height: ${height}px; max-height: ${height}px; overflow: auto;`};
  @media ${DEVICE.tablet}  {
    margin-top: 20px;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
`;

const StyledDocumentContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

function UploadFiles({
    userRoleId,
    ClientId,
    menuList,
    confidential,
    item
}) {
    const [documentList, setDocumentList] = useState([]);
    const [height, setHeight] = useState(200);
    const [fileName, setFileName] = useState('');
    const [isUploading, toggleUploading] = useState(false);
    const [isLoadingDocuments, toggleLoadingDocuments] = useState(false);

    const Menulist = menuList[userRoleId] || Object.values(menuList)[0];



    useEffect(() => {
        getDocumentList();
    }, []);

    const getDocumentList = async () => {
        toggleLoadingDocuments(true);
        const { list } = await CALL_API(`document-list/${ClientId}`, "post", {
            method: 'document-list',
            userId: item?.userid
        });
        const confidentialList = [];
        const nonConfidentialList = [];

        list?.forEach(item => {
            if (item?.permissionType === 'confidential') {
                confidentialList.push(item);
            } else if (item?.permissionType === 'non-confidential') {
                nonConfidentialList.push(item);
            }
        });
        setDocumentList(confidential ? confidentialList : nonConfidentialList);
        toggleLoadingDocuments(false);
    }

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.map(file => {
            const reader = new FileReader();
            reader.onload = async function (e) {
                toggleUploading(true);
                if (includes(["jpg", "png", 'jpeg', 'svg', 'xls', 'xlsx', 'pdf', 'csv', 'doc', 'docx', 'txt', 'ppt', 'avi', 'zip', 'mov'], get(file, 'type', '').split('/')[1])) {
                    const { code } = await CALL_API(`upload-document/${ClientId}`, 'post', {
                        name: get(file, 'name', ''),
                        type: get(file, 'type', ''),
                        content: e.target.result,
                        userId: item?.userid, 
                        permissionType: confidential ? "confidential" : "non-confidential"
                    });
                    if (code === STATUS_CODE.SUCCESS) {
                        message.success('Document uploaded');
                        toggleUploading(false);
                        getDocumentList();
                    } else {
                        message.error('Something went wrong!! Please try again.');
                    }
                }
                else message.error('Invalid file format');
                toggleUploading(false);
            };
            reader.readAsDataURL(file);
            return file;
        });
    }, []);

    const deleteDocument = async (id) => {
        const response = await CALL_API(`upload-document/${ClientId}/${id}`, 'delete', {
            // documentId : id, //Commented Code so that it's used in future
            status_id: 0
        });
        message.success("Document has been Deleted")
        getDocumentList();
    }

    useEffect(() => {
        setHeight(window.innerHeight - document.querySelector('.ant-layout-header').getBoundingClientRect().height - 100);

        window.uploadDocumentFromApp = async (base64, name, MIMEtype) => {
            toggleUploading(true);
            const { code } = await CALL_API(`upload-document/${ClientId}`, 'post', {
                name,
                type: MIMEtype,
                content: base64,
                userId: item?.userid, 
                permissionType: confidential ? "confidential" : "non-confidential"
            });
            if (code === STATUS_CODE.SUCCESS) {
                message.success('Document uploaded');
                toggleUploading(false);
                getDocumentList();
            } else {
                message.error('Something went wrong!! Please try again.');
            }
        }
    }, []);

    const uploadDocument = () => {
        if (navigator.userAgent === 'application') {
            window.Print.postMessage("upload-document");
        }
    }

    return (
        <>
            {/* <StyledDocumentPageTitle>
        My Documents
      </StyledDocumentPageTitle> */}
            <StyleDocumentRow gutter={16}>
            {Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "documents" && subItem?.permission.create)) && (
                <Col md={24} sm={24} xs={24} onClick={uploadDocument}>
                    <Spin spinning={isUploading}>
                        <Dropzone
                            onDrop={onDrop}
                            accept={"image/*,.png,.jpeg"}
                            fileName={fileName}
                            access={Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "documents" && subItem?.permission.create))}
                        />
                    </Spin>
                </Col>
                )}

                <Col md={24} sm={24} xs={24}>
                    <Spin spinning={isLoadingDocuments}>
                        <StyledDocumentDiv height={height}>
                            {documentList?.length > 0 ?
                                <Row gutter={24}>
                                    {
                                        map(documentList, ({ documentId, documentName, createdon }, index) => (
                                            <Col md={12} lg={8} sm={12} xs={12} key={index}>
                                                <StyledDocumentContainer >
                                                    <Document
                                                        id={documentId}
                                                        name={documentName}
                                                        staffName=''
                                                        uploadTime={createdon}
                                                        deleteDocument={deleteDocument}
                                                    />
                                                </StyledDocumentContainer>
                                            </Col>
                                        ))
                                    }
                                </Row>
                                :
                                <div className='nodata'>
                                    <p>No Data</p>
                                </div>}
                        </StyledDocumentDiv>
                    </Spin>
                </Col>
            </StyleDocumentRow>
        </>
    );
}

const mapStateToProps = state => ({
    userRoleId: getUserRoleId(state),
    ClientId: getClientid(state),
    menuList: getMenuList(state),
});

export default connect(mapStateToProps, null)(UploadFiles);
