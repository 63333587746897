import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Table, Spin, Select, message, TimePicker } from "antd";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getStatusListFromID,
  getAllDepartmentList,
  getClientid,
  getMenuList,
  getLocations
} from "redux/selectors";
import COLORS from "common/Colors";
import { CALL_API } from "common/API";
import { CSVLink } from "react-csv"; 
import { reduce, concat, uniqBy } from "lodash";
import moment from "moment";
import { STATUS_CODE } from "common/Constants";
import { ButtonOutlined } from "reusableComponent/UIButtons";
import { setUserList } from "redux/actions";
import { bindActionCreators } from "redux";

const StyledTable = styled(Table)`
  padding-top: 10px;
  table {
    width: 100% !important;
    border: 1px solid #ccc;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    color: ${COLORS.TEXT.PRIMARY};
    font-size: 14px;
  }
  .ant-table-row:nth-child(even) {
    background-color: #fafafa;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.TEXT.PRIMARY};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledSelect = styled(Select)`
  width: 200px;
  margin: 0px;
`;

function Shift({ userRoleId, selectedDepartmentId, ClientId, menuList, updateUserList, locationList }) {
  const [shiftList, setShiftList] = useState([]);
  const [displayshiftLoader, setDisplayshiftLoader] = useState(false);
  const [shiftCSVlist, setShiftCSVlist] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedStaff, setSelectedStaff] = useState("");
  const [shiftFilterList, setShiftFilterList] = useState([]);
  const csvLinkEl = useRef();
  const containerRef = useRef(null);

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  useEffect(() => {
    if (userRoleId !== 5) setSelectedDepartment(selectedDepartmentId);
  }, [selectedDepartmentId]);

  const STAFF_LIST = uniqBy(
    shiftList.map((val) => {
      return { user_id: val?.userid, name: val?.fullname };
    }),
    "user_id"
  );

  useEffect(() => {
    var result;

    if (selectedStaff === "") {
      result = shiftList;
    } else {
      result = shiftList?.filter((data) => data?.userid === selectedStaff);
    }

    setShiftFilterList(result);
  }, [selectedDepartment, shiftList, selectedStaff]);

  const CSVHeaders = [
    { label: "Employee ID", key: "employeeid" },
    { label: "Name", key: "username" },
    { label: "Shift Start Time", key: "shiftstarttime" },
    { label: "Shift End Time", key: "shiftendtime" },
    {label: "Timezone", key: "timezone"}
  ];

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "employeeid",
      key: "employeeid",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
      width: "10%",
    },
    {
      title: "Shift Start Time",
      dataIndex: "shiftstarttime",
      key: "shiftstarttime",
      width: "10%",
      render: (_, render) => {
        let date = render?.shiftstarttime;
        return (
          <div>
            <TimePicker
              disabled={
                Menulist?.find(
                  (item) =>
                    item?.key === "manage" &&
                    item?.subMenu.find(
                      (subItem) =>
                        subItem?.key === "shift" && subItem?.permission.edit
                    )
                )
                  ? false
                  : true
              }
              defaultValue={moment(date, "HH:mm")}
              format={"HH:mm"}
              onChange={(val) => {
                onUpdate(render?.userid, val, render?.shiftendtime);
              }}
              clearIcon={null}
              getPopupContainer={() => containerRef.current}
            />
          </div>
        );
      },
    },
    {
      title: "Shift End Time",
      dataIndex: "shiftendtime",
      key: "shiftendtime",
      width: "10%",
      render: (_, render) => {
        let date = render?.shiftendtime;
        return (
          <div>
            <TimePicker
              disabled={
                Menulist?.find(
                  (item) =>
                    item?.key === "manage" &&
                    item?.subMenu.find(
                      (subItem) =>
                        subItem?.key === "shift" && subItem?.permission.edit
                    )
                )
                  ? false
                  : true
              }
              defaultValue={moment(date, "HH:mm")}
              format={"HH:mm"}
              onChange={(val) => {
                onUpdate(render?.userid, render?.shiftstarttime, val);
              }}
              clearIcon={null}
              getPopupContainer={() => containerRef.current}
            />
          </div>
        );
      },
    },
    {
      title: "Timezone",
      dataIndex: "baselocationid",
      key: "baselocationid",
      width: "10%",
      render: (baselocationid) => {
        const timezone = locationList.filter((item)=> item.location_id === baselocationid)
        return timezone[0]?.usertimezone
      },
    },
  ];

  useEffect(() => {
    getShiftList();
  }, [setShiftList]);

  const getShiftList = async () => {
    try {
      setDisplayshiftLoader(true);
      const response = await CALL_API(`user-shift/${ClientId}`, "get", {});
      setShiftList(response);
      setDisplayshiftLoader(false);
    } catch (error) {
      console.log("[getShiftList] Error-->", error);
    }
  };

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
        updateUserList(userList);
    }
  };

  const onUpdate = async (Id, start_time, end_time) => {
    let shiftStartTime = moment(start_time, "HH:mm").format("HH:mm");
    let shiftEndTime = moment(end_time, "HH:mm").format("HH:mm");
    if (shiftStartTime || shiftEndTime) {
      setDisplayshiftLoader(true);
      const { code } = await CALL_API(`user-shift/${ClientId}/${Id}`, "patch", {
        shift_start_time: moment(shiftStartTime, "HH:mm").format("HH:mm"),
        shift_end_time: moment(shiftEndTime, "HH:mm").format("HH:mm"),
      });
      if (code === STATUS_CODE.SUCCESS) {
        setDisplayshiftLoader(false);
        message.success(`Successfully Updated`);
        getShiftList();
        fetchMasterData();
      } else {
        message.error(`Something went wrong. Please try again.`);
      }
    }
  };

  const prepareCSV = async () => {
    if (shiftFilterList?.length > 0) {
      setShiftCSVlist(
        reduce(
          shiftFilterList,

          function (result, list) {
            const timezone = locationList.filter((item)=> item.location_id === list?.baselocationid)
            result.push({
              "Employee ID": list?.employeeid,
              Name: list?.fullname,
              "Shift Start Time": moment(
                list?.shiftstarttime,
                "HH:mm:ss"
              ).format("HH:mm"),
              "Shift End Time": moment(list?.shiftendtime, "HH:mm:ss").format(
                "HH:mm"
              ),
              "Timezone": timezone[0]?.usertimezone
            });
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  };
  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 100,
    showSizeChanger: false,
  };
  const headerstyle = { fontSize: "20px", fontWeight: "bold" };

  return (
    <>
      <Row className="page-title">
        <Col span={12} className="mt-2" style={headerstyle}>
          List of Shift
        </Col>
        <Col span={12} className="align-right">
          <ButtonOutlined type="link" onClick={prepareCSV}>
            Export CSV file
          </ButtonOutlined>
          <CSVLink
            header={CSVHeaders}
            data={shiftCSVlist}
            filename={"shift-management.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>

      <>
        <StyledSelect
          placeholder="Select Employee"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.name ?? "").toLowerCase().includes(input?.toLowerCase())
          }
          options={concat(
            {
              user_id: "",
              name: "All Employee",
            },
            STAFF_LIST
          )}
          fieldNames={{
            label: "name",
            value: "user_id",
          }}
          onChange={(id) => setSelectedStaff(id)}
        />
      </>

      <Spin spinning={displayshiftLoader}>
        <StyledTable
          ref={containerRef}
          dataSource={shiftFilterList}
          columns={columns}
          // note : first parameter is needed
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="userid"
          pagination={shiftFilterList?.length > 100 ? paginationOptions : false}
        />
      </Spin>
    </>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  statusListFromID: getStatusListFromID(state, [4, 5, 6]),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
  menuList: getMenuList(state),
  locationList: getLocations(state),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(Shift);
