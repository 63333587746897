import React, { useState } from "react";
import { Button, Modal, Row } from "antd";
import styled from "@emotion/styled";

const PageIndicator = styled.div`
  background: #e2e2e8;
  border-radius: 10px;
  padding: 0px 15px;
  font-size: 17px;
  margin: 10px;
  cursor: pointer;
  color: #646a81;
  font-weight: 600;

  &.active {
    color: #6d87e6;
  }
`;

const ExpenseTitle = styled.div`
  margin: 10px;
  margin-left: 0 !important;
  font-size: 18px;
`;

const StyledContainer = styled.div`
  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Optional: Adjust as needed */
  }

  .title {
    font-size: 15px;
    color: #636980
  }

  .value{
    font-size: 17px;
    color:#000000D9
  }
`;

const FileContainer = styled.div`
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: grey;
`;

const Fileindex = styled.span`
  font-size: 18px;
`;

const BtnContainer = styled.div`
  background: #e2e2e8;
  border-radius: 50px;
  padding: 12px;
  font-size: 20px;
  margin: 10px;
  cursor: pointer;
  color: #646a81;
  font-weight: 900;

  .next-btn {
    margin: 0 10px;
  }

  .next-btn:hover {
    color: #6d87e6;
  }
`;

const ViewBillProff = ({
  bills,
  selected,
  setselected,
  active,
  setActive,
  view,
}) => {
  const [fileIndex, setFileIndex] = useState(0);
  const nextFile = () => {
    const nextIndex = (fileIndex + 1) % selected.files.length;
    const nextActive = parseInt(active);
    if (nextIndex === 0 && nextActive + 1 < bills.length) {
      setActive(nextActive + 1);
      setselected(bills[nextActive + 1]);
    }

    setFileIndex(nextIndex);
  };


  const previousFile = () => {
    const prevIndex =
      fileIndex === 0 ? selected.files.length - 1 : fileIndex - 1;
    const nextActive = parseInt(active);
    if (fileIndex === 0 && nextActive > 0) {
      setActive(nextActive - 1);
      setselected(bills[nextActive - 1]);
    }
    setFileIndex(prevIndex);
  };

  return (
    <div>
      <Row>
        <ExpenseTitle>Expense</ExpenseTitle>
        {bills.map((data, index) => (
          <PageIndicator
            key={index}
            className={active === index ? "active" : ""}
            onClick={() => {
              setselected(data);
              setActive(index);
            }}
          >
            {index + 1}
          </PageIndicator>
        ))}
      </Row>
      <StyledContainer>
        <div>
          <span className="title">Name of Expense</span>
          <span className="value">
            {selected?.nameofexpense}
          </span>
        </div>
        <div>
          <span className="title">Total amount spent</span>
          <span className="value">${selected?.totalAmount}</span>
        </div>
      </StyledContainer>
      <FileContainer>
        <img
          src={
            view
              ? selected?.files[fileIndex]
              : selected?.files[fileIndex] instanceof File
              ? URL.createObjectURL(selected?.files[fileIndex])
              : selected?.files[fileIndex]
          }
          alt="bill"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </FileContainer>

      <div className="d-flex align-items-center">
        <div className="me-auto">
          <Fileindex>{active + 1}</Fileindex> <Fileindex>-</Fileindex>{" "}
          <Fileindex>
            {fileIndex + 1}/{selected?.files?.length}
          </Fileindex>
        </div>
        <BtnContainer>
          <span className="next-btn" onClick={previousFile}>
            {"<"}
          </span>
          <span className="next-btn" onClick={nextFile}>
            {">"}
          </span>
        </BtnContainer>
      </div>
    </div>
  );
};

export default ViewBillProff;
