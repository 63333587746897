import {
  Row,
  Col,
  Table,
  Spin,
  Select,
  DatePicker,
  Space,
  Form,
  Menu,
  Dropdown,
  Input,
  Typography,
  Modal,
  message,
  Radio,
  Button,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import styled from "@emotion/styled";
import { CurrenciesList, STATUS_CODE, TYPE_OF_EXPENSE } from "common/Constants";
import AddBills from "./AddBills";
import COLORS from "common/Colors";
import Editicon from "../../../../assets/Edit_Pencil_01.svg";
import DeletIcon from "../../../../assets/Trash_Full.svg";
import { IconButton } from "@mui/material";
import ViewBillProff from "./ViewBillProff";
import { CALL_API } from "common/API";
import axios from "axios";
import EditIcon from "../../../../assets/Edit_Pencil_01.svg";
import DeleteIcon from "../../../../assets/Trash_Full.svg";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
  }

  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .ant-table-cell:nth-last-child(2) {
    min-width: 200px;
    white-space: normal;
  }
  .non-white {
    background: #f9f9f9;
  }
`;
const StyledIndicator = styled.span`
  color: #77f777;
  padding: 5px 10px;
  background: #edfbf1;
  border-radius: 10px;
  margin: 0 20px;
  font-weight: 500;
`;
const StyledDeclineIndicator = styled.span`
  color: #ee2a3b;
  padding: 5px 10px;
  background: #fcd8db;
  border-radius: 10px;
  margin: 0 20px;
  font-weight: 500;
`;

const StyledID = styled.span`
  font-weight: 600;
  font-size: 18px;
  opacity: 0.7;
`;
const StyledDateIndicator = styled.div`
  background: #e3e8fc;
  padding: 5px 15px;
  font-size: 15px;
  color: #000000ab;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 10px;
  // margin-left: 30px;
  margin-right: 30px;

  .divider {
    font-size: 15px;
    font-weight: 900;
    color: white;
  }
  @media (min-width: 1200px) and (max-width: 1380px) {
    font-size: 12px;
  }

  @media (min-width: 995px) and (max-width: 1200px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    .divider {
      display: none !important;
    }
  }

  @media (min-width: 766px) and (max-width: 875px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    .divider {
      display: none !important;
    }
  }

  @media (max-width: 576px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ButtonsWrapper = styled.div`
  margin-left: 10px;
`;

const StyledDiv = styled.div`
  .title {
    font-size: 18px;
    color: #636980;
    font-weight: 600;
  }

  .subtitle {
    font-size: 14px;
    color: #636980;
    font-weight: 400;
  }

  .billtitle {
    font-size: 18px;
    color: #1f243b;
    font-weight: 500;
  }

  .page-title {
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-bills {
    background: #e2e2e8;
    border-radius: 10px;
    padding: 6px;
    font-size: 17px;
    margin: 10px 0;
    color: #646a81;
  }

  .add-active {
    color: #6d87e6 !important;
    cursor: pointer;
  }

  .bill-container {
    border: 1px solid #0000003b;
    border-radius: 10px;
    background: #fafafa;
    padding: 15px;
  }

  .file-box {
    border: 1px solid #0000003b;
    background: white;
    border-radius: 10px;
    padding: 10px;
  }

  .plus-btn {
    background: #e2e2e8;
    border-radius: 10px;
    padding: 6px;
    font-size: 17px;
    color: #6d87e6 !important;
    cursor: pointer;
  }

  .file-container {
    display: flex;
    flex-wrap: wrap;
  }
  .pointer {
    cursor: pointer;
  }

  .text-btn {
    color: #3c5fdd !important;
  }
`;

const RequestToReimburse = ({
  setOpen,
  selectedItem,
  value,
  onChangeEdit,
  edit,
  onChangeEditdate,
  updateDays,
  onChange,
  onChangeValue,
  disabledDateAfter,
  disabledDateBefore,
  setType,
  type,
  setBills,
  bills,
  tempFile,
  uploadView,
  setTempFile,
  setUploadView,
  ClientId,
  setValue,
  userInfo,
  updateSuccessModal,
  getData,
  formData,
  setFormData,
  reset,
  view,
  getFilesId,
  setEdit,
  setDeleteId,
  setDeleteData,
  setView,
  validateFields,
  errors,
  validateField,
  setErrors,
  hasDeletePermission,
  hasEditPermission,
  setSelectedCurrency,
  selectedCurrency,
}) => {
  const containerRef = useRef(null);

  const [addbill, setAddBill] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setselected] = useState("");
  const [active, setActive] = useState("");

  const [editedIndex, setEditedIndex] = useState(null);

  const handleSelectChange = (selectedValue) => {
    setType(selectedValue);
    setErrors({ ...errors, typeofExpense: false });
  };

  useEffect(() => {
    if (edit) {
      setType(selectedItem.typeofExpense);
      setBills(selectedItem.billProofs);
    }
  }, [edit]);

  const handleShowModal = (record, index) => {
    setVisible(true);
    setselected(record);
    setActive(index);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: "Name of Expense",
      dataIndex: "nameofexpense",
      key: "nameofexpense",
    },
    {
      title: "Amount Spent",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (totalAmount, record) => {
        const currency = CurrenciesList.find(
          (currency) => currency.code === record.amountType
        );
        const symbol = currency ? currency.symbol : "";
        return symbol +" "+ totalAmount;
      },
    },
    {
      title: "Bills",
      dataIndex: "fileNames",
      key: "fileNames",
      render: (fileNames, record, index) => (
        <div>
          {fileNames.map((file, index) => (
            <Button
              onClick={() => handleShowModal(record, index)}
              type="text"
              className="text-btn"
              key={index}
            >
              {file}
            </Button>
          ))}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (_, record, index) => (
        <>
          {!view ? (
            <div>
              <IconButton onClick={() => handleEdit(record, index, edit)}>
                <img src={Editicon} alt="edit" />
              </IconButton>
              <IconButton onClick={() => handleDelete(record, index)}>
                <img src={DeletIcon} alt="delete" />
              </IconButton>
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const [editedRecords, setEditedRecords] = useState([]);
  const [editBills, setEditBills] = useState(false);
  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [travelData, setTravelData] = useState(false);

  const handleDelete = (record, index) => {
    const updatedBills = [...bills.slice(0, index), ...bills.slice(index + 1)];
    setBills(updatedBills);
  };

  const handleEdit = (record, index) => {
    setEditedRecords([...bills]);
    setEditBills(true);
    if (edit) {
      setFormData({
        ...record,
        files: [],
        fileNames: [],
      });
    } else {
      setFormData(record);
    }

    setEditedIndex(index);
    setAddBill(true);
    const updatedBills = bills.filter((_, i) => i !== index);
    setBills(updatedBills);
  };

  const handleCancelEdit = () => {
    setBills(editedRecords);
    setEditBills(false);
    setFormData({
      nameofexpense: "",
      totalAmount: "",
      amountType: selectedCurrency,
      files: [],
      fileNames: [],
    });
    setEditedIndex(null);
    setAddBill(false);
  };

  const handleSave = () => {
    const updatedBills = [...bills];
    setEditBills(false);
    updatedBills.splice(editedIndex, 0, formData);
    setBills(updatedBills);
    setFormData({
      nameofexpense: "",
      totalAmount: "",
      amountType: selectedCurrency,
      files: [],
      fileNames: [],
    });
    setEditedIndex(null);
    setAddBill(false);
  };

  const getTravelById = async (id) => {
    setDisplayTableLoader(true);
    const { code, data } = await CALL_API(
      `/travel-expense?clientId=${ClientId}&id=${id}`,
      "get"
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (data) {
        if (data?.length === undefined) {
          setTravelData(true);
        } else {
          setTravelData(false);
        }
        setDisplayTableLoader(false);
        setValue((prevState) => ({
          ...prevState,
          reason: data.reason,
          destination: data.destination,
          departure: data.departure,
          arrival: data.arrival,
          days: data.days,
        }));
      } else {
        setTravelData(false);
        message.error("Travel ID not found!");
        setValue((prevState) => ({
          ...prevState,
          travelId: "",
        }));
        setDisplayTableLoader(false);
      }
    } else {
      message.error("Travel ID not found!");
      setDisplayTableLoader(false);
    }
  };

  const postReimbursement = async () => {
    if (!validateFields()) {
      return;
    }
    let obj =
      type === "travel"
        ? {
            travelId: value.travelId,
            reason: value.reason,
            days: value.days,
            departure: value.departure,
            arrival: value.arrival,
            destination: value.destination,
            clientId: ClientId,
            userId: userInfo.userid,
            billProofs: bills,
          }
        : type === "others"
        ? {
            reason: value.reasonforExpense,
            date: {
              dateType: value.dateType,
              specificdate: value.specificdate,
              expenseMadefrom: value.expenseMadefrom,
              expenseMadetill: value.expenseMadetill,
            },
            clientId: ClientId,
            userId: userInfo.userid,
            billProofs: bills,
          }
        : {};
    setDisplayTableLoader(true);
    try {
      const { code, id } = await CALL_API(
        `travel-expense/reimbursement?type=${type}`,
        "post",
        obj
      );
      if (code === STATUS_CODE.SUCCESS) {
        for (let j = 0; j < bills.length; j++) {
          for (let i = 0; i < bills[j].files.length; i++) {
            await postFilesUpload(
              id,
              bills[j].files[i],
              i,
              bills[j].fileNames[i]
            );
          }
        }
      }
    } catch (error) {
      message.error("Failed to submit reimbursement.");
    } finally {
      setDisplayTableLoader(false);
    }
  };
  const editReimbursement = async () => {
    if (editBills) {
      // handleCancelEdit()
      message.error("Please save editing bill proof");
      return;
    }
    let obj =
      type === "travel"
        ? {
            id: selectedItem.id,
            travelId: selectedItem.travelId,
            reason: selectedItem.reason,
            days: selectedItem.days,
            departure: selectedItem.departure,
            arrival: selectedItem.arrival,
            destination: selectedItem.destination,
            clientId: ClientId,
            userId: userInfo.userid,
            billProofs: bills,
          }
        : type === "others"
        ? {
            id: selectedItem.id,
            reason: selectedItem.reason,
            date: {
              dateType: selectedItem.date.dateType,
              specificdate: selectedItem.date.specificdate,
              expenseMadefrom: selectedItem.date.expenseMadefrom,
              expenseMadetill: selectedItem.date.expenseMadetill,
            },
            clientId: ClientId,
            userId: userInfo.userid,
            billProofs: bills,
          }
        : {};
    setDisplayTableLoader(true);
    try {
      const { code, id } = await CALL_API(
        `travel-expense/reimbursement?type=${type}`,
        "patch",
        obj
      );
      if (code === STATUS_CODE.SUCCESS) {
        for (let j = 0; j < bills.length; j++) {
          for (let i = 0; i < bills[j].files.length; i++) {
            await postFilesUpload(
              selectedItem.id,
              bills[j].files[i],
              i,
              bills[j].fileNames[i]
            );
          }
        }
      }
    } catch (error) {
      message.error("Failed to submit reimbursement.");
    } finally {
      setDisplayTableLoader(false);
    }
  };

  const postFilesUpload = async (id, file, index, fileNames) => {
    const isFile = file instanceof File;
    if (isFile) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const { code, url } = await CALL_API(
          `/travel-expense/reimbursement/billproofs`,
          "post",
          {
            fileName: fileNames,
            id: id,
            clientId: ClientId,
          }
        );

        if (code === STATUS_CODE.SUCCESS) {
          postFiles(file, url);
          updateSuccessModal({
            visibility: true,
            message: "Submitted",
          });
          reset();
          setOpen(false);
          getData();
        }
      } catch (error) {
        message.error("Failed to upload file.");
      }
    } else {
      updateSuccessModal({
        visibility: true,
        message: "Submitted",
      });
      reset();
      setOpen(false);
      getData();
    }
  };

  const postFiles = async (file, url) => {
    setDisplayTableLoader(true);

    try {
      const response = await axios.put(url, file);

      if (response.data.code === STATUS_CODE.SUCCESS) {
      }
    } catch (error) {
      message.error("Failed to submit file.");
    } finally {
      setDisplayTableLoader(false);
    }
  };

  const calculateTotalAmount = (billProofs) => {
    let totalExpense = 0;

    for (let i = 0; i < billProofs.length; i++) {
      const bill = billProofs[i];
      if (typeof bill.totalAmount === "string") {
        const amount = parseInt(bill.totalAmount);
        if (!isNaN(amount)) {
          totalExpense += amount;
        } else {
          console.error("Invalid totalAmount value:", bill.totalAmount);
        }
      } else if (typeof bill.totalAmount === "number") {
        totalExpense += bill.totalAmount;
      } else {
        console.error("Invalid totalAmount value:", bill.totalAmount);
      }
    }
    return totalExpense;
  };

  const BillCurrency = ()=>{
    const currency = CurrenciesList.find(
      (currency) => currency.code === selectedCurrency
    );
    const symbol = currency ? currency.symbol : "";

    return symbol;
  }

  return (
    <StyledDiv>
      <div>
        <span
          onClick={() => {
            setOpen(false);
            reset();
          }}
          style={{ cursor: "pointer" }}
          className="title"
        >
          Travel & Expense
        </span>
        <span className="mx-2">{">"}</span>
        <span className="page-title">
          {view
            ? "View details"
            : edit
            ? "Editing Reimbursement Request"
            : "Requesting to Reimburse"}
        </span>
      </div>
      {edit & !view ? (
        <div className="d-flex my-3">
          <StyledID className="mt-1 me-4">
            Reimb.ID : {selectedItem.id}
          </StyledID>
          <StyledDateIndicator>
            Requested on{" "}
            {moment(selectedItem?.requestedOn).format("Do MMMM, YYYY")}
          </StyledDateIndicator>
        </div>
      ) : (
        <></>
      )}
      {view ? (
        <>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Row className="d-flex my-3">
                <StyledID className="mt-1 me-4">
                  Reimb.ID : {selectedItem.reimbursementId}
                </StyledID>
                {selectedItem.status === "Approved" ? (
                  <StyledIndicator>{selectedItem?.status}</StyledIndicator>
                ) : selectedItem.status === "Declined" ? (
                  <StyledDeclineIndicator>
                    {selectedItem?.status}
                  </StyledDeclineIndicator>
                ) : (
                  <StyledDateIndicator>
                    <span>
                      Requested on{" "}
                      {moment(selectedItem?.requestedOn).format(
                        "Do MMMM, YYYY"
                      )}
                    </span>
                  </StyledDateIndicator>
                )}
              </Row>
            </Col>
            <Col md={24} lg={12}>
              {selectedItem.status !== "Waiting" ? (
                <StyledDateIndicator>
                  <span>
                    Requested on{" "}
                    {moment(selectedItem?.requestedOn).format("Do MMMM, YYYY")}
                  </span>

                  <span className="d-none d-sm-block divider">|</span>
                  <span>
                    {" "}
                    {selectedItem.status} on{" "}
                    {moment(selectedItem?.approvedDate).format("Do MMMM, YYYY")}
                  </span>
                </StyledDateIndicator>
              ) : (
                <>
                  <span className="d-flex justify-content-end">
                    {hasEditPermission && (
                      <IconButton
                        onClick={() => {
                          setOpen(true);
                          setEdit(true);
                          getFilesId(selectedItem?.id);
                          setView(false);
                        }}
                      >
                        {" "}
                        <img src={EditIcon} alt="edit" />{" "}
                      </IconButton>
                    )}
                    {hasDeletePermission && (
                      <IconButton
                        onClick={() => {
                          setDeleteId(selectedItem?.id);
                          setDeleteData(true);
                        }}
                      >
                        {" "}
                        <img src={DeleteIcon} alt="delete" />{" "}
                      </IconButton>
                    )}
                  </span>
                </>
              )}
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
      <Spin spinning={displayTableLoader}>
        <div className="my-3">
          <Row>
            <Col xs={24} sm={24} md={12} className="p-2" ref={containerRef}>
              <Typography className="subtitle">
                Type of Expense<span className="error">*</span>
              </Typography>
              <Select
                placeholder="Choose"
                options={TYPE_OF_EXPENSE}
                getPopupContainer={() => containerRef.current}
                className=" w-100"
                onChange={handleSelectChange}
                value={
                  edit ? selectedItem.typeofExpense : selectedItem.typeofExpense
                }
                disabled={edit || view}
              />
              {!edit && errors.typeofExpense && (
                <span className="error">{errors.typeofExpense}</span>
              )}
            </Col>
            {type === "travel" ? (
              <>
                <Col xs={24} sm={24} md={12} className="p-2">
                  <Typography>Travel ID (If available)</Typography>
                  <Input
                    value={edit ? selectedItem?.travelId : value.travelId}
                    name="travelId"
                    onChange={(e) => {
                      if (e.target.value.length > 3) {
                        getTravelById(e.target.value);
                      }
                      edit ? onChangeEdit(e) : onChangeValue(e);
                    }}
                    disabled={view}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} className="p-2">
                  <Typography>
                    Reason for Travel<span className="error">*</span>{" "}
                  </Typography>
                  <Input
                    value={edit ? selectedItem?.reason : value.reason}
                    name="reason"
                    onChange={(e) =>
                      edit ? onChangeEdit(e) : onChangeValue(e)
                    }
                    disabled={travelData || view}
                  />
                  {!edit && errors.reason && (
                    <span className="error">{errors.reason}</span>
                  )}
                </Col>
                <Col xs={24} sm={24} md={12} className="p-2">
                  <Typography>
                    Travel Destination<span className="error">*</span>
                  </Typography>
                  <Input
                    disabled={travelData || view}
                    onChange={(e) =>
                      edit ? onChangeEdit(e) : onChangeValue(e)
                    }
                    name="destination"
                    value={edit ? selectedItem?.destination : value.destination}
                  />
                  {!edit && errors?.destination && (
                    <span className="error">{errors?.destination}</span>
                  )}
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} className="p-2">
                  <Typography>
                    Date of Departure<span className="error">*</span>
                  </Typography>
                  <DatePicker
                    name="departure"
                    className="w-100"
                    format="DD MMM YYYY"
                    disabled={travelData || view}
                    value={
                      edit
                        ? moment(selectedItem?.departure)
                        : value.departure
                        ? moment(value.departure)
                        : value.departure
                    }
                    onChange={(date, dateString) => {
                      if (edit) {
                        onChangeEditdate(date, dateString, "departure");
                        updateDays(date, moment(selectedItem?.arrival));
                      } else {
                        onChange(date, dateString, "departure");
                        updateDays(date, moment(value.arrival));
                      }
                    }}
                    disabledDate={(current) =>
                      disabledDateAfter(
                        current,
                        edit
                          ? moment(selectedItem?.arrival)
                          : moment(value.arrival)
                      )
                    }
                  />
                  {!edit && errors?.departure && (
                    <span className="error">{errors?.departure}</span>
                  )}
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} className="p-2">
                  <Typography>
                    Return date<span className="error">*</span>
                  </Typography>
                  <DatePicker
                    name="arrival"
                    className="w-100"
                    format="DD MMM YYYY"
                    disabled={travelData || view}
                    value={
                      edit
                        ? moment(selectedItem?.arrival)
                        : value.arrival
                        ? moment(value.arrival)
                        : value.arrival
                    }
                    onChange={(date, dateString) => {
                      if (edit) {
                        onChangeEditdate(date, dateString, "arrival");
                        updateDays(moment(selectedItem?.departure), date);
                      } else {
                        onChange(date, dateString, "arrival");
                        updateDays(moment(value.departure), date);
                      }
                    }}
                    disabledDate={(current) =>
                      disabledDateBefore(
                        current,
                        edit
                          ? moment(selectedItem?.departure)
                          : moment(value.departure)
                      )
                    }
                  />
                  {!edit && errors?.arrival && (
                    <span className="error">{errors?.arrival}</span>
                  )}
                </Col>
                <Col xs={24} sm={24} md={12} className="p-2">
                  <Typography>No of Days</Typography>
                  <Typography>
                    {edit ? selectedItem?.days : value.days}
                  </Typography>
                </Col>
              </>
            ) : type === "others" ? (
              <>
                <Col xs={24} sm={24} md={12} lg={12} className="p-2">
                  <Typography>
                    Reason for Expense made<span className="error">*</span>
                  </Typography>
                  <Input
                    value={edit ? selectedItem?.reason : value.reasonforExpense}
                    name={edit ? "reason" : "reasonforExpense"}
                    onChange={(e) =>
                      edit ? onChangeEdit(e) : onChangeValue(e)
                    }
                    disabled={view}
                  />
                  {!edit && errors?.reasonforExpense && (
                    <span className="error">{errors?.reasonforExpense}</span>
                  )}
                </Col>
                {!view && (
                  <Col xs={24} sm={24} md={12} lg={12} className="p-2">
                    <Typography>
                      Date of Expense made<span className="error">*</span>
                    </Typography>
                    <Radio.Group
                      name="dateType"
                      onChange={(e) =>
                        edit ? onChangeEdit(e) : onChangeValue(e)
                      }
                      value={
                        edit ? selectedItem?.date?.dateType : value.dateType
                      }
                      disabled={view}
                    >
                      <Radio value={"Specific date"}>Specific date</Radio>
                      <Radio value={"Range of dates"}>Range of dates</Radio>
                    </Radio.Group>
                    {!edit && errors?.dateType && (
                      <span className="error d-block">{errors?.dateType}</span>
                    )}
                  </Col>
                )}

                {value?.dateType === "Specific date" ||
                (edit && selectedItem?.date?.dateType === "Specific date") ? (
                  <>
                    <Col xs={24} sm={24} md={12} className="p-2">
                      <Typography>
                        {view ? "Date of Expense made" : "Choose the date"}
                        <span className="error">*</span>
                      </Typography>
                      <DatePicker
                        name="specificdate"
                        className="w-100"
                        format="DD MMM YYYY"
                        disabled={view}
                        value={
                          edit
                            ? moment(selectedItem?.date?.specificdate)
                            : value.specificdate
                        }
                        onChange={(date, dateString) => {
                          if (edit) {
                            onChangeEditdate(date, dateString, "specificdate");
                          } else {
                            onChange(date, dateString, "specificdate");
                          }
                        }}
                        disabledDate={(current) =>
                          current && current > moment().endOf("day")
                        }
                      />
                      {!edit && errors?.specificdate && (
                        <span className="error">{errors?.specificdate}</span>
                      )}
                    </Col>
                  </>
                ) : value?.dateType === "Range of dates" ||
                  (edit &&
                    selectedItem?.date?.dateType === "Range of dates") ? (
                  <>
                    <Col xs={24} sm={24} md={12} lg={6} className="p-2">
                      <Typography>
                        Expense made from<span className="error">*</span>
                      </Typography>
                      <DatePicker
                        name="departure"
                        className="w-100"
                        format="DD MMM YYYY"
                        disabled={view}
                        value={
                          edit
                            ? moment(selectedItem?.date.expenseMadefrom)
                            : value.expenseMadefrom
                        }
                        onChange={(date, dateString) => {
                          if (edit) {
                            onChangeEditdate(
                              date,
                              dateString,
                              "expenseMadefrom"
                            );
                          } else {
                            onChange(date, dateString, "expenseMadefrom");
                          }
                        }}
                        disabledDate={(current) =>
                          disabledDateAfter(
                            current,
                            edit
                              ? moment(selectedItem?.date.expenseMadetill)
                              : value.expenseMadetill
                          )
                        }
                      />
                      {!edit && errors?.expenseMadefrom && (
                        <span className="error">{errors?.expenseMadefrom}</span>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} className="p-2">
                      <Typography>
                        Expense made till<span className="error">*</span>
                      </Typography>
                      <DatePicker
                        name="arrival"
                        className="w-100"
                        disabled={view}
                        format="DD MMM YYYY"
                        value={
                          edit
                            ? moment(selectedItem?.date.expenseMadetill)
                            : value.expenseMadetill
                        }
                        onChange={(date, dateString) => {
                          if (edit) {
                            onChangeEditdate(
                              date,
                              dateString,
                              "expenseMadetill"
                            );
                          } else {
                            onChange(date, dateString, "expenseMadetill");
                          }
                        }}
                        disabledDate={(current) =>
                          disabledDateBefore(
                            current,
                            edit
                              ? moment(selectedItem?.expenseMadefrom)
                              : value.expenseMadefrom
                          )
                        }
                      />
                      {!edit && errors?.expenseMadetill && (
                        <span className="error">{errors?.expenseMadetill}</span>
                      )}
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            <Col xs={24} sm={24} md={12} className="p-2">
              <Typography>Total Expense</Typography>
              <Typography className="fw-bold py-2 fs-6">
                
                {BillCurrency() +" "+ calculateTotalAmount(bills)}
              </Typography>
            </Col>
          </Row>

          <Row className="px-2">
            <Col sm={24}>
              {" "}
              <Typography className="billtitle">Bill Proofs</Typography>
            </Col>

            {bills && bills.length ? (
              <div className="w-100 mb-3">
                <StyledTable
                  dataSource={bills}
                  columns={columns}
                  rowKey="user_leave_id"
                  pagination={false}
                />
              </div>
            ) : (
              <></>
            )}

            {!view ? (
              <>
                {addbill ? (
                  <AddBills
                    setBills={setBills}
                    bills={bills}
                    setAddBill={setAddBill}
                    containerRef={containerRef}
                    tempFile={tempFile}
                    uploadView={uploadView}
                    setTempFile={setTempFile}
                    setUploadView={setUploadView}
                    setFormData={setFormData}
                    formData={formData}
                    handleSave={handleSave}
                    handleCancelEdit={handleCancelEdit}
                    editBills={editBills}
                    setFileErrors={setErrors}
                    fileErrors={errors}
                    setEditBills={setEditBills}
                    setSelectedCurrency={setSelectedCurrency}
                    selectedCurrency={selectedCurrency}
                  />
                ) : (
                  <Col
                    sm={24}
                    onClick={() => type && setAddBill(true)}
                    className={`center add-bills ${type && "add-active"}`}
                  >
                    + Add Bills
                  </Col>
                )}
                {!edit && errors?.bills && (
                  <span style={{ color: "red" }}>{errors?.bills}</span>
                )}
              </>
            ) : (
              <></>
            )}
          </Row>
          {!view ? (
            <ButtonContainer>
              <ButtonsWrapper>
                <Button
                  onClick={() => setOpen(false)}
                  type="outlined"
                  className="ant-btn-outlined me-3"
                >
                  Cancel
                </Button>
              </ButtonsWrapper>
              <Button
                className="ant-btn-primary"
                onClick={edit ? editReimbursement : postReimbursement}
              >
                {edit ? "Save changes" : "Submit"}
              </Button>
            </ButtonContainer>
          ) : (
            <></>
          )}
        </div>
      </Spin>
      <Modal
        title="Bill Proof"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <ViewBillProff
          bills={bills}
          setselected={setselected}
          selected={selected}
          visible={visible}
          handleCancel={handleCancel}
          setActive={setActive}
          active={active}
          view={view}
        />
      </Modal>
    </StyledDiv>
  );
};

export default RequestToReimburse;
