import _ from 'lodash';

export const hasViewAccess = (path, routes) => {
  if(path?.length === 0) return true
  const findRoute = (routes, path) => {
    return _.some(routes, (route) => {
      if (path.includes(route.path)&& route.access && _.get(route, 'permission.view', false)) {
        return true;
      }
      if (route.subMenu && route.subMenu.length > 0) {
        return findRoute(route.subMenu, path);
      }
      return false;
    });
  };

  return findRoute(routes, path);
};