import React, { useState } from "react";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import { useHistory } from "react-router-dom";
import { getContractJson } from "JsonFormat/ContractJson";
import Icon from "atoms/Icon";
import { Button, Form, Radio, Input, message } from "antd";
import styled from "styled-components";
import { CALL_API } from "common/API";

const StyledDropzone = styled.div`
  font-size: 16px;
  .box {
    width: max-content;
    border-radius: 10px;
    padding: 15px;
    background-color: #f4f4f4;
  }
`;

const Contract = () => {
  const [data, setData] = useState({});
  const { ContractForm } = getContractJson(data);
  const [viewDetails, setViewDetails] = useState(false);

 

  const history = useHistory();

  const onFinish = (values) => {
    //need for api
  };

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitForm = (e) => {
    setViewDetails(true);
  };
  const handleClickForm = (e) => {
    history.goBack();
  };

  const handleBackForm = (e) => {
    history.goBack();
  };
  return (
    <>
      {!viewDetails && (
        <>
          Creating New Contract
          <>
            <GridBuilder
              formStructureJson={ContractForm}
              handleChange={handleFormChange}
              formSubmit={handleSubmitForm}
              initialValues={data}
              handleClick={handleClickForm}
              handleBack={handleBackForm}
            />
          </>
        </>
      )}
      {viewDetails && (
        <StyledDropzone>
          <div className="text-center">
            {" "}
            <h4>AU Full time salaried employee</h4>
          </div>
          <a className="pb-2 ">3rd,Oct,2023</a>
          <br></br>
          <a className="">Henry</a>
          <br></br>
          <a className="">The Zenith, Tower B/821 Pacific HWY,Chatswood</a>
          <br></br>
          <a className=" pb-2">Sydney</a>
          <br></br>
          <p>
            Dear <a className="">Henry</a>
          </p>
          <p>
            Welcome to <a className="">Waila Technology</a>
          </p>
          <p>
            This contract sets out the terms and connditions for the employment
          </p>
          <h4>1.Employment</h4>
          <p>
            1.1 The commencement date of your employment is{" "}
            <a className="">Date</a>
          </p>
          <div className="border-1">
            This commencement date will continue to be recognised for all
            purposes regarding your employment service, however this contract
            shall wholly replace any previous contract that applied to your
            employment from <a className="">23rd Aug, 2023</a>
          </div>
          <p>
            1.2 You are employed on a full time basis in the position ff{" "}
            <a className="">Senior Sales Representative</a>. You will report to{" "}
            <a className="">Will Smith, Managing Directer</a> or any other
            person directed by the Employer from time to time. The Employer may
            change you title, role, accountability or reporting relationship at
            any time wwhere this change would be reasonable.
          </p>
          <div className="border-1">
            1.3 You employment is subject to the{" "}
            <a className="">08th March, 2023</a> under which your
            classsification is however the terma of this Instrument are not
            incorporated into this contract nor is there any intention to give
            them any contractual eccect.
          </div>
          <br></br>
          <h4>2.Location</h4>
          2.1 Your usual place of employment will initially be{" "}
          <a className="text-center">Sydney </a>as well as any other location
          requiredto fulfil the futies of your position. You may at any time be
          required to work at a differend location nominated by the Employer on
          either a temporay ou parmanent basis.<br></br>
          If you accept the terms contained in this contract, please sign the
          declaration below.<br></br>
          Yours Sincerely<br></br>
          <div className="box mt-2">
            <Icon name="document"></Icon> Upload Employer Signature
          </div>
          <br></br>
          <a className="">Tom Jackson</a>
          <br></br>
          <a className="">Managing Directer</a>
          <br></br>
          <a className="">Waila Technology</a>
          <br></br>
          <div className="box mt-2">
            <Icon name="document"></Icon>Candidate Signature
          </div>
          <br></br>
          <a className="">Henry</a>
          <br></br>
          <hr></hr>
          <p>How do you want deliver this documents</p>
          <Form
            name="myForm"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="priority"
              rules={[{ required: true, message: "Please select priority!" }]}
            >
              <Radio.Group>
                <Radio value="low">Email</Radio>
                <Radio value="medium">Hard Copy</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="message"
              //need this for validations
              //   rules={[
              //     { required: true, message: "Please input your message!" },
              //   ]}
            >
              <Input.TextArea rows={4} placeholder="Type..." />
            </Form.Item>

            <Form.Item>
              <div className="text-end">
                <Button
                  type="secondry"
                  className="mx-2"
                  onClick={() => history.goBack()}
                >
                  cancel
                </Button>
                <Button
                  onClick={() => {
                    message.success("created successfully");
                    history.goBack();
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Done
                </Button>
              </div>
            </Form.Item>
          </Form>
        </StyledDropzone>
      )}
    </>
  );
};
export default Contract;
