import React from "react";
import { Modal, Row, Col, Typography, DatePicker, Input } from "antd";
import { ButtonOutlined, ButtonFilled } from "reusableComponent/UIButtons";
import moment from "moment";
import styled from "@emotion/styled";
import ViewBillProff from "pages/TravelAndExpense/MyTravelExpense/Reimbursement/ViewBillProff";
import { useMedia } from "react-use";

const { TextArea } = Input;

const StyledWrap = styled.div`
  .data {
    font-size: 17px;
  }
  .title {
    font-size: 15px;
    color: #636980;
  }
`;

const StyledIndicator = styled.span`
  color: #77f777;
  padding: 2px 15px;
  background: #edfbf1;
  border-radius: 20px;
  margin: 0 10px;
  font-weight: 500;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDeclineIndicator = styled.span`
  color: #ee2a3b;
  padding: 2px 15px;
  background: #fcd8db;
  border-radius: 20px;
  margin: 0 10px;
  font-weight: 500;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDateIndicator = styled.div`
  background: #e3e8fc;
  padding: 5px 15px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  width: auto;
  margin: 0 10px;
  text-align: center;

  .divider {
    font-size: 15px;
    font-weight: 900;
    color: white;
  }
  @media (min-width: 1200px) and (max-width: 1260px) {
    font-size: 10px;
  }

  @media (max-width: 576px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;
const StyledStatus = styled.div`
  display: flex;

  @media (max-width: 576px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 15px;
  }
`;
export const ViewReimbursementDetails = ({
  view,
  setView,
  selectedItem,
  setApproveData,
  setDeclineData,
  setComment,
  comment,
  error,
  setError,
  bills,
  selected,
  setselected,
  active,
  setActive,
  Menulist,
}) => {
  const calculateTotalExpense = (billProofs) => {
    let totalExpense = 0;
    if (billProofs) {
      for (let i = 0; i < billProofs.length; i++) {
        const bill = billProofs[i];
        if (typeof bill.totalAmount === "string") {
          const amount = parseInt(bill.totalAmount);
          if (!isNaN(amount)) {
            totalExpense += amount;
          } else {
            console.error("Invalid totalAmount value:", bill.totalAmount);
          }
        } else if (typeof bill.totalAmount === "number") {
          totalExpense += bill.totalAmount;
        } else {
          console.error("Invalid totalAmount value:", bill.totalAmount);
        }
      }

      return "$" + totalExpense;
    } else {
      return "$0";
    }
  };
  const isGreaterThan700px = useMedia("(min-width: 700px)");

  return (
    <Modal
      open={view}
      title={<h5>Reimb.ID: {selectedItem.reimbursementId}</h5>}
      onCancel={() => setView(false)}
      footer={null}
      closable={true}
      width="lg"
      style={{ maxWidth: isGreaterThan700px && "calc(100vw - 302px)" }}
    >
      <StyledWrap>
        <Row>
          <Col xs={24} md={24} xl={10} className="px-3 pb-3 border-left">
            <h5>Bill proof</h5>
            <ViewBillProff
              bills={bills}
              setselected={setselected}
              selected={selected}
              setActive={setActive}
              active={active}
              view={true}
            />
          </Col>
          <Col xs={24} md={24} xl={14} className="px-3 pb-3">
            <StyledStatus>
              <span>
                {selectedItem.status !== "Waiting" ? (
                  <>
                    {selectedItem.status === "Approved" ? (
                      <StyledIndicator>{selectedItem.status}</StyledIndicator>
                    ) : selectedItem.status === "Declined" ? (
                      <StyledDeclineIndicator>
                        {selectedItem.status}
                      </StyledDeclineIndicator>
                    ) : null}{" "}
                  </>
                ) : (
                  <></>
                )}
              </span>
              {selectedItem.status !== "Waiting" ? (
                // <span>
                <StyledDateIndicator>
                  {" "}
                  <span className="mx-2 d-flex justify-content-center align-items-center">
                    Requested on{" "}
                    {moment(selectedItem?.requestedOn).format("Do MMMM, YYYY")}
                  </span>
                  <span className="d-none d-sm-block divider">|</span>
                  <span className="mx-2 d-flex justify-content-center align-items-center">
                    {selectedItem.status} on{" "}
                    {moment(selectedItem?.approvedDate).format("Do MMMM, YYYY")}
                  </span>
                </StyledDateIndicator>
              ) : (
                // </span>
                <>
                  <div>
                    <StyledDateIndicator>
                      Requested on{" "}
                      {moment(selectedItem?.requestedOn).format(
                        "Do MMMM, YYYY"
                      )}
                    </StyledDateIndicator>
                  </div>
                </>
              )}
            </StyledStatus>
            <Row>
              <Col xs={24} sm={12} md={12} className="p-2">
                <Typography className="title">Type of Expense</Typography>
              </Col>
              <Col xs={24} sm={12} md={12} className="p-2">
                <Typography className="data">
                  {selectedItem.typeofExpense === "travel"
                    ? "Travel"
                    : "Others"}
                </Typography>
              </Col>
              {selectedItem?.typeofExpense === "travel" ? (
                <>
                  <Col xs={24} sm={12} md={12} className="p-2">
                    <Typography className="title">
                      Travel ID (if available)
                    </Typography>
                  </Col>{" "}
                  <Col xs={24} sm={12} md={12} className="p-2">
                    <Typography className="data">
                      {selectedItem?.travelId ? selectedItem?.travelId : "-"}
                    </Typography>
                  </Col>
                </>
              ) : (
                <></>
              )}

              <Col xs={24} sm={12} md={12} className="p-2">
                <Typography className="title">Reason for Travel</Typography>
              </Col>
              <Col xs={24} sm={12} md={12} className="p-2">
                <Typography className="data">{selectedItem?.reason}</Typography>
              </Col>
              {selectedItem?.typeofExpense === "travel" ? (
                <>
                  <Col xs={24} sm={12} md={12} className="p-2">
                    <Typography className="title">Date of Departure</Typography>
                  </Col>
                  <Col xs={24} sm={12} md={12} className="p-2">
                    <Typography className="data">
                      {moment(selectedItem?.departure).format("Do MMMM, YYYY")}
                    </Typography>
                  </Col>
                  <Col xs={24} sm={12} md={12} className="p-2">
                    <Typography className="title">Return date</Typography>
                  </Col>
                  <Col xs={24} sm={12} md={12} className="p-2">
                    <Typography className="data">
                      {moment(selectedItem?.arrival).format("Do MMMM, YYYY")}
                    </Typography>
                  </Col>
                  <Col xs={24} sm={12} md={12} className="p-2">
                    <Typography className="title">Number of Days</Typography>
                  </Col>
                  <Col xs={24} sm={12} md={12} className="p-2">
                    <Typography className="data">
                      {selectedItem?.days}
                    </Typography>
                  </Col>
                  <Col xs={24} sm={12} md={12} className="p-2">
                    <Typography className="title">
                      Travel Destination
                    </Typography>
                  </Col>
                  <Col xs={24} sm={12} md={12} className="p-2">
                    <Typography className="data">
                      {selectedItem?.destination}
                    </Typography>
                  </Col>
                </>
              ) : (
                <>
                  {selectedItem.date?.dateType === "Specific date" ? (
                    <>
                      <Col xs={24} sm={12} md={12} className="p-2">
                        <Typography className="title">
                          Date of Expense made
                        </Typography>
                      </Col>
                      <Col xs={24} sm={12} md={12} className="p-2">
                        <Typography className="data">
                          {moment(selectedItem?.date?.specificdate).format(
                            "Do MMMM, YYYY"
                          )}
                        </Typography>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={24} sm={12} md={12} className="p-2">
                        <Typography className="title">
                          Date of Expense made from
                        </Typography>
                      </Col>
                      <Col xs={24} sm={12} md={12} className="p-2">
                        <Typography className="data">
                          {moment(selectedItem?.date?.expenseMadefrom).format(
                            "Do MMMM, YYYY"
                          )}
                        </Typography>
                      </Col>{" "}
                      <Col xs={24} sm={12} md={12} className="p-2">
                        <Typography className="title">
                          Date of Expense till
                        </Typography>
                      </Col>
                      <Col xs={24} sm={12} md={12} className="p-2">
                        <Typography className="data">
                          {moment(selectedItem?.date?.expenseMadetill).format(
                            "Do MMMM, YYYY"
                          )}
                        </Typography>
                      </Col>
                    </>
                  )}
                </>
              )}
              <Col xs={24} sm={12} md={12} className="expense-bg p-2">
                <Typography className="title">Total Expense</Typography>
              </Col>

              <Col xs={24} sm={12} md={12} className="expense-bg p-2">
                <Typography className="data">
                  {calculateTotalExpense(selectedItem?.billProofs)}
                </Typography>
              </Col>

              <Col xs={24} sm={12} md={12} className="p-2">
                <Typography className="title">Comments</Typography>
              </Col>
              {selectedItem.status === "Waiting" ? (
                <>
                  {Menulist?.find(
                    (item) =>
                      item?.key === "allapprovals" &&
                      item?.subMenu?.find(
                        (subItem) =>
                          subItem?.key === "travelexpense" &&
                          subItem?.permission.edit
                      )
                  ) && (
                    <>
                      <Col xs={24} sm={24} md={24} className="p-2">
                        <TextArea
                          maxLength={300}
                          style={{
                            height: 120,
                            resize: "none",
                          }}
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        >
                          {" "}
                          {selectedItem?.comments}
                        </TextArea>
                      </Col>
                      {error && <span style={{ color: "red" }}>{error}</span>}
                    </>
                  )}
                </>
              ) : (
                <Col xs={24} sm={24} md={24} className="p-2">
                  <Typography className="data">
                    {selectedItem?.comments}
                  </Typography>
                </Col>
              )}
              {selectedItem.status === "Waiting" ? (
                <>
                  <Col xs={24} sm={24} md={24}>
                    <div className="d-flex justify-content-end">
                      <div>
                        <ButtonOutlined
                          onClick={() => {
                            if (!comment.trim()) {
                              setError("Comment cannot be empty");
                              return;
                            } else {
                              setView(false);
                              setDeclineData(true);
                            }
                          }}
                        >
                          Decline
                        </ButtonOutlined>
                      </div>
                      <div>
                        <ButtonFilled
                          onClick={() => {
                            if (!comment.trim()) {
                              setError("Comment cannot be empty");
                              return;
                            } else {
                              setView(false);
                              setApproveData(true);
                            }
                          }}
                        >
                          Approve
                        </ButtonFilled>
                      </div>
                    </div>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
          </Col>
        </Row>
      </StyledWrap>
    </Modal>
  );
};
