import React from "react";
import { Row, Col } from "antd";
import { Pie } from "@ant-design/plots";
import styled from "@emotion/styled";
import RoundIcon from "atoms/RoundIcon";
import Icon from "atoms/Icon";
import { last, map, get, first } from "lodash";

const StyledChartContainer = styled.div`
  padding: 5px;
  /* margin-top: -2%; */

  .styled-circle {
    height: 30px;
    width: 30px;
    svg {
      margin: 4px;
    }
  }
  .report-title {
    font-weight: bold;
    font-size: 0.8rem;
    padding-top: 3px;
    padding-left: 10px;
    text-transform: uppercase;
  }
  .report-title1 {
    font-weight: bold;
    font-size: 0.9rem;
    padding-top: 3px;
    padding-left: 10px;
  }
  .legends {
    padding-top: 2px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
    span {
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      @media (max-width: 426px) {
        font-size: 8px;
      }
      strong {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
`;

const StyledColorBox = styled.span`
  ${({ color }) => color && `background: ${color};`}
  padding: 0px 20px;
  border-radius: 10px;
`;

function UserMonthlyReportDonut({ iconName, title, data, workingData }) {
  const config = {
    width: 150,
    height: 150,
    appendPadding: 6,
    data,
    angleField: "value",
    colorField: "type",
    color: map(data, "color", []),
    radius: 1,
    innerRadius: 0.8,
    legend: false,
    label: false,
    statistic: {
      title: {
        offsetY: -4,
        style: {
          fontWeight: "bold",
        },
        customHtml: (container) => {
          // const { width, height } = container.getBoundingClientRect();
          // const d = 12;
          const text = `${workingData}`;
          return text;
        },
      },
      content: {
        offsetY: 4,
        style: {
          height: "20px",
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "13px",
          fontWeight: "normal",
        },
        customHtml: (container) => {
          const text = "Working Days";
          return text;
        },
      },
    },
  };
  return (
    <StyledChartContainer id="conatiner">
      <Row>
        <Col sm={24} md={12} className="mt-4">
          <Pie {...config} />
        </Col>
        <Col sm={24} md={12} className="pt-3">
          {data.map((val) => {
            return (
              <Row className="mb-2">
                <Col className="mb-1" sm={6}>
                  <span>
                    <StyledColorBox color={val.color}></StyledColorBox>
                  </span>
                </Col>
                <Col sm={14} className="mb-1 px-2">
                  {val.type}
                </Col>
                <Col sm={4} className="mb-1 px-2">
                  {val.value}
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </StyledChartContainer>
  );
}

export default UserMonthlyReportDonut;
