import React, { useEffect, useState } from 'react'
import { Input } from 'antd';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { CALL_API } from 'common/API';

const NotesContent = ({ item }) => {
    const [notes, setNotes] = useState(item?.notes || []);
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        setNotes(item?.notes || [])
    }, [item])

    const handleSendIconClick = async () => {
        if (inputValue.trim() !== '') {
            let value = inputValue
            // Add the new note to the notes array
            setNotes((prevNotes) => [...prevNotes, inputValue]);
            // Clear the input value
            setInputValue('');
            await CALL_API(`recruitment/candidate/${item?.candidateId}`, "patch", {
                notes : [...notes, value ]
            });
        }

    };

    return (
        <React.Fragment>
            <h6 className='fw-semibold mt-3'><strong>Add notes</strong></h6>

            <Input
                size="large"
                className='rounded-pill'
                placeholder="Type here"
                value={inputValue}
                onChange={handleInputChange}
                suffix={
                    <span
                        style={{ background: '#2D53DA', width: '30px', height: '30px', textAlign: 'center', cursor: 'pointer' }}
                        className='rounded-circle'
                        onClick={handleSendIconClick}
                    >
                        <SendOutlinedIcon
                            className='p-1'
                            style={{
                                color: '#fff',
                                rotate: '320deg',
                            }}
                        />
                    </span>
                }
            />
            
            {notes?.map((note, index) => (
                <h6 key={index} className='fw-semibold mt-3 text-secondary'><strong>{note}</strong></h6>
            ))}

            {notes?.length === 0 && <h6 className='fw-semibold mt-3 text-secondary'><strong>No notes yet</strong></h6>}
        </React.Fragment>
    );
};

export default NotesContent;
