export const getAddBandJson = (data) => {
  const AddBandForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Band Name:",
              type: "text",
              name: "bandname",
              Placeholder: "Bandname",
              value: data["bandname"],
              maxLength: 35,
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Band Name`,
                },
              ],
            },
          ],
          [
            {
              id: 2,
              label: "Salary from:",
              type: "text",
              name: "salaryfrom",
              Placeholder: "Salaryfrom",
              maxLength: 10,
              value: data["salaryfrom"],
              onKeyDown: /^[0-9]$/,
              validationRules: [
                {
                  required: true,
                  message: `Please enter the salary from`,
                },
              ],
            },
            {
              id: 3,
              label: "Salary To:",
              type: "text",
              name: "salaryto",
              Placeholder: "Salaryto",
              value: data["salaryto"],
              maxLength: 10,
              onKeyDown: /^[0-9]$/,
              validationRules: [
                {
                  required: true,
                  message: `Please enter the salary to`,
                },
              ],
            },
          ],
          [
            {
              id: 4,
              label: "Experience from:",
              type: "date",
              picker: "year",
              name: "experiencefrom",
              Placeholder: "Experiencefrom",
              value: data["experiencefrom"],
              validationRules: [
                {
                  required: true,
                  message: `Please select the experience from`,
                },
              ],
            },
            {
              id: 5,
              label: "Experience To:",
              type: "date",
              name: "experienceto",
              Placeholder: "Experienceto",
              picker: "year",
              value: data["experienceto"],
              validationRules: [
                {
                  required: true,
                  message: `Please select the experience to`,
                },
              ],
            },
          ],
          [
            {
              id: 6,
              label: "Next",
              type: "button",
              position: "end",
              textColor: "white",
              event: "submit",
            },
          ],
        ],
      },
    ],
  };

  return {
    AddBandForm,
  };
};
