import { Button, Modal, Result } from 'antd';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import COLORS from 'common/Colors';
import { IconButton } from '@mui/material';

export const displayErrorModal = (message) => {
  Modal.error({
    content: message,
    icon: '',
    footer: []
  });
}

export const CustomTextField = styled(TextField)({
  '& .MuiInput-root:after': {
    borderBottomColor: COLORS.PRIMARY,
  },
});

export const CustomIconButton = styled(IconButton)({
  background: COLORS.PRIMARY,
  color: COLORS.WHITE,
  '&:hover': {
    color: COLORS.BLACK
  }
});

const logOut = () => {
  window.localStorage.removeItem('token');
  window.location.href = "https://www.tymeplushr.com/";
};



export const show401Modal = () => {
  Modal.error({
    title: null,
    icon: null,
    content: (
      <Result
        status="error"
        title=""
        subTitle="Sorry, you are not authorized to access this page. Please re-login."
        extra={<Button type='primary' onClick={() => logOut()}>Log In</Button>}
      />
    ),
    maskClosable: false,
    footer: null,
    closable: false,
    okText: "Log In",
    width: 500,
    okButtonProps: { 
      style: { display: "none"}
    }
  });
};
