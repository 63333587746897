import React from 'react';
import styled from "styled-components";
import { Table } from "antd";
import COLORS from "common/Colors";

const StyledTable = styled(Table)`
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    .ant-scrollbars-hide-scrollbar & {
      overflow: hidden;
    }
    .ant-scrollbars-hide-scrollbar &::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-table-tbody > tr > td {
    border: none;
    font-size: 12px;
  }
  .ant-table-cell {
    padding: 5px 0px;
    height: 0px !important;
  }
  .ant-table-thead .ant-table-cell {
    background-color: #FFFFFF;
    color: ${COLORS.SECONDARY_BLACK};
    border:none;
    font-size: 12px;
  }
  .non-white {
    background: #F9F9F9;
  }
  .normal-column {
    white-space: normal;
  }
`;

const UITable = ({data, columns, name}) => {

    const scrollY = data?.length > 3 ? 80 : undefined;
    const shiftscrollY = data?.length > 6 ? 150 : undefined;

    const scroll = name !== "Shift" ? scrollY : shiftscrollY

  return (<>
    {data?.length > 0 ?
    <StyledTable dataSource={data} columns={columns} pagination={false} scroll={{ y: scroll }} />
    : 
    <div className='text-center mt-5 pt-4'>No Data</div>}
    </>
  );
};

export default UITable;