import { SearchOutlined } from "@mui/icons-material";
import { Input, Dropdown, Menu, message } from "antd";
import Table from "atoms/Table";
import COLORS from "common/Colors";
import React, { useState } from "react";
import { ButtonFilled } from "reusableComponent/UIButtons";
import styled from "styled-components";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Icon from "atoms/Icon";
import moment from "moment";
import { CALL_API } from "common/API";
import AlertModal from "organisms/AlertModal";
import { filter, isEmpty, isUndefined } from "lodash";

const StyledViewMenu = styled.div`
  width: 150px;
  cursor: pointer;
`;
const StyledDropdownMenu = styled(Dropdown)`
  width: 150px;
  cursor: pointer;
`;

function JobsList({
  data,
  loading,
  candidateList,
  setShowComponent,
  getData,
  setSelectedItem,
  MenuList
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredDataSource = data?.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const deleteData = async () => {
    setIsloading(true);
    await CALL_API(`recruitment/jobs/${deleteId}`, "patch", {
      deleteStatus: true,
    });
    getData();
    setIsloading(false);
    setDeleteOpen(false);
  };

  const columns = [
    {
      title: "Job Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "New Candidate",
      dataIndex: "newCandidate",
      key: "newCandidate",
      render: (_, record) => {
        const newUser = filter(candidateList, (candidate) => {
          const status = candidate.currentStatus;
          return (
            (isUndefined(status) || isEmpty(status) || status === "0") &&
            record?.jobId === candidate?.jobId
          );
        }).length;
        return newUser;
      },
    },
    {
      title: "Total Candidate",
      dataIndex: "totalCandidate",
      key: "totalCandidate",
      render: (_, record) => {
        const totalUser = filter(candidateList, (candidate) => {
          return candidate?.jobId.toString() === record?.jobId.toString();
        }).length;
        return totalUser;
      },
    },
    {
      title: "Status",
      dataIndex: "jobStatus",
      key: "jobStatus",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => {
        const date = moment(record?.createdAt).format("DD MMM YYYY");
        return date;
      },
    },
    {
      title: "",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (_, record) => (
        <Icon
          name="linkicon"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const url = `https://app.system.tymeplushr.com/form/${record?.clientId}/${record?.jobId}`;
            navigator.clipboard.writeText(url);
            message.success("Link Copied");
          }}
        />
      ),
    },
    {
      title: "",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (_, record) => (
        <StyledDropdownMenu
          overlay={
            <StyledViewMenu>
              <Menu>
                {MenuList?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "recruitment" && subItem?.permission.edit)) && (
                  <Menu.Item
                    key="2"
                    onClick={() => {
                      setSelectedItem(record);
                      setShowComponent("editJob");
                    }}
                  >
                    Edit the Job
                  </Menu.Item>
                )}
                {MenuList?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "recruitment" && subItem?.permission.delete)) && (
                  <Menu.Item
                    key="4"
                    onClick={() => {
                      setDeleteId(record?.jobId);
                      setDeleteOpen(true);
                    }}
                  >
                    Delete the Job
                  </Menu.Item>
                )}
              </Menu>
            </StyledViewMenu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <span
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            {MenuList?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "recruitment" && (subItem?.permission.edit || subItem?.permission.delete))) && (
              <MoreHorizIcon />
            )}
          </span>
        </StyledDropdownMenu>
      ),
    },
  ];

  const styledIcon = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "18px" };
  const styledInput = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "16px" };
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="mt-2">
          <Input
            style={styledInput}
            prefix={<SearchOutlined style={styledIcon} />}
            placeholder="Search "
            onChange={handleSearchChange}
          />
        </div>
        <div>
          {MenuList?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "recruitment" && subItem?.permission.create)) && (
            <ButtonFilled onClick={() => setShowComponent("addJob")}>
              + Add New Job
            </ButtonFilled>
          )}
        </div>
      </div>

      <Table
        dataSource={filteredDataSource}
        columns={columns}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              if (!e.target.closest(".ant-dropdown-menu")) {
                setSelectedItem(record);
                setShowComponent("candidateDetails");
              }
            },
          };
        }}
        loading={loading}
      />
      <AlertModal
        isModalOpen={deleteOpen}
        handleCancel={() => setDeleteOpen(false)}
        handleOk={deleteData}
        name={"Are you sure you want to delete this Job?"}
        loading={isLoading}
      />
    </div>
  );
}

export default JobsList;
