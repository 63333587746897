import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { CustomTextField } from "../common/common";
import {
  Typography,
  Paper,
  Box,
  Radio,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import styled from "styled-components";
import { Spin, Empty, Card, Button, message, Form } from "antd";
import { Input } from "antd";
import { CALL_API } from "common/API";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const { TextArea } = Input;

const StylesWrap = styled.div`
  .back-btn {
    cursor: pointer;
    color: #26a4ff;
  }
  .name_card {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    width: 100%;
    background: #fff;
  }
  .card-selected {
    border: 2px solid #26a4ff;
  }
`;
const AppraisalResponse = ({
  setPageView,
  displayLocationLoader,
  response,
  selectedItem,
  fetchResponse,
}) => {
  const [receivedResponse, setReceivedResponse] = useState([]);
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submittable, setSubmittable] = React.useState(false);
  const [value, setValue] = useState({
    comments: "",
    hikePercentage: "",
  });

  const [form] = Form.useForm();

  const questionStyle = {
    fontSize: "16px",
    fontWeight: "medium",
    display: "inline-block",
  };

  React.useEffect(() => {
    answeredForm();
  }, [response, selected]);

  const onChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values]);

  const answeredForm = () => {
    const emptyArray = [];
    const userResponse = response[selected]?.answer;
    userResponse?.forEach((element) => {
      emptyArray?.push({
        question: element.question,
        formType: element.formType,
        id: element.id,
        requiredSwitch: element.requiredSwitch,
        answer: element.answer,
        options:
          element.formType === "MULIPLE_CHOICE" ||
          element.formType === "CHECKBOX"
            ? element.options
            : "",
      });
    });
    setReceivedResponse(emptyArray);
  };

  const saveResponse = async (id, responseId) => {
    setLoading(true);
    const data = {
      comments: value.comments,
      hikePercentage: value.hikePercentage + "%",
    };
    const { code } = await CALL_API(
      `appraisalform/completedappraisal/${id}/${responseId}`,
      "patch",
      data
    );

    if (code === "SUCCESS") {
      setLoading(false);
      fetchResponse(selectedItem.appraisalFormId);
      message.success("Hike added successfully");
    }
  };

  return (
    <StylesWrap>
      <Spin spinning={displayLocationLoader}>
        <Row className="page-title">
          <Col sm={12} md={12}>
            <label className="bg-transparent border-0 mb-3 text-left styled-cursor">
              <span
                style={{color:"black"}}
                onClick={() => {
                  setPageView(false);
                }}
              >
                Back <ArrowForwardIosIcon style={{fontSize:"14px"}}/>
              </span>{" "}
              {selectedItem.title}
            </label>
          </Col>
        </Row>
        {response && response.length ? (
          <>
            <section className="form_builder main_container">
              <Row>
                <Col lg={3} md={4} sm={12}>
                  {response.map((data, index) => (
                    <div
                      onClick={() => {setSelected(index);form.resetFields()}}
                      className={`name_card m-3 ${
                        selected === index && "card-selected"
                      }`}
                    >
                      <div className="p-3 text-center">{data.userName}</div>
                    </div>
                  ))}
                </Col>
                <Col md={8} lg={9} sm={12}>
                  <Container>
                    <>
                      <div className="mt-4">
                        {receivedResponse.map((form, idx) => {
                          return (
                            <Paper elevation={0} className="mb-3">
                              <div className="p-3">
                                <Typography variant="h6" sx={questionStyle}>
                                  {form?.question}
                                </Typography>
                                {form?.requiredSwitch && (
                                  <span className="text-danger">*</span>
                                )}

                                {/* Selected Option */}
                                <Box
                                  key={`selectedOption-${form.id}`}
                                  sx={{ mt: 2 }}
                                >
                                  {form?.answer == "" || null || undefined ? (
                                    <Box sx={{ paddingX: 5 }}>Not answered</Box>
                                  ) : (
                                    <>
                                      {form?.formType === "PARAGRAPH" && (
                                        <p>{form?.answer}</p>
                                      )}

                                      {form?.formType === "SHORT_ANS" && (
                                        <p>{form?.answer}</p>
                                      )}

                                      {form?.formType === "TIME" && (
                                        <CustomTextField
                                          value={form?.answer}
                                          type="time"
                                          variant="standard"
                                          disabled
                                          sx={{
                                            "& .MuiInputBase-input.Mui-disabled":
                                              {
                                                WebkitTextFillColor: "black",
                                              },
                                          }}
                                        />
                                      )}

                                      {form?.formType === "DATE" && (
                                        <CustomTextField
                                          value={form?.answer}
                                          type="date"
                                          disabled
                                          variant="standard"
                                          sx={{
                                            "& .MuiInputBase-input.Mui-disabled":
                                              {
                                                WebkitTextFillColor: "black",
                                              },
                                          }}
                                        />
                                      )}

                                      {form?.formType === "DROPDOWN" && (
                                        <Box
                                          sx={{
                                            maxWidth: 280,
                                            minWidth: 250,
                                            mt: 2,
                                          }}
                                        >
                                          <FormControl fullWidth>
                                            <Select
                                              labelId="select"
                                              id="select-filled"
                                              value={form?.answer}
                                              disabled
                                              sx={{
                                                "& .MuiInputBase-input.Mui-disabled":
                                                  {
                                                    WebkitTextFillColor:
                                                      "black",
                                                  },
                                              }}
                                            >
                                              <MenuItem value={form?.answer}>
                                                {form?.answer}
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      )}

                                      {form?.formType === "MULIPLE_CHOICE" && (
                                        <>
                                          {form?.options === "" ||
                                          null ||
                                          undefined ? (
                                            <Box sx={{ paddingX: 5 }}>
                                              Not answered
                                            </Box>
                                          ) : (
                                            <>
                                              {form?.options.map((option) => {
                                                return (
                                                  <div>
                                                    {option?.optionText ===
                                                    form?.answer ? (
                                                      <FormControlLabel
                                                        value={
                                                          option?.optionText
                                                        }
                                                        control={
                                                          <Radio checked />
                                                        }
                                                        label={
                                                          option?.optionText
                                                        }
                                                      />
                                                    ) : (
                                                      <FormControlLabel
                                                        value={
                                                          option?.optionText
                                                        }
                                                        control={
                                                          <Radio disabled />
                                                        }
                                                        label={
                                                          option?.optionText
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                );
                                              })}
                                            </>
                                          )}
                                        </>
                                      )}

                                      {form?.formType === "CHECKBOX" && (
                                        <>
                                          {form?.options.map(
                                            (option, checkBoxindex) => {
                                              return (
                                                <div>
                                                  {form.answer.includes(
                                                    option?.optionText
                                                  ) ? (
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          value={
                                                            option?.optionText
                                                          }
                                                          inputProps={{
                                                            "aria-label":
                                                              "controlled",
                                                          }}
                                                          checked
                                                        />
                                                      }
                                                      label={option?.optionText}
                                                    />
                                                  ) : (
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          value={
                                                            option?.optionText
                                                          }
                                                          inputProps={{
                                                            "aria-label":
                                                              "controlled",
                                                          }}
                                                          disabled
                                                        />
                                                      }
                                                      label={option?.optionText}
                                                    />
                                                  )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </Box>
                              </div>
                            </Paper>
                          );
                        })}
                      </div>
                    </>
                    <Card className="p-3">
                      <Form
                        form={form}
                        name="validateOnly"
                        layout="vertical"
                        autoComplete="off"
                      >
                        <Form.Item
                          name="Hike Percentage"
                          label="Hike Percentage"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            name="hikePercentage"
                            value={value.hikePercentage}
                            placeholder="Hike percentage "
                            onChange={(e) => onChange(e)}
                          />
                        </Form.Item>
                        <Form.Item
                          name="Comments"
                          label="Comments"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <TextArea
                            value={value.comments}
                            name="comments"
                            onChange={(e) => onChange(e)}
                            placeholder="Comment"
                            className="mt-3"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                        </Form.Item>
                      </Form>
                    </Card>
                    <div className="text-end mt-3">
                      <Button
                        className="btn-style"
                        type="primary"
                        htmlType="submit"
                        disabled={!submittable}
                        onClick={() =>
                          saveResponse(
                            response[selected].appraisalFormId,
                            response[selected].responseId
                          )
                        }
                      >
                        <Spin spinning={loading}>Submit</Spin>
                      </Button>
                    </div>
                  </Container>
                </Col>
              </Row>
            </section>
          </>
        ) : (
          <Empty description={<span>Currently no response found!</span>} />
        )}
      </Spin>
    </StylesWrap>
  );
};

export default AppraisalResponse;
