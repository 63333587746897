import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Button from '../../atoms/Button';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Details from './Details';
import AddEditPolicy from './AddEditPolicy';
import Table from 'atoms/Table';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RoundIcon from 'atoms/RoundIcon';
import { connect } from 'react-redux';
import { getClientid, getUserInfo, getMenuList, getUserRoleId } from 'redux/selectors';
import { CALL_API } from 'common/API';
import COLORS from 'common/Colors';
import moment from 'moment';


function HRPolicy({ ClientId, userInfo, menuList, userRoleId }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [showComponent, setShowComponent] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];


  const goBack = () => {
    setShowComponent(1);
  };

  const columns = [
    {
      title: "Policy Name",
      dataIndex: "policyTitle",
      key: "policyName",
    },
    {
      title: "Last Updated",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
  ];

  const getPolicyData = async () => {
    setLoading(true);
    const response = await CALL_API(`hrpolicy/${ClientId}`, "get", {});
    setLoading(false);
    setDataSource(response);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredDataSource = dataSource?.filter((item) =>
    item.policyTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    getPolicyData();
  }, []);
  const titleStyle = { color: `${COLORS.PRIMARY}`, cursor: "pointer" };

  const deletePolicy = (policyId) => {
    const updatedDataSource = dataSource?.filter(
      (policy) => policy.policyId !== policyId
    );
    setDataSource(updatedDataSource);
  };

  return (
    <div>
      {showComponent === 1 && <h6 className="mb-2">HR Policies</h6>}
      {showComponent === 1 && (
        <>
          <Row gutter={16}>
            <Col md={6}>
              <Input
                className="mb-2"
                placeholder="Search"
                onChange={handleSearchChange}
                suffix={<SearchOutlined />}
              />
            </Col>
            {
              Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "Policies" && subItem?.permission.create)) && (
                <Col className='text-end' md={18}>
                  <Button type="primary" onClick={() => setShowComponent(3)}>Add New Policy</Button>
                </Col>
              )}
          </Row>
          <br />
          <Table
            dataSource={filteredDataSource}
            columns={columns}
            pagination={false}
            loading={loading}
            // Note: Second params needed
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                    setSelectedRow(record);
                    setShowComponent(2)
                },
              };
            }}
          />
        </>
      )}
      {showComponent === 2 && (
        <Details
          selectedRow={selectedRow}
          ClientId={ClientId}
          userInfo={userInfo}
          goBack={goBack}
          getPolicyData={getPolicyData}
          deletePolicy={deletePolicy}
          Menulist={Menulist}
        />
      )}
      {showComponent === 3 && (
        <>
          <h6>
            <strong style={titleStyle} onClick={() => goBack()}>
              HR Policies
            </strong>{" "}
            <span> {">"} Add New Policy</span>
          </h6>
          <AddEditPolicy
            ClientId={ClientId}
            userInfo={userInfo}
            goBack={goBack}
            getPolicyData={getPolicyData} 
          />
        </>
      )}
    </div>
  );
}

export const BackButton = ({ goBack }) => {
  const iconStyle = { fontSize: "16px", color: "white" };
  return (
    <RoundIcon>
      <ArrowBackIcon style={iconStyle} onClick={goBack} />
    </RoundIcon>
  );
};

const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  userInfo: getUserInfo(state),
  menuList: getMenuList(state),
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps, null)(HRPolicy);
