import React from 'react';
import Dashboard from 'pages/Dashboard';
import Employee from 'pages/Employee';
import Task from 'pages/Task';
import Notification from 'pages/Notification';
import Calendar from 'pages/Calendar';
import Approval from 'pages/Approval';
import AttendanceReport from 'pages/AttendanceReport';
import LeaveReport from 'pages/LeaveReport';
import BreakReport from 'pages/BreakReport';
import OvertimeReport from 'pages/OvertimeReport';
import MyProfile from 'pages/my-profile';
import Team from 'pages/Team';
import Chat from 'pages/Chat';
import Documents from 'pages/Documents';
import MyDocuments from 'pages/MyDocuments';
import Location from 'pages/Location';
import Department from 'pages/Department';
import Trends from 'pages/Trends';
import Productivity from 'pages/Productivity';
import Demographics from 'pages/Demographics';
import LateCheckIn from 'pages/LateCheckIn';
import EarlyCheckOut from 'pages/EarlyCheckOut';
import AbsendReport from 'pages/AbsendReport';
import Myleavereport from 'pages/Myleavereport';
import ReleasedNote from 'pages/ReleasedNote';
import MonthlyAttendanceReport from 'pages/MonthlyAttendanceReport';
import Roles from 'pages/Roles';
import Shift from 'pages/Shift';
import RolesAndPermissions from 'pages/RolesAndPermissions';
import Break from 'pages/Break';
import LeaveCategories from 'pages/LeaveCategories';
import Holiday from 'pages/holiday';
import CloseAccount from 'pages/CloseAccount';
import EmployeeDetails from 'organisms/EmployeeDetails';
import ManagerDashboard from 'pages/ManagerDashboard';
import HrDashboard from 'pages/HrDashboard';
import Permission from 'pages/Permission';
import ProfileDetails from 'pages/ProfileDetails';
import HRPolicy from 'pages/HRPolicy';
import Band from 'pages/Band/index';
import AITymeplus from 'pages/AITymeplus/index';
import Ownerdashboard from 'pages/OwnerDashboard';
import Appraisal from 'pages/Appraisal';
import AddAppraisal from 'pages/AddAppraisal';
import AppraisalResponse from 'pages/AppraisalResponse';
import MyAppraisal from 'pages/MyAppraisal';
import FormBuilder from 'pages/FormBuilder';
import Recruitment from 'pages/Recruitment';
import RecruitmentTab from 'pages/RecruitmentTab';
import Onboarding from 'pages/Onboarding/Onboarding';
import Contract from 'pages/Contract';
import Addjob from 'pages/AddJob';
import TemplateContract from 'pages/Contract/TemplateContract';
import IssueContract from 'pages/Contract/IssueContract';
import HRDocuments from 'pages/HRDocuments';
import FormResponse from 'pages/FormResponse';
import MyTravelExpense from 'pages/TravelAndExpense/MyTravelExpense';
import HrTravelExpense from 'pages/TravelAndExpense/HrTravelExpense';
import ActivityLog from 'pages/ActivityLog';
// import ActivityLog from 'pages/ActivityLog';

const Routes = [
  {
    path: 'dashboard',
    title: 'Dashboard',
    content: () => <Dashboard />,
    permission: ['dashboard', 'managerdashboard', 'hrdashboard']
  },
  {
    path: 'employee',
    title: 'People',
    content: () => <Employee type="manage"/>,
    permission: ['employee']
  },
  {
    path: 'task',
    title: 'Task',
    content: () => <Task />,
    permission: ['task']
  },
  {
    path: 'mytask',
    title: 'My Task',
    content: () => <Task />,
    permission: ['mytask']
  },
  {
    path: 'notification',
    title: 'Notification',
    content: () => <Notification />,
    permission: ['notification']
  },
  {
    path: 'calendar',
    title: 'Calendar',
    content: () => <Calendar />,
    permission: ['calendar']
  },
  {
    path: 'location',
    title: 'Location',
    content: () => <Location />,
    permission: ['location']
  },
  {
    path: 'approvals',
    title: 'Approval',
    content: () => <Approval />,
    permission: ['approvals']
  },
  {
    path: 'attendance-report',
    title: 'Attendance Report',
    content: () => <AttendanceReport />,
    permission: ['attendance-report']
  },
  {
    path: 'leave-report',
    title: 'Leave Report',
    content: () => <LeaveReport />,
    permission: ['leave-report']
  },
  {
    path: 'break-report',
    title: 'Break Report',
    content: () => <BreakReport />,
    permission: ['break-report']
  },
  {
    path: 'overtime-report',
    title: 'Overtime Report',
    content: () => <OvertimeReport />,
    permission: ['overtime-report']
  },
  {
    path: 'my-profile',
    title: 'My Profile',
    content: () => <MyProfile />,
    permission: []
  },
  {
    path: 'team',
    title: 'Team',
    content: () => <Employee type="hr"/>,
    permission: ['team']
  },
  {
    path: 'chat/:id',
    title: 'Chat',
    content: () => <Chat />,
    permission: ['chat/:id']
  },
  {
    path: 'documents/:id',
    title: 'Documents',
    content: () => <Documents />,
    permission: ['documents/:id']
  },
  {
    path: 'mydocuments',
    title: 'My Documents',
    content: () => <MyDocuments />,
    permission: ['mydocuments']
  },
  {
    path: 'department',
    title: 'Departments',
    content: () => <Department />,
    permission: ['department']
  },
  {
    path: 'trends',
    title: 'Trends',
    content: () => <Trends />,
    permission: ['trends']
  },
  {
    path: 'productivity',
    title: 'Productivity',
    content: () => <Productivity />,
    permission: ['productivity']
  },
  {
    path: 'demographics',
    title: 'Demographics',
    content: () => <Demographics />,
    permission: ['demographics']
  },
  {
    path: 'Late-check-in',
    title: 'Late Check-in',
    content: () => <LateCheckIn />,
    permission: ['Late-check-in']
  },
  {
    path: 'Early-check-out',
    title: 'Early Check-out',
    content: () => <EarlyCheckOut />,
    permission: ['Early-check-out']
  },
  {
    path: 'Absent-report',
    title: 'Absent Report',
    content: () => <AbsendReport />,
    permission: ['Absent-report']
  },
  {
    path: 'myleavereport',
    title: 'Leave History',
    content: () => <Myleavereport />,
    permission: ['myleavereport']
  },
  {
    path: 'released-notes',
    title: 'Released Notes',
    content: () => <ReleasedNote />,
    permission: ['released-notes']
  },
  {
    path: 'attendance-report-monthly',
    title: 'Monthly Attendance Report',
    content: () => <MonthlyAttendanceReport />,
    permission: ['attendance-report-monthly']
  },
  {
    path: 'roles',
    title: 'Role',
    content: () => <Roles />,
    permission: ['roles']
  },
  {
    path: 'shift',
    title: 'Shift',
    content: () => <Shift />,
    permission: ['shift']
  },
  {
    path: 'rolesandpermissions',
    title: 'Roles and Permissions',
    content: () => <RolesAndPermissions />,
    permission: ['rolesandpermissions']
  },
  {
    path: 'break',
    title: 'Break',
    content: () => <Break />,
    permission: ['break']
  },
  {
    path: 'leave-categories',
    title: 'Leave Categories',
    content: () => <LeaveCategories />,
    permission: ['leave-categories']
  },
  {
    path: 'holiday',
    title: 'Holiday',
    content: () => <Holiday />,
    permission: ['holiday']
  },
  {
    path: 'close-account',
    title: 'Close Account',
    content: () => <CloseAccount />,
    permission: []
  },
  {
    path: 'employeedetails/:id',
    title: 'Employee Details',
    content: () => <EmployeeDetails />,
    permission: []
  },
  {
    path: 'permission/:roleId/:roleName',
    title: 'Permission',
    content: () => <Permission />,
    permission: []
  },
  {
    path: 'managerdashboard',
    title: 'Manager Dashboard',
    content: () => <ManagerDashboard />,
    permission: ['managerdashboard']
  },
  {
    path: 'hrdashboard',
    title: 'HR Dashboard',
    content: () => <HrDashboard />,
    permission: ['hrdashboard']
  },
  {
    path: 'band',
    title: 'Band',
    content: () => <Band />,
    permission: ['band']
  },
  {
    path: 'profile-details/:id',
    title: 'Profile Details',
    content: () => <ProfileDetails />,
    permission: ['employee', 'team']
  },
  {
    path: 'policies',
    title: 'HR Policy',
    content: () => <HRPolicy />,
    permission: ['policies']
  },
  {
    path: 'tymeplusai',
    title: 'Tymeplus AI',
    content: () => <AITymeplus />,
    permission: ['tymeplusai']
  },
  {
    path: 'ownerdashboard',
    title: 'Dashboard',
    content: () => <Ownerdashboard />,
    permission: ['ownerdashboard']
  },
  {
    path: 'appraisal',
    title: 'Appraisal',
    content: () => <Appraisal />,
    permission: ['appraisal']
  },
  {
    path: 'myappraisal',
    title: 'My Appraisal',
    content: () => <MyAppraisal />,
    permission: ['myappraisal']
  },
  {
    path: 'form-response',
    title: 'Appraisal',
    content: () => <AppraisalResponse />,
    permission: ['form-response']
  },
  {
    path: 'add-appraisal',
    title: 'Appraisal',
    content: () => <AddAppraisal />,
    permission: ['add-appraisal', 'appraisal']
  },
  {
    path: 'formbuilder',
    title: 'Form Builder',
    content: () => <FormBuilder />,
    permission: ['formbuilder']
  },
  {
    path: 'recruitment',
    title: 'Recruitment',
    content: () => <Recruitment />,
    permission: ['recruitment']
  },
  {
    path: 'onboard',
    title: 'OnBoard',
    content: () => <Onboarding />,
    permission: ['recruitment']
  },
  {
    path: 'recruitment-tab',
    title: 'Recruitment Tab',
    content: () => <RecruitmentTab />,
    permission: ['recruitment']
  },
  {
    path: 'contract',
    title: 'Contract',
    content: () => <Contract />,
    permission: ['recruitment']
  },
  {
    path: 'addjob',
    title: 'Add Job',
    content: () => <Addjob />,
    permission: ['recruitment']
  },
  {
    path: 'myteam',
    title: 'My Team',
    content: () => <Team />,
    permission: ['myteam']
  },
  {
    path: 'contract-template',
    title: 'Contract Template',
    content: () => <TemplateContract />,
    permission: ['recruitment']
  },
  {
    path: 'issue-contract',
    title: 'Issue Contract',
    content: () => <IssueContract />,
    permission: ['recruitment']
  },
  {
    path: 'hr-documents',
    title: 'Documents',
    content: () => <HRDocuments />,
    permission: ['hr-documents']
  },
  {
    path: '/form/:clientId/:id',
    title: 'Form',
    content: () => <FormResponse />,
    permission: []
  },
  {
    path: 'mytravelexpense',
    title: 'My Travel & Expense',
    content: () => <MyTravelExpense />,
    permission: ['mytravelexpense']
  },
  {
    path: 'travelexpense',
    title: 'Travel & Expense',
    content: () => <HrTravelExpense />,
    permission: ['travelexpense']
  },
  {
    path: "activitylog",
    title: "Activity Log",
    content: () => <ActivityLog />,
    permission: ['activitylog']
  },
];



export default Routes;
