import axios from "axios";

import { SERVICE_URL, STATUS_CODE, AI_BASE_URL, STAGE_URL  } from "common/Constants";
import { show401Modal } from "./common";

export const CALL_API = async (action, method, payload) => {
  const isMasterData = action.includes("master-data");
  try {
    const headers = {
      Tymeplusjwt: window.localStorage.getItem("token") || "",
    };

    if (!isMasterData) {
      headers["roleId"] = window.localStorage.getItem("selectedRoleId") || "";
    }
    const resp = await axios({
      method,
      url: `${SERVICE_URL}${action}`,
      data: payload,
      headers,
    });
    const { status, response } = resp.data;
    if (status === STATUS_CODE.SUCCESS || status === true) return  response;
    else if (status === 401) {
      show401Modal()
      throw new Error('Unauthorized');
    }
    else throw {};
  } catch (err) {
    console.log(err);
    console.log(action);
    return err;
  }
};

export const CALL_AI_API = async (action, method, payload) => {
  try {
    const resp = await axios({
      method,
      url: `${AI_BASE_URL}${action}`,
      data: payload,
      headers: {
        Tymeplusjwt: window.localStorage.getItem("token") || "",
        roleId: window.localStorage.getItem("selectedRoleId") || ""
      },
    });
    const { status, response } = resp.data;
    if (status === STATUS_CODE.SUCCESS || status === true) return response;
    else if (status === 401) {
      show401Modal()
      throw new Error('Unauthorized');
    }
    else throw {};
  } catch (err) {
    console.log(err);
    console.log(action);
    return err;
  }
};

export const CALL_API_WITH_HEADER = async (
  action,
  method,
  payload,
  additionalHeaders
) => {
  try {
    const resp = await axios({
      method,
      url: `${SERVICE_URL}${action}`,
      data: payload,
      headers: {
        Tymeplusjwt: window.localStorage.getItem("token") || "",
        roleId: window.localStorage.getItem("selectedRoleId") || "",
        ...additionalHeaders,
      },
    });
    const { status, response } = resp.data;
    if (status === STATUS_CODE.SUCCESS) return response;
    else if (status === 401) {
      show401Modal()
      throw new Error('Unauthorized');
    }
    else throw {};
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const UPLOAD_DOCUMENT = async (action, file) => {
  try {
    let data = new FormData();
    data.append("file", file);

    const resp = await axios.post(`${SERVICE_URL}${action}`, data, {
      Tymeplusjwt: window.localStorage.getItem("token") || "",
      "Content-Type": "multipart/form-data",
      roleId: window.localStorage.getItem("selectedRoleId") || ""
    });
    const { status, response } = resp.data;
    if (status === STATUS_CODE.SUCCESS) return response;
    else if (status === 401) {
      show401Modal()
      throw new Error('Unauthorized');
    }
    else throw {};
  } catch (err) {
    return err;
  }
};
