import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Form, Input, Radio, message } from 'antd';
import { ButtonFilled, ButtonOutlined } from 'reusableComponent/UIButtons';
import EditorConvertToHTML from 'atoms/EditorConvertToHTML';
import { CALL_API } from 'common/API';
import html2pdf from 'html2pdf.js';



const StyledDropzone = styled.div`
  font-size: 16px;
  .box {
    width: max-content;
    border-radius: 10px;
    padding: 15px;
    background-color: #f4f4f4;
  }
`;

function IssueContract({ contractDetails, setShowComponent, formdata, selectedCandidate, getData, goBack, goTab }) {
  const [details, setDetails] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [updatedDetails, setUpdatedDetails] = useState(contractDetails);
  const [loading, setLoading] = useState(false)
  const reportElementRef = useRef(null);


  const handleGeneratePdf = () => {
    const content = reportElementRef.current;

    if (content) {
      const pdfOptions = {
        margin: 10,
        filename: 'output.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf().from(content).set(pdfOptions).save();
    }
  };

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1]);
      };
      reader.readAsDataURL(blob);
    });
  };

  const handlePdfCreate = async () => {
    const content = reportElementRef.current;
    const pdfOptions = {
      margin: 10,
      filename: 'output.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    // Generate PDF asynchronously
    const pdfBlob = await html2pdf().from(content).set(pdfOptions).outputPdf('blob'); // Specify 'blob' here
    const pdfBase64 = await convertBlobToBase64(pdfBlob);
    return pdfBase64
  }

 
  const onFinish = async (values) => {
    setLoading(true)
    const { priority, content } = values;
    const emailContent = content;
    if (deliveryMethod === 'hardCopy') { 
       handleGeneratePdf()
    }
    const attachment = await handlePdfCreate();
   
    const updatedFormData = {
      ...formdata,
      emailContent: emailContent,
      contractDetails: deliveryMethod === 'hardCopy' ? "" : attachment
    };


    await CALL_API(`recruitment/onboard`, 'post', updatedFormData);
    await CALL_API(`recruitment/candidate/${selectedCandidate?.candidateId}`, "patch", {
      deleteStatus: true
    });
    message.success('Onboarded successfully');
    setLoading(false)
    getData()
    goTab()

  };

  return (
    <>
      {!details && (
        <>
          <div className="text-left">
            <h5>{contractDetails?.title}</h5>
          </div>
          <br />
          <EditorConvertToHTML
            onChange={(updatedContractDetails) => {
              setUpdatedDetails({ contractDetails: updatedContractDetails });
            }}
            content={contractDetails?.contractDetails}
          />
          <br />

          <div className="w-100 text-end">
            <ButtonOutlined onClick={() => setShowComponent('table')}>
              Cancel
            </ButtonOutlined>
            <ButtonFilled onClick={() => setDetails(true)}>Next</ButtonFilled>
          </div>
        </>
      )}
      {details && (
        <StyledDropzone>
          <div className="text-left">
            <h5>{contractDetails?.title}</h5>
          </div>
          <div ref={reportElementRef}>
            <div dangerouslySetInnerHTML={{ __html: updatedDetails?.contractDetails }} />
          </div>


          <div className="w-100 mt-2">
            <Form
              name="myForm"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <h6>How do you want to deliver this document? </h6>
              <br />
              <Form.Item
                name="priority"
                rules={[
                  { required: true, message: 'Please select priority!' },
                ]}
              >
                <Radio.Group
                  onChange={(e) => setDeliveryMethod(e.target.value)}
                >
                  <Radio value="email">Email</Radio>
                  <Radio value="hardCopy">Hard Copy</Radio>
                </Radio.Group>
              </Form.Item>

              {deliveryMethod === 'email' && (
                <Form.Item name="content">
                  <Input.TextArea rows={4} placeholder="Type..." />
                </Form.Item>
              )}

              <Form.Item>
                <div className="text-end">
                  <ButtonOutlined onClick={() => setDetails(false)}>
                    Cancel
                  </ButtonOutlined>
                  <ButtonFilled htmlType="submit" loading={loading}>Done</ButtonFilled>
                </div>
              </Form.Item>
            </Form>
          </div>
        </StyledDropzone>
      )}
    </>
  );
}

export default IssueContract;
