import React, { useEffect, useState } from 'react'
import { Modal } from "antd";
import styled from "@emotion/styled";
import COLORS from 'common/Colors';
import GridBuilder from 'UIBuilderEngine/GridBuilder';
import { InitalSteps } from 'common/Constants';

const StyledModal = styled(Modal)`
  border-radius: 10px;
  padding-top: 5%;
  p{
    font-size: 18px;
    color: ${COLORS.TEXT.PRIMARY};
    font-weight: 600;
  }

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-footer {
    display: none;
  }
  .stylebtn{
    min-width: 150px;
  }
`;

function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


function AddHiringProcess({ isModalOpen, handleCancel, handleOk, loading, name, handleSubmit, initialValue, isEdit = false }) {
    const [newStep, setNewStep] = useState(InitalSteps)
    const [reset, setReset] = useState()
    const Form = {
        totalpages: 1,
        pages: [
            {
                modalView: false, 
                colSpan: [12, 12, 12, 12], 
                fields: [
                    // Row 1
                    [
                        {
                            id: 1,
                            label: "Name of the Hiring Process",
                            type: "text",
                            name: "title",
                            Placeholder: "Process Name",
                            value: '',
                            validationRules: [
                                {
                                    required: true,
                                    message: `Please Enter Process Name`,
                                },

                            ],
                            maxLength: 50
                        },
                    ],
                    [
                        {
                            id: 2,
                            label: "Steps Involved in Hiring",
                            type: "addlist",
                            name: "steps",
                            Placeholder: "Add Step Name",
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },

                            ],
                            onKeyDown: /^[a-zA-Z\s]+$/,
                            maxLength: 30
                        },
                    ],

                    [
                        {
                            id: 7,
                            label: "Cancel",
                            type: "button",
                            position: "center", // position options (end, start, center) 
                            event: "click",
                        },
                        {
                            id: 8,
                            label: isEdit ? "Update" : "Add",
                            type: "button",
                            position: "center", // position options (end, start, center)
                            event: "submit",
                        },
                    ]
                ],
            },
        ],
    };

    const [initialState, setInitialState] = useState({})

    useEffect(() => {
        setInitialState(initialValue)
    }, [initialValue, isModalOpen])

    useEffect(() => {
        setNewStep(InitalSteps)
        setReset(getRandomNumber(1,100))
    }, [isModalOpen])

    return (
        <div>
            <StyledModal
                title=""
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                closeIcon={false}

            >
                <div className='mt-3'>
                    <h5>Adding Hiring process</h5>
                    {isEdit && (
                        <GridBuilder
                            formStructureJson={Form}
                            formSubmit={handleSubmit}
                            loading={loading}
                            handleBack={handleCancel}
                            initialValues={initialState}
                        />
                    )}
                    {!isEdit && (
                        <GridBuilder
                            formStructureJson={Form}  
                            formSubmit={handleSubmit}
                            loading={loading}
                            handleBack={handleCancel}
                            initialValues={newStep}
                            isModalOpen={reset}
                        />
                    )}


                </div>

            </StyledModal>
        </div>
    )
}

export default AddHiringProcess