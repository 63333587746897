import Table from 'atoms/Table';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import queryKeys from "common/queryKey";
import moment from 'moment';
import { useScrollFetch2 } from 'hooks/useScrollFetch2';
import { DatePicker, Input, Select, message } from 'antd';
import { dateInDetail, getMySqlDate } from 'utils/Date';
import { connect } from 'react-redux';
import { getUserList } from 'redux/selectors';
import { concat, find, reduce } from 'lodash';
import { ButtonOutlined } from 'reusableComponent/UIButtons';
import { CSVLink } from 'react-csv';



const CSVHeaders = [
  { label: "Action Type" },
  { label: "Date" },
  { label: "Name" },
  { label: "Action Log" },
];

const { Option } = Select;

const { RangePicker } = DatePicker;
function ActivityLog({ userList }) {

  
const resourceColumns = [
  {
    title: " Action Type ",
    dataIndex: "actionType",
    key: "actionType",
  },
  {
    title: "Action Date",
    dataIndex: "actionDate",
    key: "actionDate",
    render: (date) => {
      const dateFormat = moment.utc(date).format('DD-MM-YYYY / HH:mm:ss');
      return `${dateFormat}`;
    },
  },
  {
    title: "Action By",
    dataIndex: "userName",
    key: "userName",
    render: (_, record) => {
      const user = find(userList, { userid: record?.actionBy });
      let name = record?.actionBy && record?.userType === "Owner" ? record?.userName : (user ? user?.fullname : "-");
      return name;
    },
  },
  {
    title: "Logs",
    dataIndex: "actionLog",
    key: "actionLog",
  },


];
  const csvLinkEl = useRef();

  const [selectedDateRange, setSelectedDateRange] = useState([
  ]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedUser, setSelectedUser] = useState("")
  const [queryParams, setQueryParams] = useState("")
  const [CSVreportlist, setCSVreportlist] = useState([]);


  const handleDateChange = (date, dateString) => {
    setSelectedDateRange(date);
  };

  const handleFilterChange = value => {
    setSelectedFilter(value);
  };

  const { getActivityLog } = queryKeys;

  const { key, url } = getActivityLog(queryParams);

  const { data, lastElementRef, isLoading } = useScrollFetch2(
    key,
    {
      endPoint: url,
      queryParam: "lastEvaluatedKey",
    }
  );



  const activitylog = useMemo(() => {

    return data?.pages?.reduce((acc, page) => {

      if (page?.response?.data === undefined) return acc;

      return [...acc, ...page?.response?.data];
    }, []);
  }, [data]);

  const getQueryParams = () => {
    let startDate, endDate;
    if (selectedDateRange) {
        [startDate, endDate] = selectedDateRange;
    }

    const queryParams = new URLSearchParams();
    queryParams.append('actionType', selectedFilter ? selectedFilter : 0);

    if (startDate && endDate) {
        let rangeEnd = getMySqlDate(endDate);
        let rangeStart = getMySqlDate(startDate);
        queryParams.append('startDate', rangeStart);
        queryParams.append('endDate', rangeEnd);
    } else {
        queryParams.append('startDate', 0);
        queryParams.append('endDate', 0);
    }

    queryParams.append('actionBy', selectedUser ? selectedUser : 0);

    const queryString = queryParams.toString();
    setQueryParams(`?${queryString}`);

};

  useEffect(() => {
    getQueryParams();
  }, [selectedFilter, selectedDateRange, selectedUser])



  const prepareCSV = async () => {
    if (activitylog.length > 0) {
      setCSVreportlist(
        reduce(
          activitylog,
          function (result, record) {
            const user = find(userList, { userid: record?.actionBy});
            result.push({
              "Action Type": record?.actionType,
              "Date":  moment.utc(record.actionDate).format('DD-MM-YYYY / HH:mm:ss'),
              "Name":  user ? user?.fullname : "-",
              "Action Log": record.actionLog,
              // "Date": `${startDate_th} ${startMonthName} ${end_year}`,
              // "Day": `${moment(record?.date).format("dddd")}`,
            });
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  };


  return (
    <div>
      <div className='w-100 d-flex justify-content-between'>
        <h5>Activity Log</h5>
        <div className='text-end'>
          <ButtonOutlined
            onClick={prepareCSV}
            disabled={activitylog?.length === 0 || null || undefined}
          >
            Export CSV file
          </ButtonOutlined>
          <CSVLink
            header={CSVHeaders}
            data={CSVreportlist}
            filename={"activityLog.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </div>
      </div>

      <div className="w-100 d-flex gap-2 align-itmes-center flex-wrap mb-2">
        <Select
          placeholder="Select Action Type"
          style={{ height: '100%', width: '200px', marginLeft: '10px' }}
          onChange={handleFilterChange}
        >
          <Option value="">All</Option>
          <Option value="Edited">Edited</Option>
          <Option value="Created">Created</Option>
          <Option value="Deleted">Deleted</Option>
        </Select>
        <Select
          placeholder="Filter by Employee"
          style={{
            minWidth: "200px",
          }}
          filterOption={(input, option) =>
            (option?.fullname ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.fullname ?? "")
              .toLowerCase()
              .localeCompare((optionB?.fullname ?? "").toLowerCase())
          }
          options={concat(
            {
              userid: "",
              fullname: "All Employee",
            },
            userList
          )}
          fieldNames={{
            label: "fullname",
            value: "userid",
          }}
          onChange={(val) => setSelectedUser(val)}
        />

        <RangePicker
          value={selectedDateRange}
          onChange={handleDateChange}
          style={{
            width: "300px",
          }}
          allowClear={true}
        />
      </div>


      <Table
        bordered={false}
        className="mb-5"
        dataSource={activitylog}
        columns={resourceColumns}
        rowKey=""
        pagination={false}
        loading={isLoading}
        onRow={(record, index) => ({
          ref: index === activitylog.length - 30 ? lastElementRef : null,
        })}
      />

    </div>
  )
}

const mapStateToProps = (state) => ({
  userList: getUserList(state),
});

export default connect(mapStateToProps, null)(ActivityLog);


