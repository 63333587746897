import { Row, Col } from "react-bootstrap";
import { Button, Spin, Input } from "antd";
import { useEffect, useState } from "react";
import { CALL_API } from "common/API";
import { SearchOutlined } from '@ant-design/icons';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Table from 'atoms/Table'
import { ButtonOutlined } from "reusableComponent/UIButtons";

const AppraisalHistory = ({
  displayLocationLoader,
  setPageView,
  history,
  AllDepartmentList,
  appraisal,
  fetchDataBYId,
  myappraisal,
  myAppraisal,
  setViewResponseData,
}) => {


  const textcolor = {color:"#2d53da"}

  const [searchTerm, setSearchTerm] = useState('');

  const [viewResponse, setViewResponse] = useState(false);
  const [responseById, setResponseById] = useState(false);
  const [responseLoader, setResponseloader] = useState(false);
  const [appraisalName, setAppraisalName] = useState([]);

  const fetchData = async (id) => {
    setResponseloader(true);
    try {
      const res = await CALL_API(`appraisalform/completedappraisal/${id}`, "get");
      setResponseloader(false);
      setResponseById(res?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const fetchData = async () => {
  //   setResponseloader(true);
  //   try {
  //     const res = await CALL_API(`appraisalform/completedappraisal`, "get");
  //     setResponseloader(false);
  //     setResponseById(res?.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  const handleFilterAppraisals = (id, assignId) => {
    fetchData(id);
  };

  const findDepartmentName = (departmentId) => {
    const department = AllDepartmentList.find(
      (dep) => dep.department_id === departmentId
    );
    return department ? department.department_name : "N/A";
  };

  const findAppraisalName = (appraisalFormId) => {
    const department = appraisal.find(
      (dep) => dep.appraisalFormId === appraisalFormId
    );
    return department ? department.title : "N/A";
  };

  const findAppraisalTime = (appraisalFormId) => {
    const department = myAppraisal.find(
      (dep) => dep.appraisalFormId === appraisalFormId
    );
    return department
      ? department.appraisalForm + " - " + department.appraisalTo
      : "N/A";
  };

  const appraisalHistorycolumns = [
    {
      title: "Form Title",
      dataIndex: "appraisalFormId",
      key: "appraisalFormId",
      width: "10%",
      render: (appraisalFormId) => findAppraisalName(appraisalFormId),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
    },
    {
      title: "Department",
      dataIndex: "departmentId",
      key: "departmentId",
      width: "10%",
      render: (departmentId) => findDepartmentName(departmentId),
    },
    {
      title: "Action",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id, record) => {
        return (
          <>
            <ButtonOutlined
              onClick={() => {
                setViewResponse(true);
                
                handleFilterAppraisals(record.appraisalFormId, record.assignId);
                setAppraisalName(record.appraisalFormId);
              }}
              type="link"
              className="fw-medium"
              style={textcolor}
            >
              View form
            </ButtonOutlined>
          </>
        );
      },
    },
  ];

  const responseColumn = [
    {
      title: "Employee name",
      dataIndex: "userName",
      key: "userName",
      width: "10%",
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: "10%",
      render: (updatedAt) => <p>{updatedAt.slice(0, 10)}</p>,
    },
    {
      title: "Department",
      dataIndex: "departmentId",
      key: "departmentId",
      width: "10%",
      render: (departmentId) => findDepartmentName(departmentId),
    },
    {
      title: "Hike percentage",
      dataIndex: "hikePercentage",
      key: "hikePercentage",
      width: "10%",
    },
    {
      title: "Action",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id, record) => {
        return (
          <>
            <ButtonOutlined
              onClick={() => {
                setPageView("historyResponse");
                setViewResponseData(record);
              }}
              type="link"
              className="fw-medium"
            >
              View form response
            </ButtonOutlined>
          </>
        );
      },
    },
  ];

  const myappraisalHistorycolumns = [
    {
      title: "Title",
      dataIndex: "appraisalFormId",
      key: "appraisalFormId",
      width: "10%",
      render: (appraisalFormId) => findAppraisalName(appraisalFormId),
    },
    {
      title: "Time Period",
      dataIndex: "appraisalFormId",
      key: "appraisalFormId",
      width: "10%",
      render: (appraisalFormId) => findAppraisalTime(appraisalFormId),
    },
    {
      title: "Hike percentage",
      dataIndex: "hikePercentage",
      key: "hikePercentage",
      width: "10%",
    },
    {
      title: "Action",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id, record) => {
        return (
          <>
            <ButtonOutlined
              onClick={() => {
                setPageView("historyResponse");
                setViewResponseData(record);
              }}
              type="link"
              className="fw-medium"
              style={textcolor}
            >
              View form
            </ButtonOutlined>
          </>
        );
      },
    },
  ];

  const filteredDataSource =(myappraisal ? history : history)?.filter((item) =>
  findAppraisalName(item?.appraisalFormId)?.toLowerCase().includes(searchTerm?.toLowerCase()) && findAppraisalName(item?.appraisalFormId) !== "N/A"
);





const handleSearchChange = (e) => {
  setSearchTerm(e.target.value);
};


  return (
    <div>
      <Row>
        <Col sm={12} md={4} lg={4}>
          <Input
            placeholder="Search"
            onChange={handleSearchChange}
            suffix={<SearchOutlined />}
          />
        </Col>
        <Col sm={12} md={8} lg={8}></Col>
      </Row>
      {myappraisal ? (
        <Table
          className="mb-5"
          dataSource={filteredDataSource}
          columns={myappraisalHistorycolumns}
          rowKey="department_id"
          pagination={false}
        />
      ) : (
        <>
          {viewResponse ? (
            <>
              <label className="bg-transparent border-0 mt-2 text-left styled-cursor">
                <span  onClick={() => setViewResponse(false)}>
                  Back
                </span>
                <span className="px-2"><ArrowForwardIosIcon style={{fontSize:"12px"}}/> </span>
                <b style={{color:"black"}}>{findAppraisalName(appraisalName)}</b>
              </label>
              <Spin spinning={responseLoader}>
                <Table
                  className="mb-5"
                  dataSource={responseById}
                  columns={responseColumn}
                  rowKey="department_id"
                  pagination={false}
                />
              </Spin>
            </>
          ) : (
            <Spin spinning={displayLocationLoader}>
              <Table
                className="mb-5"
                dataSource={filteredDataSource}
                columns={appraisalHistorycolumns}
                rowKey="department_id"
                pagination={false}
              />
            </Spin>
          )}
        </>
      )}
    </div>
  );
};

export default AppraisalHistory;
