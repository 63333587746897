import StepsProgress from "organisms/StepsProgress";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import { getOnboardingJson } from "JsonFormat/Onboarding";
import TemplateContract from "pages/Contract/TemplateContract";
import { CALL_API } from "common/API";
import { connect } from "react-redux";
import { getLocations } from "redux/selectors";



function Onboarding  ({selectedCandidate, goBack, getData, locations}) {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const [issueContract, setIssueContract] = useState(false);
  const [details, setDetails] = useState(false);
  const { OnboardingBasicForm } = getOnboardingJson(data);
  const { OnboardingpaymentDetailsForm } = getOnboardingJson(data);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    setData(
      {
        "aboutUs": selectedCandidate?.aboutUs,
        "processId": selectedCandidate?.processId,
        "previousCompanyName": selectedCandidate?.previousCompanyName,
        "lastName": selectedCandidate?.lastName,
        "currentStatus": selectedCandidate?.currentStatus,
        "referencePhoneNo": selectedCandidate?.referencePhoneNo?.toString(),
        "emailId": selectedCandidate?.emailId,
        "referenceRole": selectedCandidate?.referenceRole,
        "jobId": selectedCandidate?.jobId,
        "createdAt": selectedCandidate?.createdAt,
        "contactReferenceName": selectedCandidate?.contactReferenceName,
        "address": selectedCandidate?.address,
        "phoneNo": selectedCandidate?.phoneNo?.toString(),
        "country": selectedCandidate?.country,
        "clientId": selectedCandidate?.clientId,
        "firstName": selectedCandidate?.firstName,
        "candidateId": selectedCandidate?.candidateId,
        "talentPoolStatus": selectedCandidate?.talentPoolStatus,
        "city": selectedCandidate?.city,
        "deleteStatus": selectedCandidate?.deleteStatus,
        "updatedAt": selectedCandidate?.updatedAt,
        "notes": selectedCandidate?.notes,
        "resume": selectedCandidate?.resume,
        "screeningQuestion": selectedCandidate?.screeningQuestion
    }
    )
  },[])

  const locationNames = locations?.map(location => location.location_name);

  const OnboardingForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [12, 12, 12, 12, 12, [, 4]],
        fields: [
          // Row 1
          [
            {
              id: 1,
              label: "Company Name",
              type: "text",
              name: "companyName",
              Placeholder: "Company Name",
              value: data["companyName"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Company Name`,
                },
              ],
              maxLength: 25,
              onKeyDown:  /^[a-zA-Z\s]+$/,
            },
          ],
          [
            {
              id: 2,
              label: "Location",
              type: "select",
              name: "location",
              Placeholder: "Location",
              value: data["location"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Location`,
                },
              ],
              options: locationNames,
              maxLength: 25,
              onKeyDown: /^[a-zA-Z\s]+$/,
            },
            {
              id: 3,
              label: "Employee Code",
              type: "text",
              name: "employeeId",
              Placeholder: "Employee Code",
              value: data["employeeId"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Employee Code`,
                },
              ],
              onKeyDown: /^[a-zA-Z0-9]+$/,
              maxLength: 20,
            },
          ],
          [
            {
              id: 4,
              label: "Employment Type",
              type: "select",
              name: "employmentType",
              Placeholder: "Employment Type",
              value: data["employmentType"],
              options: [
                "Full Time",
                "Part Time",
                "Contract", 
              ],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Employment Type`,
                },
              ],
             
            },
            {
              id: 5,
              label: "Job Title",
              type: "text",
              name: "jobTitle",
              Placeholder: "Job Title",
              value: data["jobTitle"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Job Title`,
                },
              ],
              onKeyDown: /^[a-zA-Z\s]+$/,
              maxLength: 25,
            },
          ],
          [
            {
              id: 6,
              label: "Start Date",
              type: "date",
              name: "startDate",
              Placeholder: "Start Date",
              value: data["startDate"],
              validationRules: [
                {
                  required: true,
                  message: `Please select the Start Date`,
                },
              ],
            },
            {
              id: 7,
              label: "Probation Length",
              type: "text",
              name: "probation",
              //need this option
              // options: selectList,
              Placeholder: "Probation Length",
              value: data["probation"],
              validationRules: [
                {
                  required: true,
                  message: `Please select the Probation Length`,
                },
              ],
              onKeyDown: /^[a-zA-Z0-9]+$/,
              maxLength: 15,
            },
          ],
          [
            {
              id: 8,
              label: "Primary Manager",
              type: "text",
              name: "primaryNanager",
              Placeholder: "Primary Manager",
              value: data["primaryManager"],
              validationRules: [
                {
                  required: true,
                  message: `Please select the Primary Manager`,
                },
              ],
              onKeyDown: /^[a-zA-Z\s]+$/,
              maxLength: 20,
            },
            {
              id: 9,
              label: "Secondary Manager",
              type: "text",
              name: "secondaryManager",
              Placeholder: "Secondary Manager",
              value: data["secondaryManager"],
              validationRules: [
                {
                  required: true,
                  message: `Please select the Secondary Manager`,
                },
              ],
              onKeyDown: /^[a-zA-Z\s]+$/,
              maxLength: 20,
            },
          ],
          [
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 11,
              label: "Back",
              type: "button",
              position: "end",
              background: "white", // Assuming you want a gray background
              textColor: "black",
              event: "click",
            },
            {
              id: 10,
              label: "Next",
              type: "button",
              position: "end",
              background: "blue",
              textColor: "white",
              event: "submit",
            },
          ],
        ],
      },
    ],
  };



  const stepdata = [
    { id: 1, title: "Basic" },
    { id: 2, title: "Employment Details" },
    { id: 3, title: "Payment Details" },
  ];


  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (e, name) => {
    setData({
      ...data,
      [name]: e,
    });
  };


  const handleSubmitForm = async(e) => {
    if(step === 2){
      setLoading(true)
      await CALL_API(`recruitment/onboard`, 'post', data);
      await CALL_API(`recruitment/candidate/${selectedCandidate?.candidateId}`, "patch", {
        deleteStatus :true
      });
      setLoading(false)
      getData()
      goBack()
    }
    else{
      setStep(step + 1);
    }
  };
  const handleClickForm = (e) => {
    if (step === 0) {
      goBack();
    } else {
      setIssueContract(true);
    }
  };

  const handleBackForm = (e) => {
    setStep(step - 1);
  };
  return (
    <>
      {!issueContract && !details && (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => goBack()}
          >
            <b className="">Back</b> <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className="pb-0 pt-1">Onboarding</b>
          <StepsProgress stepdata={stepdata} title="" step={step} />
          {step === 0 && (
            <>
              <GridBuilder
                formStructureJson={OnboardingBasicForm}
                handleChange={handleFormChange}
                formSubmit={handleSubmitForm}
                initialValues={data}
                handleClick={handleClickForm}
                handleSelectChange={handleSelectChange}
              />
            </>
          )}
          {step === 1 && (
            <>
              <GridBuilder
                formStructureJson={OnboardingForm}
                handleChange={handleFormChange}
                formSubmit={handleSubmitForm}
                initialValues={data}
                handleClick={handleClickForm}
                handleBack={handleBackForm}
                handleSelectChange={handleSelectChange}
              />
            </>
          )}
          {step === 2 && (
            <GridBuilder
              formStructureJson={OnboardingpaymentDetailsForm}
              handleChange={handleFormChange}
              formSubmit={handleSubmitForm}
              initialValues={data}
              handleClick={handleClickForm}
              handleBack={handleBackForm}
              handleSelectChange={handleSelectChange}
              loading={loading}
            />
          )}
        </>
      )}

      {issueContract && (
        <TemplateContract goBack={() => setIssueContract(false)} formdata={data} selectedCandidate={selectedCandidate} getData={getData} goTab={goBack}/>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  locations: getLocations(state),
});
export default connect(mapStateToProps)(Onboarding);

