import { CandidateInfo, PreviousCompanyDetails, ResumeForm } from 'JsonFormat/CandidateInfoJson'
import GridBuilder from 'UIBuilderEngine/GridBuilder';
import React, { useEffect, useState } from 'react'
import styled from "@emotion/styled";
import { Row, Col, message } from "antd";
import Recruitment from 'assets/TymeplusHR Recruitment logo.svg';
import { CALL_API } from 'common/API';
import { useParams } from 'react-router-dom';
import AnswerForm from "reusableComponent/formBuilder/AnsForm";
import { isEmpty } from 'lodash';
import { ButtonFilled } from 'reusableComponent/UIButtons';
import { STATUS_CODE, countryList } from 'common/Constants';
import {
    CheckCircleFilled,
} from '@ant-design/icons';
import COLORS from 'common/Colors';


const StyledContainer = styled.div`
  overflow-x: hidden;
  padding-bottom: 5%;
  h5{
    margin-bottom: 10px;
  }
  a{
    color: white;
    text-decoration: none;
    margin-bottom: 20px;
  }
  .anticon-check-circle {
    font-size: 4rem;
    display: block;
    padding: 10px;
    color: #2CFF00;
  }
  h3{
    color: ${COLORS.PRIMARY};
    font-size: 20px;
  }
`;
const StyledRow = styled(Row)`
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  background-color: ${COLORS.PRIMARY};
  color: #ffffff;

  img {
    max-width: 100%;
    height: auto;
  }
  .page-title {
    color: #ffffff;
    h4{
        color: #ffffff;
    }
    h1 {
      margin-top: 10px;
      color: #ffffff;
      letter-spacing: 2px;
      font-size: calc(3em + 1vw);
    }
    span {
      margin-top: -2%;
    }
  }
`;

function FormResponse() {
    const [data, setData] = useState({})
    const [formData, setFormData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState({})
    const [jobData, setJobData] = useState({})
    const [createFullForm, setCreatedFullForm] = useState([]);
    const [answerResponse, setAnswerResponse] = useState([]);
    const handleFormChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };
    const { clientId, id } = useParams();

    const handleSelectChange = (e, name) => {
        console.log("e--->", e, "name--->", name)
        setData({
            ...data,
            [name]: e,
        });
    };


    const handleSubmitForm = async (e) => {
        const payload = {
            clientId: clientId,
            jobId: id,
            processId: jobData?.processId,
            firstName: data?.firstName,
            lastName: data?.lastName,
            emailId: data?.emailId,
            screeningQuestion: answerResponse,
            resume: data?.resume?.url,
            phoneNo: data?.phoneNo,
            aboutUs: data?.aboutUs,
            country: data?.country,
            city: data?.city,
            address: data?.address,
            previousCompanyName: data?.companyName,
            contactReferenceName: data?.referenceName,
            referencePhoneNo: data?.referencePhoneNo,
            referenceRole: data?.referenceRole,
            filetype: data?.resume?.fileType
        }
        setLoading(true)
        const { code } = await CALL_API(`recruitment/candidate`, 'post', payload);
        if (code === STATUS_CODE.SUCCESS) {
            //   message.success("Registered Successfully")
            setData({})
            setFormData(true)
        }
        else {
            message.error("Something went Wrong please try again")
        }
        setLoading(false)

    };


    const getForm = async () => {
        console.log("clientId -->", clientId, "id --->", id)
        const { data } = await CALL_API(`recruitment/jobs/${id}`, "post", {
            clientId: clientId,
            jobId: id
        });
        setJobData(data)
        setSelectedItem(data?.screeningQuestion)
        setCreatedFullForm(data?.screeningQuestion?.questions)
    }

    useEffect(() => {
        getForm()
    }, [])

    const CandidateInfo = {
        totalpages: 1,
        pages: [
            {
                modalView: false,
                colSpan: [24, 24, 24],
                fields: [
                    // Row 1
                    [
                        {
                            id: 1,
                            label: "First Name",
                            type: "text",
                            name: "firstName",
                            Placeholder: "First Name",
                            value: "",
                            maxLength: 25,
                            validationRules: [
                                {
                                    required: true,
                                    message: `Please enter your First Name`,
                                },
                            ],
                            onKeyDown: /^[a-zA-Z\s]+$/,

                        },
                        {
                            id: 1,
                            label: "Last Name",
                            type: "text",
                            name: "lastName",
                            Placeholder: "Last Name",
                            value: "",
                            maxLength: 25,
                            validationRules: [
                                {
                                    required: true,
                                    message: `Please enter your Last Name`,
                                },
                            ],
                            onKeyDown: /^[a-zA-Z\s]+$/,
                        },


                    ],
                    [
                        {
                            id: 1,
                            label: "Email ID",
                            type: "text",
                            name: "emailId",
                            Placeholder: "Email ID",
                            value: "",
                            maxLength: 50,
                            validationRules: [
                                {
                                    required: true,
                                    message: `Please enter your Email ID`,
                                },
                                {
                                    pattern: /^\S+@\S+\.\S+$/,
                                    message: "Please enter a valid email address",
                                }
                            ],
                        },
                        {
                            id: 1,
                            label: "Phone Number",
                            type: "phone",
                            name: "phoneNo",
                            Placeholder: "Phone Number",
                            value: "",
                            maxLength: 20,
                            onKeyDown: /^[0-9+]$/,
                            validationRules: [
                                {
                                    required: true,
                                    message: `Please enter your Phone Number`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 1,
                            label: "Address",
                            type: "text",
                            name: "address",
                            Placeholder: "Address",
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `Please enter your Address`,
                                },
                            ],
                            maxLength: 70,
                        },
                        {
                            id: 1,
                            label: "City",
                            type: "text",
                            name: "city",
                            Placeholder: "City",
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `Please enter your City`,
                                },
                            ],
                            maxLength: 70,
                            onKeyDown: /^[a-zA-Z\s]+$/,
                        },
                    ],
                    [
                        {
                            id: 3,
                            label: "Country",
                            type: "select",
                            name: "country",
                            Placeholder: "Select",
                            value: "",
                            options: countryList,
                            validationRules: [
                                {
                                    required: true,
                                    message: `Please select the Country`,
                                },
                            ],
                        },

                    ],
                    [
                        {
                            id: 3,
                            label: "About Yourself",
                            type: "textarea",
                            name: "aboutUs",
                            Placeholder: "Type Here",
                            value: "",
                            maxLength: 600,
                            // options: countryList

                            validationRules: [
                                {
                                    required: true,
                                    message: `Please enter the detail`,
                                },
                            ],
                        },

                    ],
                    [
                        {
                            id: 1,
                            label: "Resume",
                            type: "label",
                            // name: "resume",
                            // Placeholder: "Resume",
                            // value: "",
                            // validationRules: [
                            //   {
                            //     required: true,
                            //     message: `Please Select the File`,
                            //   },
                            // ],
                        },
                    ],
                    [
                        {
                            id: 1,
                            label: "Resume",
                            type: "file",
                            name: "resume",
                            Placeholder: "Resume",
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `Please Select the File`,
                                },
                            ],
                        },
                    ],
                    ...(jobData?.getPreviousCompanyInfo ? [
                        [
                            {
                                id: 1,
                                label: "Reference From Previous Company",
                                type: "label",
                            },
                        ],
                        [
                            {
                                id: 1,
                                label: "Company Name",
                                type: "text",
                                name: "companyName",
                                Placeholder: "Company Name",
                                value: "",
                                validationRules: [
                                    {
                                        required: true,
                                        message: `Please enter your Company Name`,
                                    },
                                ],
                                onKeyDown: /^[a-zA-Z\s]+$/,
                                maxLength: 20,
                            },
                            {
                                id: 1,
                                label: "Person name to contact for reference",
                                type: "text",
                                name: "referenceName",
                                Placeholder: "Enter Name",
                                value: "",
                                validationRules: [
                                    {
                                        required: true,
                                        message: `Please enter Name`,
                                    },
                                ],
                                onKeyDown: /^[a-zA-Z\s]+$/,
                                maxLength: 20,
                            },
                        ],
                        [
                            {
                                id: 1,
                                label: "Phone Number",
                                type: "phone",
                                name: "referencePhoneNo",
                                Placeholder: "Phone Number",
                                value: "",
                                validationRules: [
                                    {
                                        required: true,
                                        message: `Please enter Phone Number`,
                                    },
                                ],
                                onKeyDown: /^[0-9+]$/,
                                maxLength: 20,
                            },
                            {
                                id: 1,
                                label: "Role of the person",
                                type: "text",
                                name: "referenceRole",
                                Placeholder: "Role",
                                value: "",
                                validationRules: [
                                    {
                                        required: true,
                                        message: `Please enter Role`,
                                    },
                                ],
                                onKeyDown: /^[a-zA-Z\s]+$/,
                                maxLength: 20,
                            },
                        ]
                    ] : []),
                    ...(!isEmpty(jobData?.screeningQuestion) ? [
                        [
                            {
                                id: 1,
                                label: "Screening Questions",
                                type: "label",
                            },
                        ],
                        [
                            {
                                id: 1,
                                label: "",
                                type: "component",
                                name: "resume",
                                Placeholder: "",
                                value: "",
                                content: (<>
                                    <AnswerForm
                                        fullFormDetail={createFullForm}
                                        selectedItem={selectedItem}
                                        setAnswerResponse={setAnswerResponse}
                                        answerResponse={answerResponse}
                                    />
                                </>)
                            },
                        ]
                    ] : []),

                    [
                        {
                            id: 10,
                            label: "Register",
                            type: "button",
                            position: "end",
                            textColor: "white",
                            event: "submit",
                        },
                    ]

                ],


            },
        ],
    }


    return (
        <StyledContainer >

            <StyledRow gutter={8} className='px-5'>
                <div className='container'>
                    <div className='d-flex gap-4 mt-2'>
                        <img src={Recruitment} alt="logo" />
                    </div>
                    <Col md={24} sm={24} lg={24} className="page-title">
                        <div className='text-center'>
                            <h4>{jobData?.title}</h4>
                            
                        </div>
                    </Col>
                    <Col md={24} sm={24} lg={24} className="text-end">
                        <a href='https://www.tymeplushr.com/' target='blank'>www.tymeplushr.com</a>
                    </Col>
                </div>
            </StyledRow>
          
            {formData && (
                <div className='text-center mt-5'>
                    <br />
                    <CheckCircleFilled />
                    <h3>Registered Successfully</h3>
                </div>
            )}
            {!formData && (
                <div className='container mt-3'>
                    <h5>Job Description</h5>
                    <div dangerouslySetInnerHTML={{ __html: jobData?.description }} />
                    <h5>Candidate Info</h5>
                    <GridBuilder
                        formStructureJson={CandidateInfo}
                        handleChange={handleFormChange}
                        formSubmit={handleSubmitForm}
                        initialValues={data}
                        handleSelectChange={handleSelectChange}
                        loading={loading}
                    />
                </div>
            )}


        </StyledContainer>
    )
}

export default FormResponse