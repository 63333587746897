import { message } from 'antd';
import { CALL_API } from 'common/API';
import React, { useState } from 'react'
import { Row, Col, Container } from "react-bootstrap"
import EachFormSection from 'reusableComponent/formBuilder/EachFormSection';
import FormBasicDetail from 'reusableComponent/formBuilder/formBasicDetails';

const AddForm = ({setPageView, ClientId, fetchData, updateProcessingModal, updateSuccessModal, name, getformDetails, getFormData, formId = false }) => {
    const [formDetailshow, setFormDetailshow] = useState(true);
    const [fullFormDetail, setFullFormDetail] = useState([]);
    const [formbasicDetail, setFormBasicDetail] = useState({
        title: '',
        description: ''
    });
    const [savedForm, setSavedForm] = useState(false);
   
    const addEditFormBuilder = async (payload) => {
        console.log('payload-->',payload);
        if (formId){
            getFormData(payload, formId)
            return
        }
        if(payload?.title === ""){
            message.error('Title is required')
            return
        }
        updateProcessingModal(true)
        const { code } = await CALL_API(`formbuilder/${ClientId}`, "post", payload);
        if (code === "SUCCESS") {
          setSavedForm(true);
          updateProcessingModal(false)
          updateSuccessModal({
            visibility: true,
            message: `Form created successfully`,
          });
        //   message.success("Form created successfully");
          fetchData()
          setPageView("list")
        }
      };
    return (
        <Row className="page-title">
            <section className='form_builder main_container'>
                <Row className='d-flex justify-content-center'>
                    <Col md={10} lg={7} sm={12}>
                        <Container className='bg_light my-3 rounded-2'>
                            <FormBasicDetail
                                setFormDetailshow={setFormDetailshow}
                                formDetailshow={formDetailshow}
                                setFormBasicDetail={setFormBasicDetail}
                                setFullFormDetail={setFullFormDetail}
                                formbasicDetail={formbasicDetail}
                            />
                        </Container>
                        <div className='my-3'>
                            <EachFormSection
                                formbasicDetail={formbasicDetail}
                                fullFormDetail={fullFormDetail}
                                setFullFormDetail={setFullFormDetail}
                                setSavedForm={setSavedForm}
                                isFormBuilder={true}
                                addEditFormBuilder={addEditFormBuilder}
                            />
                        </div>
                    </Col>
                </Row>
            </section>
            
        </Row>
    )
}

export default AddForm;