import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Spin, Modal, message } from "antd";
import Button from "atoms/Button";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getStatusListFromID,
  getClientid,
  getAllDepartmentList,
  getLocations,
  getMenuList,
} from "redux/selectors";
import { setRoleList } from "redux/actions";
import COLORS from "common/Colors";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";
import { CSVLink } from "react-csv";
import AddRole from "organisms/AddRole";
import EditRole from "organisms/EditRole";
import { reduce } from "lodash";
import moment from "moment";
import Table from "atoms/Table";
import { ButtonOutlined } from "reusableComponent/UIButtons";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
  }
  .ant-table-content {
    overflow-x: auto;
  }
  .ant-table-cell {
    padding: 10px;
    height: 0px !important;
    border: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background: none;
  }

  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0 !important;
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 35px 0px 20px 0px;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body {
    padding: 0px 0px 35px 0px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .page-title-head {
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  width: 125px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;

function Roles({
  clientId,
  AllDepartmentList,
  locationList,
  menuList,
  userRoleId,
}) {
  const [addRole, toggleAddRole] = useState(false);
  const [editRole, toggleEditRole] = useState(false);
  const [RoleDetails, setRoleDetails] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [deleteRole, setDeleteRole] = useState(false);
  const [roleid, setRoleid] = useState("");
  const [displayLocationLoader, setDisplayLocationLoader] = useState(false);
  const [roleCSVlist, setroleCSVlist] = useState([]);
  const [btnLoader, togglebtnLoader] = useState(false);

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const csvLinkEl = useRef();

  const CSVHeaders = [
    { label: "Role name", key: "roleName" },
    { label: "Department", key: "department" },
    { label: "Location", key: "locationId" },
    { label: "Created On", key: "insertedAt" },
  ];

  const columns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "role_name",
      width: "10%",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: "20%",
      className: "normal-column",
      render: (department) => {
        if (department) {
          const departmentIds = Array.isArray(department)
            ? department
            : [department];
          const departmentNames = departmentIds.map((id) => {
            const matchingDepartment = AllDepartmentList.find(
              (item) => item.department_id === id
            );
            return matchingDepartment ? matchingDepartment.department_name : "";
          });
          return departmentNames.join(", ");
        } else {
          return "-";
        }
      },
    },
    {
      title: "Location",
      dataIndex: "locationId",
      key: "locationId",
      width: "20%",
      className: "normal-column",
      render: (locationId) => {
        if (locationId) {
          const locationIdIds = Array.isArray(locationId)
            ? locationId
            : [locationId];
          const locationNames = locationIdIds.map((id) => {
            const matchinglocationId = locationList.find(
              (item) => item.location_id === id
            );
            return matchinglocationId ? matchinglocationId.location_name : "";
          });
          return locationNames.join(", ");
        } else {
          return "-";
        }
      },
    },
    {
      title: "Created On",
      dataIndex: "insertedAt",
      key: "insertedAt",
      width: "15%",
      align: "center",
      render: (_, record) => {
        return moment(record?.insertedAt).format("DD-MM-yyyy");
      },
    },
  ];

  const addRoleModal = () => {
    toggleAddRole(true);
  };

  useEffect(() => {
    getRoleList();
  }, [setRoleList]);

  const getRoleList = async () => {
    setDisplayLocationLoader(true);
    const { code, response } = await CALL_API(
      `owner-roles/${clientId}`,
      "get",
      {}
    );
    if (code === STATUS_CODE.SUCCESS) {
      setRoleList(response.reverse());
      setDisplayLocationLoader(false);
    }
  };

  const prepareCSV = async () => {
    togglebtnLoader(true);
    if (roleList.length > 0) {
      setroleCSVlist(
        reduce(
          roleList,
          function (result, list) {
            const departmentIds = Array.isArray(list?.department)
              ? list?.department
              : [list?.department];
            const departmentNames = departmentIds.map((id) => {
              const matchingDepartment = AllDepartmentList.find(
                (item) => item.department_id === id
              );
              return matchingDepartment
                ? matchingDepartment.department_name
                : "";
            });

            const locationIdIds = Array.isArray(list?.locationId)
              ? list?.locationId
              : [list?.locationId];
            const locationNames = locationIdIds.map((id) => {
              const matchinglocationId = locationList.find(
                (item) => item.location_id === id
              );
              return matchinglocationId ? matchinglocationId.location_name : "";
            });

            result.push({
              "Role name": list.roleName,
              Department: departmentNames?.join(", "),
              Location: locationNames?.join(", "),
              "Created On": moment(list?.insertedAt).format("yyyy-MM-DD"),
            });
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
    togglebtnLoader(false);
  };

  const deletedRole = () => {
    setDeleteRole(true);
  };

  const ViewRole = (RoleObject, isViewOnly = false) => {
    setRoleid(RoleObject.roleId);
    toggleEditRole(true);
    setRoleDetails({
      ...RoleObject,
      isViewOnly,
    });
  };
  const confirmdelete = async () => {
    setDisplayLocationLoader(true);
    const { code, Message } = await CALL_API(
      `owner-roles/${clientId}/${roleid}`,
      "delete",
      {}
    );
    if (code === STATUS_CODE.SUCCESS) {
      setDeleteRole(false);
      message.success(Message);
      toggleEditRole(false);
      getRoleList();
      setDisplayLocationLoader(false);
    }
  };
  const headerstyle = { fontSize: "20px", fontWeight: "bold" };
  return (
    <>
      {!addRole && !editRole && (
        <Row className="page-title">
          <Col sm={24} md={12} style={headerstyle}>
            List of Roles
          </Col>
          <Col sm={24} md={12} className="align-right">
            <ButtonOutlined onClick={prepareCSV}>
              Export CSV file
            </ButtonOutlined>
            <CSVLink
              header={CSVHeaders}
              data={roleCSVlist}
              filename={"role-list.csv"}
              asyncOnClick={true}
              ref={csvLinkEl}
            />
            {Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "roles" && subItem?.permission.create
                )
            ) && (
              <StyledButton
                type="primary"
                htmlType="submit"
                onClick={() => addRoleModal(true)}
              >
                +Add Role
              </StyledButton>
            )}
          </Col>
        </Row>
      )}

      {!addRole && !editRole && (
        <Spin spinning={displayLocationLoader}>
          <StyledTable
            className="mb-5"
            dataSource={roleList}
            columns={columns}
            rowKey="role_id"
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  ViewRole(record);
                },
              };
            }}
          />
        </Spin>
      )}
      {addRole && (
        <AddRole
          isModalVisible={addRole}
          closeModal={() => toggleAddRole(false)}
          getRoleList={getRoleList}
          RoleDetails={RoleDetails}
        />
      )}

      {editRole && (
        <EditRole
          isModalVisible={editRole}
          closeModal={() => toggleEditRole(false)}
          getRoleList={getRoleList}
          RoleDetails={RoleDetails}
          deleteRole={deletedRole}
          viewOnly={
            Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "roles" && subItem?.permission.edit
                )
            )
              ? false
              : true
          }
        />
      )}

      <StyleddeactivateModal
        title="Are you sure you want to deactivate this Role?"
        width={500}
        open={deleteRole}
        onCancel={() => setDeleteRole(false)}
        footer={[]}
        centered
      >
        <Row className="page-title-head">
          <Col>
            <StyledButton
              className="mx-2"
              type="primary"
              htmlType="submit"
              onClick={() => setDeleteRole(false)}
            >
              No
            </StyledButton>
          </Col>
          <Col>
            <StyledButton
              className="mx-2"
              loading={btnLoader}
              type="primary"
              htmlType="submit"
              onClick={confirmdelete}
            >
              {btnLoader ? "Yes" : "Yes"}
            </StyledButton>
          </Col>
        </Row>
      </StyleddeactivateModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  statusListFromID: getStatusListFromID(state, [4, 5, 6]),
  selectedDepartmentId: getSelectedDepartmentId(state),
  clientId: getClientid(state),
  AllDepartmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  menuList: getMenuList(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveRoleList: setRoleList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null)(Roles);
