export const selectList = ["Month", "Week", "Hours"];
export const getOnboardingJson = (data) => {
  const OnboardingBasicForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [12, 12, 24],
        fields: [
          [
            {
              id: 1,
              label: "First Name",
              type: "text",
              name: "firstName",
              Placeholder: "First Name",
              value: data["firstName"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your First Name`,
                },
              ],
              onKeyDown: /^[a-zA-Z\s]+$/,
              maxLength: 20,
            },
            {
              id: 2,
              label: "Last Name",
              type: "text",
              name: "lastName",
              Placeholder: "Last Name",
              value: data["lastname"],
              validationRules: [
                {
                  required: true,
                  message: `Please select the Last Name`,
                },
              ],
              onKeyDown: /^[a-zA-Z\s]+$/,
              maxLength: 20,
            },
          ],
          [
            {
              id: 3,
              label: "Email ID",
              type: "email",
              name: "emailId",
              Placeholder: "Email ID",
              value: data["emailId"],
              maxLength: 50,
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Email ID`,
                },
                {
                  pattern: /^\S+@\S+\.\S+$/,
                  message: "Please enter a valid email address",
                },
              ],
            },
            {
              id: 4,
              label: "Phone Number",
              type: "phone",
              name: "phoneNo",
              Placeholder: "phone no",
              value: data["phoneNo"],
              maxLength:20,
              onKeyDown: /^[0-9+]$/,
              validationRules: [
                {
                  required: true,
                  message: `Please select the Phone Number`,
                },
              ],
            },
          ],
          [
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 11,
              label: "Back",
              type: "button",
              position: "end",
              background: "white", 
              textColor: "black",
              event: "back",
            },
            {
              id: 10,
              label: "Next",
              type: "button",
              position: "end",
              background: "blue",
              textColor: "white",
              event: "submit",
            },
          ],
        ],
      },
    ],
  };

  const OnboardingpaymentDetailsForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [12, 12, 24],
        fields: [
          // Row 1
          [
            {
              id: 1,
              label: "Pay Rate",
              type: "country",
              name: "payRate",
              Placeholder: "Pay Rate",
              value: data["payRate"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Pay Rate`,
                },
              ],
              maxLength: 10,
              onKeyDown: /^[0-9]$/,
            },
            {
              id: 2,
              label: "Payment by",
              type: "select",
              name: "paymentBy",
              Placeholder: "Select",
              options: ["Month", "Week", "Hours"],
              value: data["paymentBy"],
              validationRules: [
                {
                  required: true,
                  message: `Please select the Payment by`,
                },
              ],
            },
          ],
          [
            {
              id: 3,
              label: "Working days (per week)",
              type: "text",
              name: "workingDaysPerWeek",
              Placeholder: "Working days (per week)",
              value: data["workingDaysPerWeek"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Working days (per week)`,
                },
              ],
              onKeyDown: /^[0-9]$/,
              maxLength: 1,
            },
            {
              id: 3,
              label: "Working Hours (per day)",
              type: "text",
              name: "workingHoursPerDay",
              Placeholder: "Working Hours (per day)",
              value: data["workingHoursPerDay"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Working Hours (per day)`,
                },
              ],
              onKeyDown: /^[0-9]$/,
              maxLength: 2,
            },
          ],
          [
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 11,
              label: "Back",
              type: "button",
              position: "end",
              background: "white",
              textColor: "black",
              event: "click",
            },
            {
              id: 11,
              label: "Issue Contract",
              type: "button",
              position: "end",
              background: "white",
              textColor: "black",
              event: "back",
            },

            {
              id: 10,
              label: "Finish",
              type: "button",
              position: "end",
              background: "blue",
              textColor: "white",
              event: "submit",
            },
          ],
        ],
      },
    ],
  };

  return {
    OnboardingBasicForm,
    OnboardingpaymentDetailsForm,
  };
};
