import React, { useEffect, useLayoutEffect, useState } from "react";
import { Layout, Row } from "antd";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Modal, Result, Button } from "antd";
import { FieldTimeOutlined } from "@ant-design/icons";
import styled from "styled-components";
// Components
import SideBar from "molecules/SideMenu";
import Header from "molecules/Header";
import Container from "pages/Container";
import { STATUS_CODE } from "common/Constants";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setIsUserLoggedIn,
  setSystemDate,
  setUsernName,
  setLoadingDataFirstTime,
  setBreakTypesList,
  setDepartmentList,
  setLeaveCategoryList,
  setLocationList,
  setPunchActions,
  setUserRole,
  setDepartmentId,
  setRoleList,
  setCheckedIn,
  setCheckInLocationId,
  setIsOnBreak,
  setUserList,
  setStatusList,
  setAllDepartmentList,
  setPunchId,
  setBreakId,
  toggleProcessingModal,
  setShiftTimeMaster,
  setUserProfile,
  clearStore,
  setProfilePic,
  setMenuList,
  setClientid,
  setCurrentPlan,
  setTrailPeriod,
  setUserMenuList,
  setCheckInDepartmentId,
  setBandList,
} from "redux/actions";
import {
  getIsUserLoggedIn,
  getUserName,
  getSysteDate,
  getUserRoleId,
  getTrailPeriod,
  getUserInfo,
  getMenuList,
  getUserList,
  getLocations,
  getLoadingDataFirstTime
} from "redux/selectors";
import ProcessingModal from "molecules/ProcessingModal";
import { CALL_API } from "common/API";
import { getServerDateToMySql } from "utils/Date";
import PrivacyAndPolicy from "pages/PrivacyAndPolicy";
import { jwtDecode } from "jwt-decode";
import AppSideBar from "molecules/AppSideBar";
import FormResponse from "pages/FormResponse";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { find, get } from "lodash";

var createHost = require("cross-domain-storage/host");

const StyledAppDiv = styled.div`
  height: 100%;
`;
const PlanWrap = styled(Row)`
  background-color: #d32f2f;
  height: 30px;
  padding: 5px;
  box-shadow: 5px 5px 10px #00000029;
  cursor: pointer;
  .title {
    font-weight: bold;
    color: #fff;
  }
  .subtitle {
    font-weight: bold;
    color: #ffff00;
  }
  .black-title {
    color: #000000;
  }
`;

const App = ({
  activePage,
  isUserLoggedIn,
  setUserLoggedIn,
  saveSystemDate,
  setBreakTypes,
  saveDepartmentList,
  saveAllDepartmentList,
  saveLeaveCategoryList,
  saveLocationList,
  savePunchActions,
  saveUsernName,
  toggleLoadingDataFirstTime,
  saveDepartmentId,
  saveRoleList,
  updateCheckInLocationId,
  updateIsOnBreak,
  updateCheckedIn,
  updateUserList,
  saveStatusList,
  savePunchId,
  saveBreakId,
  updateProcessingModal,
  saveShiftTimeMaster,
  saveUserProfile,
  removeStoreData,
  saveProfilePic,
  saveUserRole,
  saveMenuList,
  saveBandList,
  saveClientid,
  saveTrailPeriod,
  saveCurrentPlan,
  userRoleId,
  TrailPeriod,
  saveUserMenuList,
  updateCheckInDepartmentId,
  menuList,
  userList,
  userInfo,
  locationList,
  LoadingDataFirstTime
}) => {
  createHost([
    {
      origin: "http://localhost:3000",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "http://localhost:3001",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "https://www.tractask.waila.co",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "https://www.crm.waila.co",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "http://localhost:3001/",
      allowedMethods: ["get", "set", "remove"],
    },
  ]);

  const [pageTitle, setPageTitle] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [intervalID, setIntervalID] = useState(0);
  const [open, setOpen] = useState(false);
  const [showplan, setshowplan] = useState(false);
  const [sessionTimeout, setSessionTimeout] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuLists, setMenuLists] = useState([]);
  const [user, setUser] = useState([]);
  const [defaultTimeZone, setDefaultTimeZone] = useState('')
  const [isupdate, setIsupdate] = useState(true)


  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setCollapsed(!collapsed);
  };

  const isLoggedIn = (clientid) => {
    setUserLoggedIn(true);
    toggleLoadingDataFirstTime(true);
    fetchMasterData(true, clientid);
  };

  const isLoggedOut = () => {
    setUserLoggedIn(true);
    toggleLoadingDataFirstTime(true);
  };

  const logOut = () => {
    setTimeout(() => {
      window.localStorage.removeItem('token');
      isLoggedOut();
      window.location.href = "https://www.tymeplushr.com";
    }, 500);
  };

  const updatePageTitle = (title) => {
    setPageTitle(title);
  };

  let defaultKey = "";

  useEffect(() => {
    if (isUserLoggedIn && defaultTimeZone && isupdate) {
      const intId = setInterval(() => {
        startTimeCounter();
        
      }, 1000);
      setIntervalID(intId);
      setUserLoggedIn(true);
      setIsupdate(true);
    } else {
      clearInterval(intervalID);
    }
  }, [isUserLoggedIn, isupdate]);


  useEffect(() => {
    startTimeCounter();
  }, [defaultTimeZone]);
 


  useLayoutEffect(() => {
    clearInterval(intervalID);
    updateProcessingModal(false);
    if (window.innerWidth < 567) {
      toggleDrawer();
    }
  }, []);

  const redirectToNewDomain = () => {
    window.location.href = 'https://www.tymeplushr.com/';
  };

  // localtoken is used For local development

  // const localtoken =
  // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJZCI6IldBTjNLRVciLCJ1c2VySWQiOiJqYXNtaW5najIwMDFAZ21haWwuY29tIiwidXNlck5hbWUiOiJKYXNtaW4iLCJ1c2VyVHlwZSI6IkVtcGxveWVlIiwiaWF0IjoxNzIwMDc0NDIxLCJleHAiOjE3MjAxMTc2MjF9.qVXcl6Z_g8p57hlVMuqZYRVPL0cqH6KppSUCo3lyse4";

  // useEffect(() => {
  //   window.localStorage.setItem("token", localtoken);
  //   sessionStorage.setItem('tokenTimestamp', new Date().getTime().toString());
  //   getPreview()
  //   const clientid = jwtDecode(window.localStorage.getItem("token"))?.clientId;
  //   isLoggedIn(clientid);
  //   setUserLoggedIn(true)
  // }, []);


  const urlParams = new URLSearchParams(window.location.search);
  var token = urlParams.get("token");
  const urlParts = window.location.pathname.split('/');
  const clientIdFromUrl = urlParts[2];
  const idFromUrl = urlParts[3];
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          console.log("Token Set")
          window.localStorage.removeItem('token');
          removeStoreData();
          window.localStorage.setItem("token", token);
          sessionStorage.setItem('tokenTimestamp', new Date().getTime().toString());
          const clientId = jwtDecode(window.localStorage.getItem("token"))?.clientId;
          isLoggedIn(clientId);
          getPreview();
        }
        // Extracting values from the URL

        console.log("clientIdFromUrl -->", clientIdFromUrl)
        console.log("idFromUrl -->", idFromUrl)
        if (clientIdFromUrl && idFromUrl) {
          console.log("Form response")
          setUserLoggedIn(false)
        }
        let isToken = window.localStorage.getItem("token")
        if (!isToken && token === null && !clientIdFromUrl && !idFromUrl) {
          console.log("No Token set");
          redirectToNewDomain();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []); 
  // Empty dependency array ensures the effect runs only once on mount



  useEffect(() => {
    // Function to check token expiration
    const checkTokenExpiration = () => {
        const token =  window.localStorage.getItem("token");
        const decodedToken = token ? jwtDecode(token) : null;
        if (!decodedToken || !decodedToken.exp) return;

        const currentTime = new Date().getTime();
        const expirationTime = decodedToken.exp * 1000; // exp is in seconds, convert to milliseconds

        if (currentTime > expirationTime) {
            setSessionTimeout(true);
        }
    };

    // Initial check
    checkTokenExpiration();

    // Set interval for periodic check
    const intervalId = setInterval(checkTokenExpiration, 60000); // Check every 1 minute

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
}, []);




useEffect(()=>{
  const filteruser = userList?.filter(
    (item) => item?.userid === get(userInfo, "userid", "")
  );
  const baseLocationId = filteruser[0]?.baselocationid;
  const timezone = locationList.filter((item)=> item.location_id === baseLocationId)
    setTimeout(() => {
      setIsupdate(false);
      setUser(filteruser);
      setDefaultTimeZone(timezone)
      setIsupdate(true);

  }, 1000);
},[userList])


  const startTimeCounter = () => {
    const d = new Date();
    
    // const baseLocationId = user[0]?.baselocationid;
    if(userRoleId === "1"){
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const ist = new Date(
        d.toLocaleString("en-US", { timeZone: userTimeZone })
      );
      return saveSystemDate(getServerDateToMySql(ist));
    }else if(userRoleId !== "1" && defaultTimeZone){
      const userTimeZone = defaultTimeZone[0]?.usertimezone
      const ist = new Date(
        d.toLocaleString("en-US", { timeZone: userTimeZone? userTimeZone : Intl.DateTimeFormat().resolvedOptions().timeZone })
      );
      return saveSystemDate(getServerDateToMySql(ist));
    }
   
    
  };


  const fetchMasterData = async (fetch_master_data, clientId) => {
    const {
      code,
      name,
      userid,
      role_id,
      breakList,
      roleList,
      departmentList,
      leaveCategories,
      locations,
      punchActions,
      location_id,
      punch_action_id,
      userList,
      statusList,
      shiftTimeMaster,
      break_id = "",
      department_id,
      location_name,
      username,
      email_id,
      job_title,
      department,
      payroll,
      staff_department_id,
      menuList,
      bandList,
      clientid,
      trailperiodendon,
      currentPlan,
      userMenuList,
      threadid,
      joiningdate
    } = await CALL_API(`master-data/${clientId}`, "post", {
      fetch_master_data,
    });
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        const setRoleList = find(roleList, { 'roleId': role_id[0] });
        setBreakTypes(breakList);
        saveMenuList(menuList);
        setMenuLists(menuList);
        saveBandList(bandList);
        saveUserMenuList(userMenuList);
        saveDepartmentList(setRoleList?.department);
        saveAllDepartmentList(departmentList);
        saveDepartmentId(setRoleList?.department);
        saveLeaveCategoryList(leaveCategories);
        saveLocationList(locations);
        saveTrailPeriod(trailperiodendon);
        saveCurrentPlan(currentPlan);
        saveClientid(clientid);
        savePunchActions(punchActions);
        saveUsernName(name);
        saveRoleList(roleList);
        updateUserList(userList);
        saveStatusList(statusList);
        saveShiftTimeMaster(shiftTimeMaster);
       const filteruser = userList?.filter(
    (item) => item?.userid === userid
  );
  const baseLocationId = filteruser[0]?.baselocationid;
  const timezone = locations.filter((item)=> item.location_id === baseLocationId)
    setTimeout(() => {
      setUser(filteruser);
      setDefaultTimeZone(timezone)
      setIsupdate(false);
      setUserLoggedIn(true);
      setIsupdate(true);
  }, 1000);
        saveUserProfile({
          location_name,
          joiningdate,
          name,
          email_id,
          job_title,
          department,
          payroll,
          userid,
          staff_department_id,
          threadid,
          role_id,
        });
      }
      // const filteruser = userList?.filter(
      //   (item) => item?.userid === userid
      // );
      // setUser(filteruser);
      updateCheckInLocationId(location_id?.toString());
      updateCheckInDepartmentId(department_id?.toString());
      updateIsOnBreak(break_id ? true : false);
      saveBreakId(break_id);
      savePunchId(punch_action_id);
      saveUserRole(role_id[0]);
      updateCheckedIn(punch_action_id ? true : false);
      toggleLoadingDataFirstTime(false);
      if (punch_action_id) {
        window.localStorage.setItem("login_reminder", "true");
      }
    } else {
      setSessionTimeout(true);
    }
  };

  const getPreview = async () => {
    const token = window.localStorage.getItem("token");

    const clientId = jwtDecode(token)?.clientId;

    const { code, url } = await CALL_API(
      `profile-upload/${clientId}`,
      "get",
      {}
    );
    if (code === STATUS_CODE.SUCCESS) {
      saveProfilePic(url);
    }
  };

  const iconcolor = { color: "#2D53DA" }

  return (

    <StyledAppDiv>
      {isUserLoggedIn && (
        <>
          <Router>
            <Layout isapp={`${navigator.userAgent === "application"}`}>
              <SideBar
                open={open}
                onClose={onClose}
                setPageTitle={updatePageTitle}
                defaultKey={defaultKey}
                activePage={activePage}
                collapsed={collapsed}
                toggleCollapse={toggleDrawer}
              ></SideBar>
              <Layout>
                <Header
                  showDrawer={showDrawer}
                  pageTitle={pageTitle}
                  setLoggedIn={isLoggedIn}
                  setPageTitle={updatePageTitle}
                  toggleCollapse={toggleDrawer}
                  collapsed={collapsed}
                  showplan={showplan}
                  setshowplan={setshowplan}
                  isLoggedOut={isLoggedOut}
                ></Header>
                {userRoleId === 1 && TrailPeriod && (
                  <PlanWrap onClick={() => setshowplan(true)}>
                    <marquee
                      className="title"
                      width="100%"
                      direction="left"
                      height="100px"
                    >
                      Your Free Trail Period Ends on{" "}
                      <span className="subtitle">{TrailPeriod}</span> Please{" "}
                      <span className="black-title">Upgrade</span> Your Plan
                    </marquee>
                  </PlanWrap>
                )}
                {
                  userRoleId !== 1 && window.innerWidth < 567 && Menulist && Menulist?.find(item => item?.key === "managementview" && item?.access) && (
                    <div className="bg-white text-end px-4 pt-2 pb-2" ><ArrowCircleLeftIcon style={iconcolor} onClick={() => setDrawerOpen(true)} /></div>
                  )
                }

                <Container menuLists={menuLists} appBarWidth={Menulist && Menulist?.find(item => item?.key === "managementview" && item?.access) ? "70px" : "0"} />
              </Layout>

              {userRoleId !== "1" && (
                <>
                  {Menulist && Menulist?.find(item => item?.key === "managementview" && item?.access) && (
                    <AppSideBar open={drawerOpen} setOpen={setDrawerOpen} />
                  )}
                </>)}
            </Layout>

          </Router>
        </>
      )}

      <Router>
        <Route path="/privacy-policy">
          <PrivacyAndPolicy />
        </Route>
        <Route path="/form/:clientId/:id">
          <FormResponse />
        </Route>
      </Router>

      <ProcessingModal />

      <Modal
        visible={sessionTimeout}
        maskClosable={false}
        title={null}
        footer={null}
        closable={false}
      >
        <Result
          icon={<FieldTimeOutlined />}
          title="Your session has expired!"
          extra={
            <Button onClick={() => logOut()} type="primary">
              Login
            </Button>
          }
        />
      </Modal>
    </StyledAppDiv>
  );
};

const mapStateToProps = (state) => ({
  isUserLoggedIn: getIsUserLoggedIn(state),
  userName: getUserName(state),
  systeDate: getSysteDate(state),
  userRoleId: getUserRoleId(state),
  TrailPeriod: getTrailPeriod(state),
  userInfo: getUserInfo(state),
  menuList: getMenuList(state),
  userList: getUserList(state),
  locationList: getLocations(state),
  LoadingDataFirstTime: getLoadingDataFirstTime(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUserLoggedIn: setIsUserLoggedIn,
      saveSystemDate: setSystemDate,
      saveUsernName: setUsernName,
      setBreakTypes: setBreakTypesList,
      saveDepartmentList: setDepartmentList,
      saveAllDepartmentList: setAllDepartmentList,
      saveLeaveCategoryList: setLeaveCategoryList,
      saveLocationList: setLocationList,
      savePunchActions: setPunchActions,
      saveUserRole: setUserRole,
      toggleLoadingDataFirstTime: setLoadingDataFirstTime,
      saveDepartmentId: setDepartmentId,
      saveRoleList: setRoleList,
      updateCheckedIn: setCheckedIn,
      updateCheckInLocationId: setCheckInLocationId,
      updateIsOnBreak: setIsOnBreak,
      updateUserList: setUserList,
      saveStatusList: setStatusList,
      savePunchId: setPunchId,
      saveBreakId: setBreakId,
      updateProcessingModal: toggleProcessingModal,
      saveShiftTimeMaster: setShiftTimeMaster,
      saveUserProfile: setUserProfile,
      removeStoreData: clearStore,
      saveProfilePic: setProfilePic,
      saveMenuList: setMenuList,
      saveBandList: setBandList,
      saveUserMenuList: setUserMenuList,
      saveClientid: setClientid,
      saveCurrentPlan: setCurrentPlan,
      saveTrailPeriod: setTrailPeriod,
      updateCheckInDepartmentId: setCheckInDepartmentId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
