import COLORS from "common/Colors";
import Styled from "styled-components"

const Wrapper = Styled.div`
    h6{
        cursor: pointer;
    }
   .template{
    /* background: #f7fffd; */
    padding: 20px;
    h6{
      font-weight: bold;
      font-size: 18px;
    }
     span{
        cursor: pointer;
        font-weight: 500;
        margin-right: 20px;
        color: ${COLORS.PRIMARY}
     }
     .card-container{
      padding: 20px;
      display: flex;
      gap:20px;
      flex-wrap: wrap;
      .cards{
        border: 1px solid #f1f2f4;
        border-radius: 10px;
        max-width: 210px;
        img{
            width: 100%;
        }
      }
   }
   }
  


`;

export const CardImage = Styled.div`
  background: ${props => props.backgroundColor || '#F0EBF8'};
  border-radius: 10px 10px 0px 0px;
  max-width: 210px;

  img {
    width: 100%;
  }
`;

export default Wrapper
