import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { Row, Col } from "react-bootstrap";
import { Empty, message } from "antd";
import SecondSubMenu from "./SecondSubMenu";
import { Select } from "antd";
import { CALL_API } from "common/API";
import { find, get, isEmpty, map } from "lodash";
import { connect } from "react-redux";
import { toggleProcessingModal } from "redux/actions";
import { bindActionCreators } from "redux";
import { ButtonOutlined } from "reusableComponent/UIButtons";

const CustomizeTabPanel = styled(TabPanel)(({ theme }) => ({
  "&.root": {
    padding: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "400px",
    },
  },
  width: "100%",
  padding: 0,
}));

const VerticalTab = styled(Tab)({
  textTransform: "none",
  color: "#636980",
  textAlign: "left",
  "&.MuiTabs-indicator": {
    width: "0px",
  },
  "&.Mui-selected": {
    borderRadius: "15px",
    border: "none",
    background: "#E3E8FC",
    color: "#1F243B",
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function VerticalTabs({
  candidateList,
  processList,
  jobList,
  getData,
  activeTab = "",
  processValue,
  setShowComponent,
  onboardCandidate,
  selectedItem,
  movetoChange,
  goBack,
  updateProcessingModal,
  index,
}) {
  const [localCandidateList, setLocalCandidateList] = React.useState([]);

  const [value, setValue] = React.useState(index);
  const [selectedCandidate, setSelectedCandidate] = React.useState(
    localCandidateList[value]
  );
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedCandidate(localCandidateList[newValue]); // Update selected candidate
    // getData()
  };

  React.useEffect(() => {
    if (activeTab.toString() === "1") {
      const filteredCandidates = candidateList.filter(
        (candidate) =>
          candidate.currentStatus === processValue?.toString() &&
          candidate?.jobId === selectedItem?.jobId
      );
      setLocalCandidateList(filteredCandidates);
      setSelectedCandidate(filteredCandidates[0]);
    } else {
      setLocalCandidateList(candidateList);
      setSelectedCandidate(candidateList[value]);
    }
  }, [processValue]);

  const handleSelectChange = async (value) => {
    const updatedCandidate = {
      ...selectedCandidate,
      currentStatus: value,
    };
    // movetoChange(value)
    // Update the state with the new candidate object
    setSelectedCandidate(updatedCandidate);
    // Update the localCandidateList with the updatedCandidate
    const updatedLocalCandidateList = localCandidateList.map((candidate) => {
      if (candidate.candidateId === selectedCandidate?.candidateId) {
        return updatedCandidate;
      } else {
        return candidate;
      }
    });

    // Set the updated localCandidateList state
    setLocalCandidateList(updatedLocalCandidateList);

    updateProcessingModal(true);
    await CALL_API(
      `recruitment/candidate/${selectedCandidate?.candidateId}`,
      "patch",
      {
        currentStatus: value,
      }
    );
    updateProcessingModal(false);
    goBack();
    getData();
  };

  const moveToTalentPool = async () => {
    updateProcessingModal(true);
    await CALL_API(
      `recruitment/candidate/${selectedCandidate?.candidateId}`,
      "patch",
      {
        talentPoolStatus: true,
      }
    );
    updateProcessingModal(false);
    message.success("Moved to Talent Pool");
    getData();
    goBack();
    // Remove the selected candidate from the local candidate list
    const updatedLocalCandidateList = localCandidateList.filter(
      (candidate) => candidate.candidateId !== selectedCandidate.candidateId
    );

    setLocalCandidateList(updatedLocalCandidateList);
    setSelectedCandidate(updatedLocalCandidateList[0]);
    setValue(0);
    setLoading(false);
  };

  const getProcess = () => {
    const process = find(processList, {
      processId: selectedCandidate?.processId,
    });

    if (process) {
      const steps = get(process, "steps", []);
      //   const currentStatus = record?.currentStatus || '0';
      return steps;
    }
    return [];
  };

  const transformedSteps = map(getProcess(), (step) => {
    const key = Object.keys(step)[0];
    const value = step[key];
    return { value: key, label: value };
  });

  const tabheight = { maxHeight: "80vh" };

  return (
    <Grid container>
      {localCandidateList?.length > 0 && (
        <>
          <Grid item xs={24} sm={24} md={4} lg={2}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              style={tabheight}
            >
              {localCandidateList?.map((item, index) => (
                <VerticalTab
                  key={index}
                  label={`${item?.firstName} ${item?.lastName}`}
                  {...a11yProps(index)}
                  className="mt-3"
                />
              ))}
            </Tabs>
          </Grid>

          <Grid
            item
            xs={24}
            sm={24}
            md={8}
            lg={10}
            sx={{ borderLeft: 2, borderColor: "divider" }}
          >
            {selectedCandidate && (
              <CustomizeTabPanel value={value} index={value}>
                <div>
                  <Row>
                    <Col lg={5} sm={12}>
                      <h6 className="fw-semibold">
                        <strong>{`${selectedCandidate?.firstName} ${selectedCandidate?.lastName}`}</strong>
                      </h6>
                    </Col>
                    <Col lg={7} sm={12}>
                      <div className="text-end">
                        {activeTab?.toString() !== "4" && (
                          <>
                            <ButtonOutlined
                              onClick={moveToTalentPool}
                              loading={loading}
                            >
                              Add to Talent Pool
                            </ButtonOutlined>
                          </>
                        )}

                        <span className="mx-2">
                          <ButtonOutlined
                            primary
                            onClick={() => {
                              setShowComponent("onboard");
                              onboardCandidate(selectedCandidate);
                            }}
                          >
                            On Board
                          </ButtonOutlined>
                        </span>

                        {activeTab?.toString() !== "4" && (
                          <>
                            <Select
                              defaultValue="Move to"
                              onChange={(val) => handleSelectChange(val)}
                              options={transformedSteps}
                            />
                          </>
                        )}
                      </div>
                    </Col>
                    <SecondSubMenu
                      item={selectedCandidate}
                      jobList={jobList}
                      processList={processList}
                      getProcess={getProcess}
                    />
                  </Row>
                </div>
              </CustomizeTabPanel>
            )}
            {isEmpty(selectedCandidate) && (
              <div className="mt-5">
                <Empty />
              </div>
            )}
          </Grid>
        </>
      )}
      {localCandidateList?.length === 0 && (
        <div className="w-100 text-center mt-5">
          <Empty />
        </div>
      )}
    </Grid>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(VerticalTabs);
