import { CheckCircleFilled } from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  message,
} from "antd";
import { CALL_API } from "common/API";
import COLORS from "common/Colors";
import { LEAVE_ICON, STATUS_CODE } from "common/Constants";
import { concat, includes, orderBy, reduce } from "lodash";
import AttendanceBarChart from "molecules/AttendanceBarChart";
import DepartmentStaffLeaveBalance from "molecules/DepartmentStaffLeaveBalance";
import LeaveDonutChart from "molecules/LeaveDonutChart";
import LocationBasedPresentStaff from "molecules/LocationBasedPresentStaff";
import moment from "moment";
import LeaveFormModel from "organisms/LeaveFormModel";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { getMySqlDate } from "utils/Date";
import Filter from "../assets/Filter.svg";

const StyledRow = styled(Row)`
  padding-top: 20px;
`;

const StyledLeaveRow = styled(Row)`
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 5px;
  height: 100%;
  margin: 20px;
`;

const StyledChartContainer = styled.div`
  padding: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 5px !important;
  padding-bottom: 25px;
  margin: 0px !important;
`;

const StyledForm = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .select {
    width: 150px;
  }
`;
const ButtonOutlined = styled(Button)`
  padding: 5px 20px;
  border: 1px solid ${COLORS.PRIMARY};
  border-radius: 5px;
  background: transparent;
  font-weight: normal;
  color: ${COLORS.PRIMARY};
  white-space: nowrap;

  &:hover {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
  }
  &:focus {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
  }
  @media (max-width: 900px) {
    margin: 10px 0px;
  }
`;

const StyledTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #141414;
  margin-right: auto;
`;
const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #00000024;
    border-radius: 10px;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 600;
    font-size: 0.9rem;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
  .ant-table-thead > tr > th::before {
    display: none !important;
  }

  .ant-table-cell-row-hover {
    background: #e3e8fc !important;
  }
`;

function ManagerDashboard({
  ClientId,
  selectedDepartmentId,
  userRoleId,
  departmentList,
  roleList,
  AllDepartmentList,
  Menulist,
  leaveCategoryList,
  userList,
  updateSuccessModal,
  leaveList,
  setLeaveList,
}) {
  const [leaveCatgeory, setLeaveCatgeory] = useState([]);
  const [displayTaskLoader, setDisplayTaskLoader] = useState(false);
  const [displayLeaveModal, toggleDisplayLeaveModal] = useState(false);
  const [leaveDetails, setLeaveDetails] = useState({});
  const [leaveID, setLeaveID] = useState();
  const [havingCancel, sethavingCancel] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [displayLeaveReportLoader, toggleLeaveReportLoader] = useState(false);
  const [staffDepartmentLeaveModal, toggleStaffDepartmentLeaveModal] =
    useState(false);
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = useState("");
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [userLeaves, setUserLeaves] = useState([]);
  const [CSVDepartmentLeave, setCSVDepartmentLeave] = useState([]);
  const csvLinkDepartment = useRef();

  const [form] = Form.useForm();

  const containerRef = useRef(null);

  const [leaveSummary, setLeaveSummary] = useState({});

  const filterRoleList = roleList?.filter(
    (item) => item?.roleId === userRoleId
  );

  const filterDepartmentId = filterRoleList[0]?.department;
  const filterDepartment = AllDepartmentList?.filter((item) =>
    filterDepartmentId?.includes(item?.department_id)
  );

  useEffect(() => {
    let leaveStatistics = {};
    leaveCategoryList.forEach((category) => {
      const isDepartmentFiltered = category.departmentId.some((deptId) =>
        filterDepartment.some((dept) => dept.department_id === deptId)
      );  
      if (isDepartmentFiltered) {
        leaveStatistics[category.leave_category_id] = {
          available: 0,
          consumed: 0,
        };
      }
    });
    
    const approvedLeaves = leaveCatgeory.filter((item) => {
      const isDepartmentFiltered = filterDepartment.some(
        (dept) => dept.department_id === item.department_id
      );

      return isDepartmentFiltered && item.status_id === "5";
    });

    approvedLeaves.forEach((leave) => {
      const leaveCategoryName = parseInt(leave?.leave_category_id);
      leaveStatistics[leaveCategoryName].consumed += parseFloat(
        leave.number_of_days
      );
    });

    const updatedLeaveData = leaveCategoryList.map((category) => {
      const categoryName = category.leave_category_name;
    
      const isDepartmentFiltered = category.departmentId.some((deptId) =>
        filterDepartment.some((dept) => dept.department_id === deptId)
      );
    
      if (!isDepartmentFiltered) {
        return null;
      }
    
      const userCount = userList.filter((user) => {
        const userDepartments = Array.from(
          new Set(filterDepartment.flatMap((dept) => dept.department_id))
        );
    
        const flatValues = Object.values(user?.userdepartmentid || {}).flat();
    
        const uniqueValues = [...new Set(flatValues)];
    
        const hasAnyValue = uniqueValues.some((value) =>
          userDepartments.includes(value)
        );
    
        if (!hasAnyValue) {
          return false;
        }
    
        const eligibleGender = category.eligible.toLowerCase();
        if (eligibleGender === "both") {
          return true;
        } else {
          const userGender = user.gender.toLowerCase();
          return eligibleGender === userGender;
        }
      }).length;
    
      return {
        categoryName: category.leave_category_name,
        id: category.leave_category_id,
        data: [
          {
            type: "available",
            value:
              userCount * category.number_of_leaves -
                leaveStatistics[category.leave_category_id]?.consumed || 0,
          },
          {
            type: "consumed",
            value: leaveStatistics[category.leave_category_id]?.consumed || 0,
          },
        ],
      };
    }).filter(Boolean); 
    
    setLeaveSummary(updatedLeaveData);
    setUserLeaves(updatedLeaveData);
  }, [leaveCatgeory, userList, leaveCategoryList]);

  const openDetailWindow = (name, id) => {
    setSelectedLeaveTypeId(id);
    setSelectedLeaveType(`Department's ${name} Balance`);

    toggleStaffDepartmentLeaveModal(true);
  };
  useEffect(() => {
    getLeaveList();
    getLeaveListCategories();
  }, []);

  function getDates() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const startDate = new Date(currentYear - 2, 0, 1);
    const endDate = new Date(currentYear + 1, 11, 31);

    return {
      startDate,
      endDate,
    };
  }

  const dates = getDates();

  const ApproveOk = async (reason) => {
    return new Promise(async (resolve) => {
      await approveRejectCancelLeave2(5, reason, havingCancel);
      resolve();
    }).catch((err) => console.log("[ApproveOk] Error-->", err));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setLoading(true);
    ApproveOk();
  };

  const getLeaveList = async () => {
    return new Promise(async (resolve, reject) => {
      setLeaveList([]);

      if (selectedDepartmentId) {
        setDisplayTaskLoader(true);
        const { code, leaves = [] } = await CALL_API(
          `leave-list/${ClientId}`,
          "post",
          {
            status_id: "4",
            department_id: selectedDepartmentId,
            start_date: dates.startDate,
            end_date: dates.endDate,
          }
        );

        setDisplayTaskLoader(false);
        if (
          includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)
        ) {
          setLeaveList(leaves.reverse());

          resolve(leaves);
        } else {
          reject([]);
        }
      } else {
        reject();
      }
    });
  };

  const getLeaveListCategories = async () => {
    return new Promise(async (resolve, reject) => {
      setLeaveCatgeory([]);

      if (selectedDepartmentId) {
        toggleLeaveReportLoader(true);
        const { code, response = [] } = await CALL_API(
          `employee/allleave`,
          "post",
          {
            clientId: ClientId,
            year: new Date().getFullYear().toString(),
          }
        );

        toggleLeaveReportLoader(false);
        if (
          includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)
        ) {
          setLeaveCatgeory(response);

          resolve(response);
        } else {
          reject([]);
        }
      } else {
        reject([]);
      }
    });
  };

  const approveRejectCancelLeave2 = async (
    statusId,
    declined_reason = "",
    is_leave_cancel_request
  ) => {
    return new Promise(async (resolve) => {
      const { code } = await CALL_API(
        `approve-reject-cancel-leave/${ClientId}`,
        "post",
        {
          departmentId: selectedDepartmentId,
          user_leave_id: leaveID,
          status_id: statusId.toString(),
          declined_reason,
          is_cancel_request: is_leave_cancel_request,
          start_date: leaveDetails?.new_start_date
            ? getMySqlDate(leaveDetails?.new_start_date)
            : getMySqlDate(leaveDetails?.start_date),
          end_date: leaveDetails?.new_end_date
            ? getMySqlDate(leaveDetails?.new_end_date)
            : getMySqlDate(leaveDetails?.end_date),
        }
      );

      if (code === STATUS_CODE.SUCCESS) {
        setLoading(false);
        setIsModalOpen(false);
        updateSuccessModal({
          visibility: true,
          message: "Approved",
          Sucessicon: (
            <div className="d-flex justify-content-center align-itemw-center">
              <CheckCircleFilled style={{ color: "green" }} />
            </div>
          ),
        });
        getLeaveList();
      } else {
        message.error(`Something went wrong. Please try again.`);
      }
      resolve();
    });
  };

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 10,
    showSizeChanger: false,
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
    },
    {
      title: "Employee ID",
      dataIndex: "employeeid",
      key: "employeeid",
      width: "7%",
    },

    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Start Date - Day",
      dataIndex: "start_date",
      key: "start_date",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_start_date === "null" ||
          record.new_start_date === "" ||
          record.new_start_date === null
        ) {
          const dateFormat = moment(record.start_date).format("DD MMM YYYY");
          const getdayAlone = moment(record.start_date).format("dddd");
          return `${dateFormat} - ${getdayAlone}`;
        } else {
          const dateFormat = moment(record.new_start_date).format(
            "DD MMM YYYY"
          );
          const getdayAlone = moment(record.new_start_date).format("dddd");

          return `${dateFormat} - ${getdayAlone}`;
        }
      },
    },

    {
      title: "End Date - Day",
      dataIndex: "end_date",
      key: "end_date",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_end_date === "null" ||
          record.new_end_date === "" ||
          record.new_end_date === null
        ) {
          const dateFormat = moment(record.end_date).format("DD MMM YYYY");
          const getdayAlone = moment(record.end_date).format("dddd");

          return `${dateFormat} - ${getdayAlone}`;
        } else {
          const dateFormat = moment(record.new_end_date).format("DD MMM YYYY");
          const getdayAlone = moment(record.new_end_date).format("dddd");

          return `${dateFormat} - ${getdayAlone}`;
        }
      },
    },
    {
      title: "Days",
      dataIndex: "number_of_days",
      key: "number_of_days",
      width: "7%",
      render: (_, record) => {
        if (
          record.new_number_of_days === null ||
          record.new_number_of_days === "" ||
          record.new_number_of_days === "null"
        ) {
          return record.number_of_days;
        } else {
          return record.new_number_of_days;
        }
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leave_category_name",
      key: "leave_category_name",
      width: "10%",
    },
    {
      title: "Amended Leave",
      dataIndex: "having_cancel_request",
      key: "having_cancel_request",
      width: "10%",

      render: (having_cancel_request) => {
        if (having_cancel_request === "1") {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
    {
      title: "Applied On",
      dataIndex: "created_at",
      key: "created_at",
      width: "10%",
      render: (_, record) => {
        const dateFormat = moment(record.created_at).format("DD MMM YYYY");

        return `${dateFormat}`;
      },
    },
  ];

  // if (
  //   Menulist?.find(
  //     (item) =>
  //       item?.key === "allapprovals" &&
  //       item?.subMenu?.find(
  //         (subItem) => subItem?.key === "approvals" && subItem?.permission.edit
  //       )
  //   )
  // ) {
  //   columns.push({
  //     title: "Action",
  //     dataIndex: "address",
  //     key: "address",
  //     width: "10%",
  //     render: (_, record) => (
  //       <>
  //         <Tooltip title="View Leave">
  //           <EyeFilled
  //             className="action-button"
  //             onClick={() => viewLeave(record, true)}
  //           />
  //         </Tooltip>
  //         {(userRoleId === 1 || userRoleId === 6) && (
  //           <Tooltip title="Remind HOD & Assistant HOD" placement="left">
  //             <BellFilled
  //               className="action-button"
  //               onClick={() => remindSuperior(record)}
  //             />
  //           </Tooltip>
  //         )}
  //       </>
  //     ),
  //   });
  // }

  const departmentOptions = concat(
    orderBy(filterDepartment, [(val) => val.department_id], ["asc"]).map(
      (dept) => ({
        value: dept.department_id,
        label: dept.department_name,
      })
    )
  );

  const filterleavereport = selectedDepartment
    ? leaveList.filter(
        (item) =>
          item.status_id === "4" && item.department_id == selectedDepartment
      )
    : leaveList;

  const handleChange = (value) => {
    setSelectedDepartment(value);
  };

  useEffect(() => {
    setSelectedDepartment(null);
  }, []);

  const viewLeave = (leaveObject, isViewOnly = false) => {
    toggleDisplayLeaveModal(true);
    setLeaveDetails({
      ...leaveObject,
      isViewOnly,
    });
  };

  const remindSuperior = (leaveRecord) => {
    const { confirm } = Modal;
    confirm({
      icon: "",
      closable: true,
      content:
        "Do you want to send reminder notification to HOD & Assistant HOD?",
      async onOk() {
        const { user_leave_id, user_id } = leaveRecord;
        const { code } = await CALL_API(
          "leave-notification-to-hod-assistant-hod",
          "post",
          {
            user_leave_id,
            user_id,
          }
        );
        if (code === STATUS_CODE.SUCCESS) {
          message.success(`Notification has been sent.`);
        } else {
          message.error(`Something went wrong. Please try again.`);
        }
      },
      onCancel() {},
    });
  };

  const CSVHeaders = [
    { label: "Employee ID" },
    { label: "Name" },
    { label: "Available Leaves" },
    { label: "Consumed Leaves" },
    { label: "Total Leaves" },
  ];
  const prepareCSV = async (event, done) => {
    if (userLeaves.length > 0) {
      setCSVDepartmentLeave(
        reduce(
          userLeaves,
          function (result, leave) {
            result.push({
              "Leave Category ID": leave.id,
              "Leave Category Name": leave.categoryName,
              "Available Leaves": leave.data[0].value - leave.data[1].value,
              "Consumed Leaves": leave.data[1].value,
              "Total Leaves": leave.data[0].value,
            });
            return result;
          },
          []
        )
      );

      setTimeout(() => {
        csvLinkDepartment.current.link.click();
      });
    } else {
      console.log("No data available to download");
    }
  };

  return (
    <>
      <>
        <StyledRow gutter={16}>
          <Col md={24} sm={24} xs={24} xl={12}>
            <AttendanceBarChart />
          </Col>

          <Col md={24} sm={24} xs={24} xl={12}>
            <LocationBasedPresentStaff />
          </Col>

          {Menulist?.find(
            (item) =>
              item?.key === "allapprovals" &&
              item?.subMenu?.find(
                (subItem) =>
                  subItem?.key === "approvals" && subItem?.permission.view
              )
          ) && (
            <Col
              md={24}
              sm={24}
              xs={24}
              xl={24}
              ref={containerRef}
              className="mt-4"
            >
              <StyledChartContainer>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <StyledTitle>
                    Waiting for Approval {`(${filterleavereport?.length})`}
                  </StyledTitle>
                  <StyledForm
                    form={form}
                    name="approval_form"
                    layout="inline"
                    initialValues={{
                      department_id:
                        departmentOptions.length > 0
                          ? departmentOptions[0].value
                          : "",
                    }}
                  >
                    <Select
                      className="select"
                      name="department_id"
                      placeholder={
                        <>
                          <img src={Filter} alt="filter" />{" "}
                          <span className="Status-placeholder">Department</span>
                        </>
                      }
                      options={departmentOptions}
                      onChange={handleChange}
                      value={selectedDepartment}
                      getPopupContainer={() => containerRef.current}
                    />
                  </StyledForm>
                </div>
                <Spin spinning={displayTaskLoader}>
                  <StyledTable
                    dataSource={filterleavereport}
                    columns={columns}
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? "" : "non-white"
                    }
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          if (
                            Menulist?.find(
                              (item) =>
                                item?.key === "allapprovals" &&
                                item?.subMenu?.find(
                                  (subItem) =>
                                    subItem?.key === "approvals" &&
                                    subItem?.permission.edit
                                )
                            )
                          ) {
                            viewLeave(record, true);
                          }
                        },
                      };
                    }}
                    rowKey="user_leave_id"
                    pagination={
                      filterleavereport.length > 10 ? paginationOptions : false
                    }
                  />
                </Spin>
                <LeaveFormModel
                  isModalVisible={displayLeaveModal}
                  closeModal={() => toggleDisplayLeaveModal(false)}
                  userRoleId={userRoleId}
                  leaveDetails={leaveDetails}
                  toggleDisplayLeaveModal={toggleDisplayLeaveModal}
                  getLeaveList={getLeaveList}
                  setIsModalOpen={setIsModalOpen}
                  setLeaveID={setLeaveID}
                  sethavingCancel={sethavingCancel}
                  ClientId={ClientId}
                />
              </StyledChartContainer>
            </Col>
          )}
          <Col md={24} sm={24} xs={24} xl={24} className="mt-4">
            <Spin spinning={displayLeaveReportLoader}>
              <StyledLeaveRow gutter={24}>
                <Col span={12}>
                  <div>
                    <StyledTitle>Overall Leave Report</StyledTitle>
                  </div>
                </Col>
                <Col
                  span={12}
                  style={{ justifyContent: "flex-end", display: "flex" }}
                >
                  <ButtonOutlined
                    onClick={prepareCSV}
                    disabled={userLeaves.length === 0}
                  >
                    Export CSV file
                  </ButtonOutlined>
                  <CSVLink
                    header={CSVHeaders}
                    data={CSVDepartmentLeave}
                    filename={"Department Leave List.csv"}
                    asyncOnClick={true}
                    ref={csvLinkDepartment}
                  />
                </Col>
                {Array.isArray(leaveSummary) &&
                  leaveSummary.map((value) => (
                    <Col
                      md={24}
                      sm={24}
                      xs={24}
                      xl={12}
                      key={value.categoryName}
                      onClick={() =>
                        openDetailWindow(value.categoryName, value.id)
                      }
                      className="mt-2"
                    >
                      <LeaveDonutChart
                        iconName={LEAVE_ICON[7]}
                        title={`${value?.categoryName} - ${new Date()
                          .getFullYear()
                          .toString()}`}
                        data={value?.data}
                      />
                    </Col>
                  ))}
              </StyledLeaveRow>
            </Spin>
          </Col>
        </StyledRow>
      </>

      <Modal
        title="Approve leave"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            No
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Yes
          </Button>,
        ]}
      >
        <p>Are you sure you want to approve leave?</p>
      </Modal>

      <DepartmentStaffLeaveBalance
        displayModal={staffDepartmentLeaveModal}
        toggleModal={() => toggleStaffDepartmentLeaveModal(false)}
        leaveTypeId={selectedLeaveTypeId}
        selectedDepartmentId={selectedDepartmentId}
        leaveName={selectedLeaveType}
        leaveList={leaveList}
        leaveCategoryList={leaveCategoryList}
        filterDepartment={filterDepartment}
        leaveCatgeory={leaveCatgeory}
      />
    </>
  );
}

export default ManagerDashboard;
