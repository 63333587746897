import React, { useRef, useEffect } from "react";
import { Form, Input, Row, Col, DatePicker, Select } from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import { momentDate } from "utils/Date";
import { NON_EMPTY_REGEX, NON_CHARACTER, VALIDATE_CHARACTER, TIMEZONELIST, countryList } from "common/Constants";

const Styleddiv = styled.div`
  .ant-form-item-label > label {
    font-weight: bold;
  }
`;

const EditInformation = ({ title, details, setValues, viewOnly, setsubmit }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const {
      name = title === "Add Location"
        ? details.location_name
        : title === "holiday"
        ? details.holiday_name
        : title === "break"
        ? details.breakName
        : details.department_name,
      location_latitude = details.latitude,
      location_longitude = details.longitude,
      start_date = details.start_date,
      end_date = details.end_date,
      country = details?.country,
      usertimezone = details?.usertimezone
    } = details;
    form.setFieldsValue({
      name: name,
      location_latitude: location_latitude,
      location_longitude: location_longitude,
      start_date: moment(start_date),
      end_date: moment(end_date),
      country: country,
      usertimezone: usertimezone
    });
    setValues(form.getFieldValue());
  }, [details]);

  const containerRef = useRef(null);

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  const handleFormValuesChange = (changedValues, allValues) => {
    setsubmit(true);
    setValues(allValues);
  };

  return (
    <Styleddiv>
      <Form
        form={form}
        name="AddDepartment"
        layout="vertical"
        disabled={viewOnly}
        initialValues={{
          name: "",
          latitude: "",
          longtitude: "",
          start_date: momentDate(new Date()),
          end_date: "",
          usertimezone: "",
          country:""
        }}
        onValuesChange={handleFormValuesChange}
      >
        <div className="styled-card">
        {title === "Add Location" && (
                  <Form.Item
                    name="country"
                    label="Country"
                    rules={[
                      { required: true, message: "Please select country" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"

                    >
                      {countryList?.map((country, index) => (
                        <Select.Option
                          key={index}
                          value={country}
                          label={country}
                        >
                          {country}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
        )}
          <Form.Item
            label={
              title === "Add Location"
                ? "Location Name"
                : title === "holiday"
                ? "Holiday Name"
                : title === "break"
                ? "Break Name"
                : "Department Name"
            }
            name="name"
            rules={[
              {
                required: true,
                message:
                  title === "Add Location"
                    ? "Please enter location name"
                    : title === "holiday"
                    ? "Please enter holiday name"
                    : title === "break"
                    ? "Please enter Break name"
                    : "Please enter department name",
              },
              {
                validator: (rule, value) => {
                  if (value && !VALIDATE_CHARACTER.test(value)) {
                    return Promise.reject(
                      title === "Add Location"
                        ? "Location Name should not contain special characters"
                        : title === "holiday"
                          ? "Holiday Name should not contain special characters"
                          : title === "break"
                            ? "Break Name should not contain special characters"
                            : "Department Name should not contain special characters"
                    );
                  }
                  return Promise.resolve();
                },              
              },
            ]}
          >
            <Input
              placeholder={
                title === "Add Location"
                  ? "Location Name"
                  : title === "holiday"
                  ? "Department name"
                  : title === "break"
                  ? "Break Name"
                  : "Department name"
              }
              maxLength={30}
            />
          </Form.Item>
          {title === "Add Location" && (
            <>
            <Form.Item
                    name="usertimezone"
                    label="Time Zone"
                    rules={[
                      { required: true, message: "Please select Time zone" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"

                    >
                      {TIMEZONELIST?.map((item, index) => (
                        <Select.Option
                          key={index}
                          value={item}
                          label={item}
                        >
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  
                  </Form.Item>
              <Form.Item
                label="Latitude"
                name="location_latitude"
                rules={[
                  {
                    required: true,
                    pattern: NON_CHARACTER,
                    message: "Please enter latitude!",
                  },
                ]}
              >
                <Input placeholder="Enter latitude" maxLength={20} />
              </Form.Item>
              <Form.Item
                label="Longitude"
                name="location_longitude"
                rules={[
                  {
                    required: true,
                    pattern: NON_CHARACTER,
                    message: "Please enter longitude!",
                  },
                ]}
              >
                <Input placeholder="Enter longitude" maxLength={20} />
              </Form.Item>
            </>
          )}

          {title === "holiday" && (
            <>
              <Row>
                <Col xs={24} xl={24} ref={containerRef}>
                  <Form.Item
                    label="Start date"
                    name="start_date"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Please enter start date!",
                      },
                      () => ({
                        validator(rule, value) {
                          const date = moment(value);
                          return date.isValid()
                            ? Promise.resolve()
                            : Promise.reject();
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      className="w-100"
                      format="DD-MM-YYYY"
                      onChange={validateDateRange}
                      placeholder="Select start date"
                      getPopupContainer={() => containerRef.current}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} ref={containerRef}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.start_date !== currentValues.start_date
                    }
                  >
                    {() => (
                      <Form.Item
                        label="End date"
                        name="end_date"
                        rules={[
                          {
                            required: true,
                            pattern: NON_EMPTY_REGEX,
                            message: "Please enter start date!",
                          },
                          () => ({
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          className="w-100"
                          format="DD-MM-YYYY"
                          placeholder="Select end date"
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Form>
    </Styleddiv>
  );
};

export default EditInformation;
