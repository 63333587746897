import React from "react";
import { Row, Steps } from "antd";
import styled from "@emotion/styled";
import { CheckOutlined } from "@ant-design/icons";
const { Step } = Steps;
const Styleddiv = styled(Row)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img,
  svg {
    vertical-align: unset;
    font-size: 35px;
    background: #E3E8FC;
    border-radius: 50px;
    border: 1px solid #0d6efd ;
    padding: 8px;
  }
  .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    // margin-top: 10px;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    margin-top: 0px;
    color: #096dd9;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    // margin-top: 10px;
    color: #0d6efd;
  }
  .icon-color {
    color: #0d6efd;
  }
  .ant-steps {
    /* width: 450px; */
    box-sizing: border-box;
    margin: 0;
    padding: 10px;
    margin-left: 5%;
    margin-right: 5%;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    /* line-height: 1.5715; */
    list-style: none;
    font-feature-settings: 'tnum';
    display: flex;
    font-size:0;
    font-weight: 500;
    text-align: initial;
}

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #E3E8FC;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    color: #0d6efd;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #0d6efd;
}
/* .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #26A4FF;
    font-size: 16px;
    font-weight: bold;
  } */
`;
const StepsProgress = ({ step, stepdata }) => {
  return (
    <>
      <Styleddiv className="">
        <Steps current={step} labelPlacement="vertical">
          {stepdata.map((item, index) => (
            <Step
              title={item.title}
              key={item.title}
              icon={
                step > index ? <CheckOutlined className="icon-color" /> : null
              }
            />
          ))}
        </Steps>
      </Styleddiv>
    </>
  );
};
export default StepsProgress;
