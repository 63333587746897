import React, { useState, useEffect } from "react";
import { Row, Col, Switch, Checkbox } from "antd";
import { CALL_API } from "common/API";
import { bindActionCreators } from "redux";
import {
  toggleProcessingModal,
  toggleSuccessModal,
  setLocationList,
  setRoleList,
} from "redux/actions";
import { connect } from "react-redux";
import {
  getLocations,
  getClientid,
  getRoleList,
  getAllDepartmentList,
} from "redux/selectors";
import { STATUS_CODE, VALIDATE_CHARACTER } from "common/Constants";
import EditSelectTable from "molecules/EditSelectTable";
import EditInformation from "molecules/EditInformation";
import TabsList from "molecules/TabsList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormModal from "./FormModal";

function EditLocationModal({
  isModalVisible,
  closeModal,
  getLocationList,
  LocationDetails,
  saveLocationList,
  ClientId,
  roleList,
  departmentList,
  //need for delete
  deleteLocation,
  viewOnly,
  saveRoleList
}) {

  const fetchMasterData = async (fetch_master_data) => {
    const { code, locations, roleList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        saveLocationList(locations);
        saveRoleList(roleList);
      }
    }
  };

  const [selectDepartmentId, setSelectDepartmentId] = useState([]);
  const [values, setValues] = useState({});
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [create, setCreate] = useState(false);
  const [submit, setsubmit] = useState(false);
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [selectAllDepartmentsChecked, setSelectAllDepartmentsChecked] =
    useState(false);
  const [selectAllRolesChecked, setSelectAllRolesChecked] = useState(false);
  useEffect(() => {
    setValues({ name: LocationDetails?.location_name, location_latitude: LocationDetails?.latitude, location_longitude:LocationDetails?.longitude, country: LocationDetails?.country, usertimezone: LocationDetails?.usertimezone });
    setSelectedRoleId(LocationDetails.role_id);
    setSelectDepartmentId(LocationDetails.department_id);
  }, [LocationDetails]);

  useEffect(() => {
    setSelectAllDepartmentsChecked(selectDepartmentId?.length === departmentList?.length);
    setSelectAllRolesChecked(selectedRoleId?.length === roleList?.length);
  }, [selectDepartmentId, departmentList, selectedRoleId, roleList]);

  const rolecolumns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "roleId",
      key: "roleId",
      width: "10%",
      align: "center",
      render: (roleId) => {
        const check = selectedRoleId?.includes(roleId) ? true : false;
        return (
          <Switch
            disabled={viewOnly}
            size="small"
            defaultChecked
            checked={check}
            onChange={(checked) => getRoleId(roleId, checked)}
          />
        );
      },
    },
  ];

  const getRoleId = (role_id, checked) => {
    const newRoleId = checked
      ? [...selectedRoleId, role_id]
      : selectedRoleId.filter((item) => item !== role_id);
    setSelectedRoleId(newRoleId);
    setsubmit(true);
  };

  const handleSelectAllRoleChange = (e) => {
    const checked = e.target.checked;
    setSelectAllRolesChecked(checked);
    const allRoleIds = roleList.map((item) => item.roleId);
    setSelectedRoleId(checked ? allRoleIds : []);
    setsubmit(true);
  };

  const departmentcolumns = [
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "department_id",
      key: "department_id",
      width: "10%",
      align: "center",
      render: (department_id) => {
        const check = selectDepartmentId?.includes(department_id)
          ? true
          : false;
        return (
          <Switch
            disabled={viewOnly}
            checked={check}
            size="small"
            onChange={(checked) => getDepartmentId(department_id, checked)}
          />
        );
      },
    },
  ];

  const getDepartmentId = (department_id, checked) => {
    const newDepartmentId = checked
      ? [...selectDepartmentId, department_id]
      : selectDepartmentId.filter((item) => item !== department_id);
    setSelectDepartmentId(newDepartmentId);
    setsubmit(true);
  };

  const handleSelectAllDepartmentsChange = (e) => {
    const checked = e.target.checked;
    setSelectAllDepartmentsChecked(checked);
    const allDepartmentIds = departmentList.map((item) => item.department_id);
    setSelectDepartmentId(checked ? allDepartmentIds : []);
    setsubmit(true);
  };

  useEffect(() => {
    if (submit) {
      handleSubmit();
    }
  }, [selectedRoleId, selectDepartmentId, values])

  const handleSubmit = async () => {
    setCreate(true);
    const { name, location_latitude, location_longitude, country, usertimezone } = values;
    const containsSpecialCharacters = !VALIDATE_CHARACTER.test(name);
    if (containsSpecialCharacters) {
      return;
    }
    const { code } = await CALL_API(
      `owner-location/${ClientId}/${LocationDetails.location_id}`,
      "patch",
      {
        location_id: LocationDetails.location_id,
        location_name: name,
        latitude: location_latitude,
        longitude: location_longitude,
        role_id: selectedRoleId,
        department_id: selectDepartmentId,
        country: country,
        usertimezone: usertimezone
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      setCreate(false);
      fetchMasterData(true);
      getLocationList();
    }
  };


  const items = [
    {
      id: 1,
      label: "Basic",
      component: (
        <>
          {viewOnly ? (
            <Row className="styled-card">
              <Col>
                <b>Location Name</b> : {values.name}
              </Col>
            </Row>
          ) : (
            <EditInformation
              details={LocationDetails}
              setValues={setValues}
              create={create}
              setsubmit={setsubmit}
              title="Add Location"
            />
          )}
        </>
      ),
    },
    {
      id: 2,
      label: "Role",
      component: (
        <>
          {!viewOnly && (
            <div className="mb-3 d-flex justify-content-between">
              {roleList?.length > 0 && (
                <Checkbox
                  className=""
                  checked={selectAllRolesChecked}
                  onChange={handleSelectAllRoleChange}
                >
                  Select All
                </Checkbox>
              )}
            </div>
          )}
          <EditSelectTable
            columns={rolecolumns}
            tabledata={roleList}
            setOpenModal={() => setOpenRoleModal(true)}
            Name="Add Role"
          />
          <FormModal
            isModalVisible={openRoleModal}
            closeModal={() => setOpenRoleModal(false)}
            Name="Role"
          />
        </>
      ),
    },
    {
      id: 3,
      label: "Department",
      component: (
        <>
          {!viewOnly && (
            <div className="mb-3 d-flex justify-content-between">
              {departmentList?.length > 0 && (
                <Checkbox
                  className=""
                  checked={selectAllDepartmentsChecked}
                  onChange={handleSelectAllDepartmentsChange}
                >
                  Select All
                </Checkbox>
              )}
            </div>
          )}
          <EditSelectTable
            columns={departmentcolumns}
            tabledata={departmentList}
            setOpenModal={() => setOpenDepartmentModal(true)}
            Name="Add Department"
          />
          <FormModal
            isModalVisible={openDepartmentModal}
            closeModal={() => setOpenDepartmentModal(false)}
            Name="Department"
          />
        </>
      ),
    },
  ];
  return (
    <>
      {isModalVisible && (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => {
              closeModal(false);
            }}
          >
            Location{" "}
            <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className=" pb-0 pt-1">{values.name}</b>
          <TabsList items={items} />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  locationList: getLocations(state),
  ClientId: getClientid(state),
  roleList: getRoleList(state),
  departmentList: getAllDepartmentList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
      updateSuccessModal: toggleSuccessModal,
      saveLocationList: setLocationList,
      saveRoleList: setRoleList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(EditLocationModal);
