import React, { useEffect, useState } from "react";
import { Row, Col, Menu, Card, Avatar, Tooltip, Dropdown } from "antd";
import { connect } from "react-redux";
import {
  getUserName,
  getUserRoleId,
  getUnreadChatMessageList,
} from "redux/selectors";
import styled from "@emotion/styled";
import { startCase } from "lodash";
import COLORS from "common/Colors";
import ViewMore from "organisms/ViewMore";
import Mail from "../../src/assets/Mail.svg"


const StyledCard = styled(Card)`
.contract_style{
    position: absolute;
    top: 0px;
    left: 0px;
}
.contract_text{
  width: 120px;
  height: 24px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 550;
  font-size: 14px;
  color: #2D53DA;
  background-color: #E3E8FC;
  border-radius: 0px 0px 8px 0px;
}
  .ant-card-body {
    padding: 0px;
  }
  .ant-card-bordered{
      border-radius: 10px !important;
    }
  .vert_Icon{
    color: #2D53DA;
  }
  .first_row_styl {
    display: flex;
  }
  .thin-hr {
    border: none;
    height: 0.1px; 
    background-color: black; 
}

  .threedot {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
  .StyledImage {
    background-color: transparent;
    vertical-align: middle;
  } 
  .employee-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    gap:3px;
    box-shadow: 0px 4px 27px 0px #00000017;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .chat-container {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: ${COLORS.PRIMARY};
      translate: 50% -50%;
      .chat-icon {
        translate: 20px 55px;
      }
    }
    .icon {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name {
      font-size: 16px;
      font-weight: 600;
      margin-top: 4px;
    }
    .department {
      font-size: 14px;
      margin-top: 8px;
      place-self: flex-start;
      .bold {
        color: #636980 !important;
      }
    }
    .position {
      font-size: 14px;
      place-self: flex-start;
      .bold {
        font-weight: 400;
        color: #636980;
      }
    }
  }
`;
const StyledAvatar = styled(Avatar)`
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  font-size: 3rem;
  height: 74px;
  width: 74px;
  text-align: center;
  margin-top: 10px;
  .ant-avatar-string {
    margin-top: 1rem;
  }
`;
const StyledViewMenu = styled.div`
  width: 100px;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 0px;
  gap: 4px;

  .ant-dropdown-menu{
    border-radius: 8px;
    color:#1F243B;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    box-shadow: 0px 4px 27px 0px #00000030;
  }
`;

function EmployeeCard({
  id,
  name,
  Department,
  employeeid,
  ViewEmployee,
  data,
  deletedEmployee,
  profile,
  employeeType,
  rolename,
}) {
  const [updateProfile, setUpdatedProfile] = useState("");
 
  useEffect(() => {
    setUpdatedProfile(profile);
  }, [updateProfile]);

  return (
    <StyledCard>
      <div className="employee-card">
        <Row>
          <Col className="contract_style">
             <div className="contract_text text-center">{data?.employementtype}</div>
          </Col>
         
        </Row>
        {profile === null ||
          profile === "" ||
          profile === undefined ||
          profile == "null" ? (
          <StyledAvatar size="default">
            {startCase(name?.charAt(0))}
          </StyledAvatar>
        ) : (
          <StyledAvatar
            src={profile}
            className="StyledImage"
            size="default"
          ></StyledAvatar>
        )}
        <div className="name">
          {" "}
          <Tooltip title={name}>{name}</Tooltip>
        </div>
        <hr className="thin-hr w-100"></hr>
        <div className="position">
          <span className="bold">Employee ID</span>: {employeeid || "-"}
        </div>
        <div className="position">
          <span className="bold">Job Title</span>:{" "}
          {data?.jobtitle}
        </div>
        {/* /-------------------can be used future--------------------------/
        <div className="position">
          <span className="bold">Department</span>:{" "}
          {data?.specialization}
        </div> 
        /-----------------------------------------------------------------/ */}
        {/* <div className="position">
          <span className="bold">Type</span>:{" "}
          {data?.usertype}
        </div> */}
        <div className="position">
          <span className="bold"><img src={Mail}></img></span>{" "}
          <>{<ViewMore value={data?.userid} />}</>
        </div>
      </div>
    </StyledCard>
  );
}
const mapStateToProps = (state) => ({
  userName: getUserName(state),
  userRoleId: getUserRoleId(state),
  unreadChatMessageList: getUnreadChatMessageList(state),
});

export default connect(mapStateToProps, null)(EmployeeCard);
