import { useCallback, useRef } from "react";
import { useInfiniteQuery } from "react-query";
import { apiInstance } from "config/baseUrl";

export const useScrollFetch2 = (queryKey, url, options) => {
  const observer = useRef();
  const accessToken = window.localStorage.getItem("token");

  const fetcher = async (pageParam) => {
    const { endPoint, queryParam, limit = "", search = "", extra } = url || {};
    const pages = pageParam || 0;

    let params = new URLSearchParams();
    if (limit) params.append("limit", limit);
    if (search) params.append("search", search);
    params.append(queryParam, pages);
    if (extra) {
      for (let key in extra) {
        params.append(key, extra[key]);
      }
    }

    const constructedUrl = `${endPoint}&${params.toString()}`;

    return await apiInstance({
      method: "GET",
      url: constructedUrl,
      headers: {
        Tymeplusjwt: accessToken,
        // "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
  };

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading, refetch } =
    useInfiniteQuery({
      queryKey: [queryKey],
      queryFn: ({ pageParam }) => fetcher(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.response?.lastEvaluatedKey?.id === null) {
          return null;
        }
        if (lastPage?.response?.lastEvaluatedKey?.id) {
          return lastPage?.response?.lastEvaluatedKey?.id;
        }
        return null;
      },
      ...options,
      refetchOnWindowFocus: false,
      initialPageParam: 0,
    });

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading]
  );

  const isReloading = isFetching || isLoading;

  return { data, isReloading, isLoading, lastElementRef, refetch };
};
