import {
  Typography
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleSuccessModal } from "redux/actions";
import {
  getClientid,
  getMenuList,
  getUserInfo,
  getUserList,
  getUserRoleId
} from "redux/selectors";


import TabsList from "molecules/TabsList";
import BusinessTrip from "./BusinessTrip";
import Reimbursement from "./Reimbursement";

const HrTravelExpense = ({
  ClientId,
  userInfo,
  userList,
  updateSuccessModal,
  menuList,
  userRoleId
}) => {

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];
  const items = [
    {
      id: 1,
      label: "Business Trip",
      component: (
        <BusinessTrip
          ClientId={ClientId}
          userInfo={userInfo}
          userList={userList}
          updateSuccessModal={updateSuccessModal}
          Menulist={Menulist}
        />
      ),
    },
    {
      id: 2,
      label: "Reimbursement",
      component: (
        <Reimbursement
          ClientId={ClientId}
          userInfo={userInfo}
          userList={userList}
          updateSuccessModal={updateSuccessModal}
          Menulist={Menulist}
        />
      ),
    },
  ];

  return (
    <>
      <Typography.Title className="p-2" level={4}>
        Travel & Expense
      </Typography.Title>
      <TabsList
        items={items}
        //    handleTabSelect={handleTabSelect}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  userInfo: getUserInfo(state),
  userList: getUserList(state),
  menuList: getMenuList(state),
  userRoleId: getUserRoleId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSuccessModal: toggleSuccessModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HrTravelExpense);
