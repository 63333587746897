import { VALIDATE_CHARACTER } from "common/Constants"; 

export const EligibleList = [
  "Both",
  "Male",
  "Female",
];
const DoYouWant = [
  "Yes",
  "No"
]

export const getAddLeaveJson = (data) => {
  const AddLeaveForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          // Row 1
          [
            {
              id: 1,
              label: "Leave Name",
              type: "text",
              name: "leave_category_name",
              Placeholder: "Leave Name",
              value: data["leave_category_name"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Leave Name`,
                },
                {
                  validator: (rule, value, callback) => {
                    if (value && !VALIDATE_CHARACTER.test(value)) {
                      callback("Leave Name should not contain special characters");
                    } else {
                      callback();
                    }
                  },
                }
              ],
            },

          ],
          [
            {
              id: 2,
              label: "Number of Days",
              type: "number",
              name: "number_of_leaves",
              Placeholder: "Number of Leaves",
              value: data["number_of_leaves"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Number of Days`,
                },
              ],
            },

          ],
          [
            {
              id: 3,
              label: "Eligible",
              type: "radio",
              name: "eligible",
              Placeholder: "Select",
              value: "",
              options: EligibleList,
              validationRules: [
                {
                  required: true,
                  message: `Please select the eligible`,
                },
              ],
            },
          ],
          [
            {
              id: 4,
              label: "Do you want to include Saturdays in this leave?",
              type: "radio",
              name: "isSaturday",
              Placeholder: "Select",
              value: "",
              options: DoYouWant,
              validationRules: [
                {
                  required: true,
                  message: `Please select an option`,
                },
              ],
            },
          ],
          [
            {
              id: 4,
              label: "Do you want to include Sundays in this leave?",
              type: "radio",
              name: "weekend",
              Placeholder: "Select",
              value: "",
              options: DoYouWant,
              validationRules: [
                {
                  required: true,
                  message: `Please select an option`,
                },
              ],
            },
          ],
          [
            {
              id: 5,
              label: `Do you want to include the "Holidays" you provide in this leave?`,
              type: "radio",
              name: "holiday",
              Placeholder: "Select",
              value: "",
              options: DoYouWant,
              validationRules: [
                {
                  required: true,
                  message: `Please select an option`,
                },
              ],
            },
          ],
          [
            {
              id: 2,
              label: "Next",
              type: "button",
              position: "end",
              // background: "Orange",steps
              textColor: "white",
              event: "submit",
            },
          ],

        ],
      },
    ],
  }

  return {
    AddLeaveForm,
  }
}
