import React, {
  useState, useEffect
} from "react";
import { Switch, message, Button, Checkbox, } from "antd";
import { connect } from "react-redux";
import {
  toggleProcessingModal,
  toggleSuccessModal,
  setAllDepartmentList,
  setRoleList,
} from "redux/actions";
import {
  getLocations,
  getClientid,
  getAllDepartmentList,
  getUserMenuList,
  getRoleList
} from "redux/selectors";
import { bindActionCreators } from "redux";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import { getAddRoleJson } from "JsonFormat/AddRoleJson";
import StepsProgress from "./StepsProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SelectTable from "molecules/SelectTable";
import RolesAndPermissions from "pages/RolesAndPermissions";
import InReview from "molecules/InReview";
import FormModal from "./FormModal";
import styled from "@emotion/styled";

const StyledButtons = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 150px;
  float: right;
`;

function AddRole({
  isModalVisible,
  closeModal,
  getRoleList,
  clientId,
  saveRoleList,
  locationList,
  departmentList,
  userMenuList,
  roleList
}) {
  const fetchMasterData = async (fetch_master_data) => {
    const { code, roleList } = await CALL_API(
      `master-data/${clientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        saveRoleList(roleList);
      }
    }
  };

  const [data, setData] = useState({});
  const { AddRoleForm } = getAddRoleJson(data, roleList);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitForm = (e) => {
    setStep(1);
  };

  const [step, setStep] = useState(0);
  const [selectDepartmentId, setSelectDepartmentId] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState([]);
  const [menuList, setMenuList] = useState(userMenuList);
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [loading, setLoading] = useState(false)
  const [selectAllDepartmentChecked, setSelectAllDepartmentChecked] =
    useState(false);

  useEffect(() => {
    setSelectAllDepartmentChecked(selectDepartmentId.length === departmentList.length);
    setSelectAllChecked(selectedLocationId.length === locationList.length);
  }, [selectDepartmentId, selectedLocationId, departmentList, locationList]);

  const stepdata = [
    { id: 1, title: "Basic" },
    { id: 2, title: "Department" },
    { id: 3, title: "Location" },
    { id: 4, title: "Permission" },
    { id: 5, title: "Review" },
  ];

  const departmentcolumns = [
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "department_id",
      key: "department_id",
      width: "10%",
      align: "center",
      render: (department_id, record) => {
        return (
          <Switch
            size="small"
            checked={
              selectAllDepartmentChecked ||
              selectDepartmentId.includes(department_id)
            }
            onChange={(checked) => getDepartmentId(department_id, checked)}
          />
        );
      },
    },
  ];

  const getDepartmentId = (department_id, checked) => {
    const newDepartmentId = checked
      ? [...selectDepartmentId, department_id]
      : selectDepartmentId.filter((item) => item !== department_id);
    setSelectDepartmentId(newDepartmentId);
  };

  const handleSelectAllDepartment = (e) => {
    const checked = e.target.checked;
    setSelectAllDepartmentChecked(checked);
    const allDepartmentIds = departmentList.map((item) => item.department_id);
    setSelectDepartmentId(checked ? allDepartmentIds : []);
  };

  const locatincolumns = [
    {
      title: "Location Name",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id, record) => {
        return (
          <Switch
            size="small"
            checked={
              selectAllChecked || selectedLocationId.includes(location_id)
            }
            onChange={(checked) => getLocationId(location_id, checked)}
          />
        );
      },
    },
  ];

  const getLocationId = (location_id, checked) => {
    const newLocationId = checked
      ? [...selectedLocationId, location_id]
      : selectedLocationId.filter((item) => item !== location_id);
    setSelectedLocationId(newLocationId);
  };

  const handleSelectAllLocation = (e) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);
    const allLocationIds = locationList.map((item) => item.location_id);
    setSelectedLocationId(checked ? allLocationIds : []);
  };

  const onFinish = async () => {
    const { rolename } = data;
    setLoading(true)
    await CALL_API(`owner-roles/${clientId}`, "post", {
      rolename: rolename,
      department: selectDepartmentId,
      location: selectedLocationId,
      menuList: menuList,
    });
    setData({})
    setSelectDepartmentId([])
    setSelectedLocationId([])
    fetchMasterData(true);
    setLoading(false)
    closeModal(false);
    message.success("Role Created Successfully");
    setStep(0);
    getRoleList();
  };
 
  return (
    <>
      {isModalVisible && (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => closeModal(false)}
          >
            Role <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className="pb-0 pt-1">Add Role</b>
          <StepsProgress stepdata={stepdata} title="Add Role:" step={step} />
          <div className="styled-card">
            {step == 0 && (
              <>
                <GridBuilder
                  formStructureJson={AddRoleForm}
                  handleChange={handleFormChange}
                  formSubmit={handleSubmitForm}
                  initialValues={data}
                />
              </>
            )}
            {step == 1 && (
              <>
                {departmentList?.length > 0 && (
                  <Checkbox
                    className="mt-3"
                    checked={selectAllDepartmentChecked}
                    onChange={handleSelectAllDepartment}
                  >
                    Select All
                  </Checkbox>
                )}

                <SelectTable
                  step={step}
                  setStep={setStep}
                  columns={departmentcolumns}
                  tabledata={departmentList}
                  setOpenModal={() => setOpenDepartmentModal(true)}
                  Name="Add Department"
                />
                <FormModal
                  isModalVisible={openDepartmentModal}
                  closeModal={() => setOpenDepartmentModal(false)}
                  Name="Department"
                />
              </>
            )}
            {step == 2 && (
              <>
                {locationList?.length > 0 && (
                  <Checkbox
                    className="mt-3"
                    checked={selectAllChecked}
                    onChange={handleSelectAllLocation}
                  >
                    Select All
                  </Checkbox>
                )}

                <SelectTable
                  step={step}
                  setStep={setStep}
                  columns={locatincolumns}
                  tabledata={locationList}
                  setOpenModal={() => setOpenLocationModal(true)}
                  Name="Add Location"
                />
                <FormModal
                  isModalVisible={openLocationModal}
                  closeModal={() => setOpenLocationModal(false)}
                  Name="Location"
                />
              </>
            )}
            {step == 3 && (
              <RolesAndPermissions
                step={step}
                setStep={setStep}
                setMenuList={setMenuList}
                menuList={menuList}
                setsubmit={() => {}}
              />
            )}

            {step == 4 && (
              <InReview
                step={step}
                setStep={setStep}
                BasicInformation={data}
                department={selectDepartmentId}
                location={selectedLocationId}
                create={onFinish}
                loading={loading}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  locationList: getLocations(state),
  clientId: getClientid(state),
  allDepartmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  departmentList: getAllDepartmentList(state),
  userMenuList: getUserMenuList(state),
  roleList: getRoleList(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveAllDepartmentList: setAllDepartmentList,
      updateProcessingModal: toggleProcessingModal,
      updateSuccessModal: toggleSuccessModal,
      saveRoleList: setRoleList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddRole);
