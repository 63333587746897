import React from "react";
import Icon from "atoms/Icon";
import { ReadyState } from "react-use-websocket";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import crm from 'assets/productlogo/crm.svg'
import tymeplus from 'assets/productlogo/tymeplus.svg';
import tractask from 'assets/productlogo/tractask.svg';
import myaccount from 'assets/productlogo/myaccount.svg'
import timemoment from 'moment-timezone';
import moment from 'moment-timezone';

export const TIMEZONELISTS = timemoment.tz.names();

export const TIMEZONELIST = TIMEZONELISTS.filter(timezone => !moment.tz(timezone).isDST());


const ORIGIN = window.location.origin;

const LIVE_SOCKET = `wss://ml4805lrj5.execute-api.ap-southeast-1.amazonaws.com/Prod/`;
const TEST_SOCKET = `wss://cjrbo8oedg.execute-api.ap-south-1.amazonaws.com/Prod/`;
const DEV_SOCKET = `wss://cjrbo8oedg.execute-api.ap-south-1.amazonaws.com/Prod/`;
export const SERVICE_URL = `https://e2m7j7a7p0.execute-api.ap-southeast-1.amazonaws.com/Prod/`;
export const AI_BASE_URL = `https://mvff9fjrsc.execute-api.ap-south-1.amazonaws.com/dev/`;


export const SOCKET_URL =
  ORIGIN.indexOf("localhost") > -1
    ? TEST_SOCKET
    : ORIGIN.indexOf("test") > -1
      ? TEST_SOCKET
      : LIVE_SOCKET;


export const Products = [
  {
    name: "Tymeplus",
    url: "",
    logo: tymeplus,
    access: true
  },
  {
    name: "TracTask",
    url: `https://www.tractask.waila.co/?token=${window.localStorage.getItem('token')}`,
    logo: tractask,
    access: true
  },
  {
    name: "Marketing Tool",
    url: `https://www.crm.waila.co/?token=${window.localStorage.getItem('token')}`,
    logo: crm,
    access: true
  },
  {
    name: "My Account",
    url: `https://www.myaccount.waila.co/?token=${window.localStorage.getItem('token')}`,
    logo: myaccount,
    access: true
  },

]

export const NON_EMPTY_REGEX = /^(?!\s*$).+/;

export const EMAIL_REGEX = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export const NON_EMPTY_SINGLE_NUMBER = /\b[0-9]\b/;

export const NON_EMPTY_NUMBER = /^\d+$/;

export const NON_CHARACTER = /^[0-9\.\-\+]+$/;

//Validate symbol except space
export const VALIDATE_SYMBOL = /^[^<>*|/\\~#{}()[\],$^%&+=_@'?.!"]+$/g;

export const VALIDATE_CHARACTER = /^[A-Za-z\s]+$/;

export const PUNCH_ACTIONS = {
  PUNCH_IN: 1,
  PUNCH_OUT: 2,
  BREAK_START: 3,
  BREAK_END: 4,
};

export const InitalSteps = {
  title: '',
  steps: [
    { 0: 'New' }
  ]
}

export const STATUS = {
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
  ERROR: "ERROR",
  RECORD_NOT_FOUND: "RECORD_NOT_FOUND",
};

const dashboardMenu = {
  key: "dashboard",
  icon: <Icon name="dashboard" />,
  path: "dashboard",
  title: "Dashboard",
};

const adminDashboardMenu = {
  key: "dashboard",
  icon: <Icon name="dashboard" />,
  title: "Dashboard",
  subMenu: [
    {
      key: "dashboard",
      path: "dashboard",
      title: "Dashboard",
      icon: <Icon name="subdashboard" />,
    },
    {
      key: "trends",
      path: "trends",
      title: "Trends",
      icon: <Icon name="trends" />,
    },
    {
      key: "demographics",
      path: "demographics",
      title: "Demographics",
      icon: <Icon name="demographics" />,
    },
    {
      key: "productivity",
      path: "productivity",
      title: "Productivity",
      icon: <SettingsApplicationsIcon style={{ fontSize: "20px" }} />,
    },
  ],
};

const approvalMenu = {
  key: "approvals",
  icon: <Icon name="approvals" />,
  path: "approvals",
  title: "Approvals",
};

const calendarMenu = {
  key: "calendar",
  icon: <Icon name="calendar" />,
  path: "calendar",
  title: "Calendar",
};

const locationMenu = {
  key: "location",
  icon: <Icon name="location" />,
  path: "location",
  title: "Location",
};

const myCalendarMenu = {
  key: "calendar",
  icon: <Icon name="calendar" />,
  path: "calendar",
  title: "My Calendar",
};

const teamMenu = {
  key: "team",
  icon: <Icon name="team" />,
  path: "team",
  title: "Teams",
};

const myTeamMenu = {
  key: "team",
  icon: <Icon name="team" />,
  path: "team",
  title: "My Team",
};

const myDocumentMenu = {
  key: "mydocuments",
  icon: <Icon name="document" />,
  path: "mydocuments",
  title: "My Documents",
};

const taskMenu = {
  key: "task",
  icon: <Icon name="task" />,
  path: "task",
  title: "Task",
};

const myTaskMenu = {
  key: "mytask",
  icon: <Icon name="task" />,
  path: "mytask",
  title: "My Task",
};

const myLeaveReport = {
  key: "leavereport",
  icon: <Icon name="leave" />,
  path: "myleavereport",
  title: "My Leave History",
};

const reportMenu = {
  key: "reports",
  icon: <Icon name="report" />,
  title: "Reports",
  subMenu: [
    {
      key: "attendance-report",
      title: "Attendance",
      icon: <Icon name="attendance" />,
      subMenu: [
        {
          key: "attendance-report-daily",
          path: "attendance-report",
          title: "Daily",
          icon: <DoneAllIcon style={{ fontSize: "20px" }} />,
        },
        {
          key: "attendance-report-monthly",
          path: "attendance-report-monthly",
          title: "Monthly",
          icon: <CalendarMonthIcon style={{ fontSize: "20px" }} />,
        },
      ],
    },
    {
      key: "leave-report",
      path: "leave-report",
      title: "Leave",
      icon: <Icon name="leave" />,
    },
    {
      key: "break-report",
      path: "break-report",
      title: "Break",
      icon: <Icon name="break" />,
    },
    {
      key: "overtime-report",
      path: "overtime-report",
      title: "Overtime",
      icon: <Icon name="overtime" />,
    },
  ],
};

const ExceptionReport = {
  key: "Exception Reports",
  icon: <Icon name="exceptionreport" />,
  title: "Exception Reports",
  subMenu: [
    {
      key: "Late check-in",
      path: "Late-check-in",
      title: "Late check-in",
      icon: <Icon name="latecheckin" />,
    },
    {
      key: "Early check-out",
      path: "Early-check-out",
      title: "Early check-out",
      icon: <Icon name="earlycheckout" />,
    },
    {
      key: "Absent report",
      path: "Absent-report",
      title: "Absent report",
      icon: <Icon name="absentreport" />,
    },
  ],
};

const employeeMenu = {
  key: "employee",
  icon: <Icon name="employee" />,
  path: "employee",
  title: "Employee",
};

const departmentMenu = {
  key: "department",
  icon: <Icon name="department" />,
  path: "department",
  title: "Department",
};

const roleMenu = {
  key: "role",
  icon: <Icon name="role" />,
  path: "roles",
  title: "Role",
};
const shiftMenu = {
  key: "shift",
  icon: <Icon name="shift" />,
  path: "shift",
  title: "Shift",
};

export const HR_MENU = [
  dashboardMenu,
  approvalMenu,
  calendarMenu,
  teamMenu,
  reportMenu,
  ExceptionReport,
];

export const CEO_MENU = [
  dashboardMenu,
  approvalMenu,
  calendarMenu,
  teamMenu,
  reportMenu,
  ExceptionReport,
];

export const HOD_MENU = [
  dashboardMenu,
  approvalMenu,
  calendarMenu,
  teamMenu,
  taskMenu,
  reportMenu,
  ExceptionReport,
];

export const STAFF_MENU = [
  dashboardMenu,
  myCalendarMenu,
  myTeamMenu,
  myDocumentMenu,
  myTaskMenu,
  myLeaveReport,
];

export const ADMIN = [
  adminDashboardMenu,
  departmentMenu,
  locationMenu,
  employeeMenu,
  roleMenu,
  shiftMenu,
];

export const MENU = {
  1: HR_MENU,
  2: HOD_MENU,
  3: HOD_MENU,
  4: STAFF_MENU,
  5: ADMIN,
  6: CEO_MENU,
};

export const STATUS_CODE = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  INVALID_PAYLOAD: "INVALID_PAYLOAD",
  AUTHENTICATION_FAILED: "AUTHENTICATION_FAILED",
  AUTHENTICATION_SUCCESSFUL: "AUTHENTICATION_SUCCESSFUL",
  USER_DISABLED: "USER_DISABLED",
  VALID_SESSION: "VALID_SESSION",
  SAME_PASSWORD: "SAME_PASSWORD",
  INVALID_CODE: "INVALID_CODE",
  RECORD_NOT_FOUND: "RECORD_NOT_FOUND",
  INVALID_SESSION: "INVALID_SESSION",
  PASSWORD_UPDATED: "PASSWORD_UPDATED",
  RECORD_EXIST: "RECORD_EXIST",
  OUTSIDE_ALLOWED_PROXIMITY: "OUTSIDE_ALLOWED_PROXIMITY",
  PUNCH_IN_SUCCESSFUL: "PUNCH_IN_SUCCESSFUL",
  PUNCH_OUT_SUCCESSFUL: "PUNCH_OUT_SUCCESSFUL",
  BREAK_STARTED: "BREAK_STARTED",
  BREAK_ENDED: "BREAK_ENDED",
  SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
  TASK_SAVED: "TASK_SAVED",
  TASK_DELETED: "TASK_DELETED",
  LEAVE_EXCEED: "LEAVE_EXCEED",
  ANNUAL_LEAVE_AVAILABLE: "ANNUAL_LEAVE_AVAILABLE",
  SICK_LEAVE_AVAILABLE: "SICK_LEAVE_AVAILABLE",
  REASON: "SEND_REASON",
};

export const LEAVE_INFO = {
  AVAILABLE: "Available",
  CONSUMED: "Consumed",
};

export const USER_REPORT = {
  ONTIME: "OnTime",
  LATE: "Late",
  LEAVE: "Leave",
  EARLYEXIT: "Early Exit",
  ABSENT: "Absent",
};

export const LEAVE_ICON = {
  7: "calendar2",
  1: "medical_box",
  2: "medical_box",
  3: "medical_box",
  5: "pregnant_woman",
  6: "baby_carrier",
};

export const EXPENSE_STATUS = [
  { value: "All", label: "All" },
  { value: "Waiting", label: "Waiting" },
  { value: "Approved", label: "Approved" },
  { value: "Declined", label: "Declined" },
];

export const TYPE_OF_EXPENSE = [
  { value: "travel", label: "Travel" },
  { value: "others", label: "Others" },
];

 const Currencies = [
  "US Dollar",
  "Euro",
  "Japanese Yen",
  "British Pound",
  "Australian Dollar",
  "Canadian Dollar",
  "Swiss Franc",
  "Chinese Yuan",
  "Indian Rupee",
  "Brazilian Real",
  "Russian Ruble",
  "South African Rand",
  "Mexican Peso",
  "New Zealand Dollar",
  "Singapore Dollar",
  "Hong Kong Dollar",
  "Swedish Krona",
  "Norwegian Krone",
  "South Korean Won",
  "Turkish Lira"
  // Add more currencies as needed
];

export const TYPE_OF_CURRENCIES = Currencies.map(currency => ({
  value: currency,
  label: currency
}));

export const REPORT_DURATION_TYPES = [
  { value: 2, label: "Monthly Report" },
  { value: 1, label: "Custom Date" },
];

export const REPORT_DURATION_TYPES1 = [{ value: 2, label: "Monthly Report" }];

export const APPROVAL_FILTER_TYPES = [
  { value: 4, label: "All" },
  { value: 1, label: "Custom Date" },
  { value: 2, label: "Monthly" },
];

export const CHECKIN_REPORT_FILTER_TYPES = [
  { value: 1, label: "Custom Date" },
  { value: 2, label: "Monthly" },
];
export const MONTH_LIST = [
  { index: 1, value: "January", label: "January" },
  { index: 2, value: "February", label: "February" },
  { index: 3, value: "March", label: "March" },
  { index: 4, value: "April", label: "April" },
  { index: 5, value: "May", label: "May" },
  { index: 6, value: "June", label: "June" },
  { index: 7, value: "July", label: "July" },
  { index: 8, value: "August", label: "August" },
  { index: 9, value: "September", label: "September" },
  { index: 10, value: "October", label: "October" },
  { index: 11, value: "November", label: "November" },
  { index: 12, value: "December", label: "December" },
];

export const WEB_SOCKET_CONNECTION_STATUS = {
  [ReadyState.CONNECTING]: "Connecting",
  [ReadyState.OPEN]: "Open",
  [ReadyState.CLOSING]: "Closing",
  [ReadyState.CLOSED]: "Closed",
  [ReadyState.UNINSTANTIATED]: "Uninstantiated",
};

export const countryList = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of The",
  "Cook Islands",
  "Costa Rica",
  "Cote D'ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and The Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and The South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Eswatini",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan (ROC)",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe"
]


export const CurrenciesList = [
  {
    name: "US Dollar",
    symbol: "$",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "USD",
    namePlural: "US dollars"
  },
  {
    name: "Canadian Dollar",
    symbol: "CA$",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "CAD",
    namePlural: "Canadian dollars"
  },
  {
    name: "Euro",
    symbol: "€",
    symbolNative: "€",
    decimalDigits: 2,
    rounding: 0,
    code: "EUR",
    namePlural: "euros"
  },
  {
    name: "United Arab Emirates Dirham",
    symbol: "AED",
    symbolNative: "د.إ.‏",
    decimalDigits: 2,
    rounding: 0,
    code: "AED",
    namePlural: "UAE dirhams"
  },
  {
    name: "Afghan Afghani",
    symbol: "Af",
    symbolNative: "؋",
    decimalDigits: 0,
    rounding: 0,
    code: "AFN",
    namePlural: "Afghan Afghanis"
  },
  {
    name: "Albanian Lek",
    symbol: "ALL",
    symbolNative: "Lek",
    decimalDigits: 0,
    rounding: 0,
    code: "ALL",
    namePlural: "Albanian lekë"
  },
  {
    name: "Armenian Dram",
    symbol: "AMD",
    symbolNative: "դր.",
    decimalDigits: 0,
    rounding: 0,
    code: "AMD",
    namePlural: "Armenian drams"
  },
  {
    name: "Argentine Peso",
    symbol: "AR$",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "ARS",
    namePlural: "Argentine pesos"
  },
  {
    name: "Australian Dollar",
    symbol: "AU$",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "AUD",
    namePlural: "Australian dollars"
  },
  {
    name: "Azerbaijani Manat",
    symbol: "man.",
    symbolNative: "ман.",
    decimalDigits: 2,
    rounding: 0,
    code: "AZN",
    namePlural: "Azerbaijani manats"
  },
  {
    name: "Bosnia-Herzegovina Convertible Mark",
    symbol: "KM",
    symbolNative: "KM",
    decimalDigits: 2,
    rounding: 0,
    code: "BAM",
    namePlural: "Bosnia-Herzegovina convertible marks"
  },
  {
    name: "Bangladeshi Taka",
    symbol: "Tk",
    symbolNative: "৳",
    decimalDigits: 2,
    rounding: 0,
    code: "BDT",
    namePlural: "Bangladeshi takas"
  },
  {
    name: "Bulgarian Lev",
    symbol: "BGN",
    symbolNative: "лв.",
    decimalDigits: 2,
    rounding: 0,
    code: "BGN",
    namePlural: "Bulgarian leva"
  },
  {
    name: "Bahraini Dinar",
    symbol: "BD",
    symbolNative: "د.ب.‏",
    decimalDigits: 3,
    rounding: 0,
    code: "BHD",
    namePlural: "Bahraini dinars"
  },
  {
    name: "Burundian Franc",
    symbol: "FBu",
    symbolNative: "FBu",
    decimalDigits: 0,
    rounding: 0,
    code: "BIF",
    namePlural: "Burundian francs"
  },
  {
    name: "Brunei Dollar",
    symbol: "BN$",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "BND",
    namePlural: "Brunei dollars"
  },
  {
    name: "Bolivian Boliviano",
    symbol: "Bs",
    symbolNative: "Bs",
    decimalDigits: 2,
    rounding: 0,
    code: "BOB",
    namePlural: "Bolivian bolivianos"
  },
  {
    name: "Brazilian Real",
    symbol: "R$",
    symbolNative: "R$",
    decimalDigits: 2,
    rounding: 0,
    code: "BRL",
    namePlural: "Brazilian reals"
  },
  {
    name: "Botswanan Pula",
    symbol: "BWP",
    symbolNative: "P",
    decimalDigits: 2,
    rounding: 0,
    code: "BWP",
    namePlural: "Botswanan pulas"
  },
  {
    name: "Belarusian Ruble",
    symbol: "Br",
    symbolNative: "руб.",
    decimalDigits: 2,
    rounding: 0,
    code: "BYN",
    namePlural: "Belarusian rubles"
  },
  {
    name: "Belize Dollar",
    symbol: "BZ$",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "BZD",
    namePlural: "Belize dollars"
  },
  {
    name: "Congolese Franc",
    symbol: "CDF",
    symbolNative: "FrCD",
    decimalDigits: 2,
    rounding: 0,
    code: "CDF",
    namePlural: "Congolese francs"
  },
  {
    name: "Swiss Franc",
    symbol: "CHF",
    symbolNative: "CHF",
    decimalDigits: 2,
    rounding: 0.05,
    code: "CHF",
    namePlural: "Swiss francs"
  },
  {
    name: "Chilean Peso",
    symbol: "CL$",
    symbolNative: "$",
    decimalDigits: 0,
    rounding: 0,
    code: "CLP",
    namePlural: "Chilean pesos"
  },
  {
    name: "Chinese Yuan",
    symbol: "CN¥",
    symbolNative: "CN¥",
    decimalDigits: 2,
    rounding: 0,
    code: "CNY",
    namePlural: "Chinese yuan"
  },
  {
    name: "Colombian Peso",
    symbol: "CO$",
    symbolNative: "$",
    decimalDigits: 0,
    rounding: 0,
    code: "COP",
    namePlural: "Colombian pesos"
  },
  {
    name: "Costa Rican Colón",
    symbol: "₡",
    symbolNative: "₡",
    decimalDigits: 0,
    rounding: 0,
    code: "CRC",
    namePlural: "Costa Rican colóns"
  },
  {
    name: "Cape Verdean Escudo",
    symbol: "CV$",
    symbolNative: "CV$",
    decimalDigits: 2,
    rounding: 0,
    code: "CVE",
    namePlural: "Cape Verdean escudos"
  },
  {
    name: "Czech Republic Koruna",
    symbol: "Kč",
    symbolNative: "Kč",
    decimalDigits: 2,
    rounding: 0,
    code: "CZK",
    namePlural: "Czech Republic korunas"
  },
  {
    name: "Djiboutian Franc",
    symbol: "Fdj",
    symbolNative: "Fdj",
    decimalDigits: 0,
    rounding: 0,
    code: "DJF",
    namePlural: "Djiboutian francs"
  },
  {
    name: "Danish Krone",
    symbol: "Dkr",
    symbolNative: "kr",
    decimalDigits: 2,
    rounding: 0,
    code: "DKK",
    namePlural: "Danish kroner"
  },
  {
    name: "Dominican Peso",
    symbol: "RD$",
    symbolNative: "RD$",
    decimalDigits: 2,
    rounding: 0,
    code: "DOP",
    namePlural: "Dominican pesos"
  },
  {
    name: "Algerian Dinar",
    symbol: "DA",
    symbolNative: "د.ج.‏",
    decimalDigits: 2,
    rounding: 0,
    code: "DZD",
    namePlural: "Algerian dinars"
  },
  {
    name: "Estonian Kroon",
    symbol: "Ekr",
    symbolNative: "kr",
    decimalDigits: 2,
    rounding: 0,
    code: "EEK",
    namePlural: "Estonian kroons"
  },
  {
    name: "Egyptian Pound",
    symbol: "EGP",
    symbolNative: "ج.م.‏",
    decimalDigits: 2,
    rounding: 0,
    code: "EGP",
    namePlural: "Egyptian pounds"
  },
  {
    name: "Eritrean Nakfa",
    symbol: "Nfk",
    symbolNative: "Nfk",
    decimalDigits: 2,
    rounding: 0,
    code: "ERN",
    namePlural: "Eritrean nakfas"
  },
  {
    name: "Ethiopian Birr",
    symbol: "Br",
    symbolNative: "Br",
    decimalDigits: 2,
    rounding: 0,
    code: "ETB",
    namePlural: "Ethiopian birrs"
  },
  {
    name: "British Pound Sterling",
    symbol: "£",
    symbolNative: "£",
    decimalDigits: 2,
    rounding: 0,
    code: "GBP",
    namePlural: "British pounds sterling"
  },
  {
    name: "Georgian Lari",
    symbol: "GEL",
    symbolNative: "GEL",
    decimalDigits: 2,
    rounding: 0,
    code: "GEL",
    namePlural: "Georgian laris"
  },
  {
    name: "Ghanaian Cedi",
    symbol: "GH₵",
    symbolNative: "GH₵",
    decimalDigits: 2,
    rounding: 0,
    code: "GHS",
    namePlural: "Ghanaian cedis"
  },
  {
    name: "Guinean Franc",
    symbol: "FG",
    symbolNative: "FG",
    decimalDigits: 0,
    rounding: 0,
    code: "GNF",
    namePlural: "Guinean francs"
  },
  {
    name: "Guatemalan Quetzal",
    symbol: "GTQ",
    symbolNative: "Q",
    decimalDigits: 2,
    rounding: 0,
    code: "GTQ",
    namePlural: "Guatemalan quetzals"
  },
  {
    name: "Hong Kong Dollar",
    symbol: "HK$",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "HKD",
    namePlural: "Hong Kong dollars"
  },
  {
    name: "Honduran Lempira",
    symbol: "HNL",
    symbolNative: "L",
    decimalDigits: 2,
    rounding: 0,
    code: "HNL",
    namePlural: "Honduran lempiras"
  },
  {
    name: "Croatian Kuna",
    symbol: "kn",
    symbolNative: "kn",
    decimalDigits: 2,
    rounding: 0,
    code: "HRK",
    namePlural: "Croatian kunas"
  },
  {
    name: "Hungarian Forint",
    symbol: "Ft",
    symbolNative: "Ft",
    decimalDigits: 0,
    rounding: 0,
    code: "HUF",
    namePlural: "Hungarian forints"
  },
  {
    name: "Indonesian Rupiah",
    symbol: "Rp",
    symbolNative: "Rp",
    decimalDigits: 0,
    rounding: 0,
    code: "IDR",
    namePlural: "Indonesian rupiahs"
  },
  {
    name: "Israeli New Sheqel",
    symbol: "₪",
    symbolNative: "₪",
    decimalDigits: 2,
    rounding: 0,
    code: "ILS",
    namePlural: "Israeli new sheqels"
  },
  {
    name: "Indian Rupee",
    symbol: "Rs",
    symbolNative: "টকা",
    decimalDigits: 2,
    rounding: 0,
    code: "INR",
    namePlural: "Indian rupees"
  },
  {
    name: "Iraqi Dinar",
    symbol: "IQD",
    symbolNative: "د.ع.‏",
    decimalDigits: 0,
    rounding: 0,
    code: "IQD",
    namePlural: "Iraqi dinars"
  },
  {
    name: "Iranian Rial",
    symbol: "IRR",
    symbolNative: "﷼",
    decimalDigits: 0,
    rounding: 0,
    code: "IRR",
    namePlural: "Iranian rials"
  },
  {
    name: "Icelandic Króna",
    symbol: "Ikr",
    symbolNative: "kr",
    decimalDigits: 0,
    rounding: 0,
    code: "ISK",
    namePlural: "Icelandic krónur"
  },
  {
    name: "Jamaican Dollar",
    symbol: "J$",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "JMD",
    namePlural: "Jamaican dollars"
  },
  {
    name: "Jordanian Dinar",
    symbol: "JD",
    symbolNative: "د.أ.‏",
    decimalDigits: 3,
    rounding: 0,
    code: "JOD",
    namePlural: "Jordanian dinars"
  },
  {
    name: "Japanese Yen",
    symbol: "¥",
    symbolNative: "￥",
    decimalDigits: 0,
    rounding: 0,
    code: "JPY",
    namePlural: "Japanese yen"
  },
  {
    name: "Kenyan Shilling",
    symbol: "Ksh",
    symbolNative: "Ksh",
    decimalDigits: 2,
    rounding: 0,
    code: "KES",
    namePlural: "Kenyan shillings"
  },
  {
    name: "Cambodian Riel",
    symbol: "KHR",
    symbolNative: "៛",
    decimalDigits: 2,
    rounding: 0,
    code: "KHR",
    namePlural: "Cambodian riels"
  },
  {
    name: "Comorian Franc",
    symbol: "CF",
    symbolNative: "FC",
    decimalDigits: 0,
    rounding: 0,
    code: "KMF",
    namePlural: "Comorian francs"
  },
  {
    name: "South Korean Won",
    symbol: "₩",
    symbolNative: "₩",
    decimalDigits: 0,
    rounding: 0,
    code: "KRW",
    namePlural: "South Korean won"
  },
  {
    name: "Kuwaiti Dinar",
    symbol: "KD",
    symbolNative: "د.ك.‏",
    decimalDigits: 3,
    rounding: 0,
    code: "KWD",
    namePlural: "Kuwaiti dinars"
  },
  {
    name: "Kazakhstani Tenge",
    symbol: "KZT",
    symbolNative: "тңг.",
    decimalDigits: 2,
    rounding: 0,
    code: "KZT",
    namePlural: "Kazakhstani tenges"
  },
  {
    name: "Lebanese Pound",
    symbol: "LB£",
    symbolNative: "ل.ل.‏",
    decimalDigits: 0,
    rounding: 0,
    code: "LBP",
    namePlural: "Lebanese pounds"
  },
  {
    name: "Sri Lankan Rupee",
    symbol: "SLRs",
    symbolNative: "SL Re",
    decimalDigits: 2,
    rounding: 0,
    code: "LKR",
    namePlural: "Sri Lankan rupees"
  },
  {
    name: "Lithuanian Litas",
    symbol: "Lt",
    symbolNative: "Lt",
    decimalDigits: 2,
    rounding: 0,
    code: "LTL",
    namePlural: "Lithuanian litai"
  },
  {
    name: "Latvian Lats",
    symbol: "Ls",
    symbolNative: "Ls",
    decimalDigits: 2,
    rounding: 0,
    code: "LVL",
    namePlural: "Latvian lati"
  },
  {
    name: "Libyan Dinar",
    symbol: "LD",
    symbolNative: "د.ل.‏",
    decimalDigits: 3,
    rounding: 0,
    code: "LYD",
    namePlural: "Libyan dinars"
  },
  {
    name: "Moroccan Dirham",
    symbol: "MAD",
    symbolNative: "د.م.‏",
    decimalDigits: 2,
    rounding: 0,
    code: "MAD",
    namePlural: "Moroccan dirhams"
  },
  {
    name: "Moldovan Leu",
    symbol: "MDL",
    symbolNative: "MDL",
    decimalDigits: 2,
    rounding: 0,
    code: "MDL",
    namePlural: "Moldovan lei"
  },
  {
    name: "Malagasy Ariary",
    symbol: "MGA",
    symbolNative: "MGA",
    decimalDigits: 0,
    rounding: 0,
    code: "MGA",
    namePlural: "Malagasy Ariaries"
  },
  {
    name: "Macedonian Denar",
    symbol: "MKD",
    symbolNative: "MKD",
    decimalDigits: 2,
    rounding: 0,
    code: "MKD",
    namePlural: "Macedonian denari"
  },
  {
    name: "Myanma Kyat",
    symbol: "MMK",
    symbolNative: "K",
    decimalDigits: 0,
    rounding: 0,
    code: "MMK",
    namePlural: "Myanma kyats"
  },
  {
    name: "Macanese Pataca",
    symbol: "MOP$",
    symbolNative: "MOP$",
    decimalDigits: 2,
    rounding: 0,
    code: "MOP",
    namePlural: "Macanese patacas"
  },
  {
    name: "Mauritian Rupee",
    symbol: "MURs",
    symbolNative: "MURs",
    decimalDigits: 0,
    rounding: 0,
    code: "MUR",
    namePlural: "Mauritian rupees"
  },
  {
    name: "Mexican Peso",
    symbol: "MX$",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "MXN",
    namePlural: "Mexican pesos"
  },
  {
    name: "Malaysian Ringgit",
    symbol: "RM",
    symbolNative: "RM",
    decimalDigits: 2,
    rounding: 0,
    code: "MYR",
    namePlural: "Malaysian ringgits"
  },
  {
    name: "Mozambican Metical",
    symbol: "MTn",
    symbolNative: "MTn",
    decimalDigits: 2,
    rounding: 0,
    code: "MZN",
    namePlural: "Mozambican meticals"
  },
  {
    name: "Namibian Dollar",
    symbol: "N$",
    symbolNative: "N$",
    decimalDigits: 2,
    rounding: 0,
    code: "NAD",
    namePlural: "Namibian dollars"
  },
  {
    name: "Nigerian Naira",
    symbol: "₦",
    symbolNative: "₦",
    decimalDigits: 2,
    rounding: 0,
    code: "NGN",
    namePlural: "Nigerian nairas"
  },
  {
    name: "Nicaraguan Córdoba",
    symbol: "C$",
    symbolNative: "C$",
    decimalDigits: 2,
    rounding: 0,
    code: "NIO",
    namePlural: "Nicaraguan córdobas"
  },
  {
    name: "Norwegian Krone",
    symbol: "Nkr",
    symbolNative: "kr",
    decimalDigits: 2,
    rounding: 0,
    code: "NOK",
    namePlural: "Norwegian kroner"
  },
  {
    name: "Nepalese Rupee",
    symbol: "NPRs",
    symbolNative: "नेरू",
    decimalDigits: 2,
    rounding: 0,
    code: "NPR",
    namePlural: "Nepalese rupees"
  },
  {
    name: "New Zealand Dollar",
    symbol: "NZ$",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "NZD",
    namePlural: "New Zealand dollars"
  },
  {
    name: "Omani Rial",
    symbol: "OMR",
    symbolNative: "ر.ع.‏",
    decimalDigits: 3,
    rounding: 0,
    code: "OMR",
    namePlural: "Omani rials"
  },
  {
    name: "Panamanian Balboa",
    symbol: "B/.",
    symbolNative: "B/.",
    decimalDigits: 2,
    rounding: 0,
    code: "PAB",
    namePlural: "Panamanian balboas"
  },
  {
    name: "Peruvian Nuevo Sol",
    symbol: "S/.",
    symbolNative: "S/.",
    decimalDigits: 2,
    rounding: 0,
    code: "PEN",
    namePlural: "Peruvian nuevos soles"
  },
  {
    name: "Philippine Peso",
    symbol: "₱",
    symbolNative: "₱",
    decimalDigits: 2,
    rounding: 0,
    code: "PHP",
    namePlural: "Philippine pesos"
  },
  {
    name: "Pakistani Rupee",
    symbol: "PKRs",
    symbolNative: "₨",
    decimalDigits: 0,
    rounding: 0,
    code: "PKR",
    namePlural: "Pakistani rupees"
  },
  {
    name: "Polish Zloty",
    symbol: "zł",
    symbolNative: "zł",
    decimalDigits: 2,
    rounding: 0,
    code: "PLN",
    namePlural: "Polish zlotys"
  },
  {
    name: "Paraguayan Guarani",
    symbol: "₲",
    symbolNative: "₲",
    decimalDigits: 0,
    rounding: 0,
    code: "PYG",
    namePlural: "Paraguayan guaranis"
  },
  {
    name: "Qatari Rial",
    symbol: "QR",
    symbolNative: "ر.ق.‏",
    decimalDigits: 2,
    rounding: 0,
    code: "QAR",
    namePlural: "Qatari rials"
  },
  {
    name: "Romanian Leu",
    symbol: "RON",
    symbolNative: "RON",
    decimalDigits: 2,
    rounding: 0,
    code: "RON",
    namePlural: "Romanian lei"
  },
  {
    name: "Serbian Dinar",
    symbol: "din.",
    symbolNative: "дин.",
    decimalDigits: 0,
    rounding: 0,
    code: "RSD",
    namePlural: "Serbian dinars"
  },
  {
    name: "Russian Ruble",
    symbol: "RUB",
    symbolNative: "₽.",
    decimalDigits: 2,
    rounding: 0,
    code: "RUB",
    namePlural: "Russian rubles"
  },
  {
    name: "Rwandan Franc",
    symbol: "RWF",
    symbolNative: "FR",
    decimalDigits: 0,
    rounding: 0,
    code: "RWF",
    namePlural: "Rwandan francs"
  },
  {
    name: "Saudi Riyal",
    symbol: "SR",
    symbolNative: "ر.س.‏",
    decimalDigits: 2,
    rounding: 0,
    code: "SAR",
    namePlural: "Saudi riyals"
  },
  {
    name: "Sudanese Pound",
    symbol: "SDG",
    symbolNative: "SDG",
    decimalDigits: 2,
    rounding: 0,
    code: "SDG",
    namePlural: "Sudanese pounds"
  },
  {
    name: "Swedish Krona",
    symbol: "Skr",
    symbolNative: "kr",
    decimalDigits: 2,
    rounding: 0,
    code: "SEK",
    namePlural: "Swedish kronor"
  },
  {
    name: "Singapore Dollar",
    symbol: "S$",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "SGD",
    namePlural: "Singapore dollars"
  },
  {
    name: "Somali Shilling",
    symbol: "Ssh",
    symbolNative: "Ssh",
    decimalDigits: 0,
    rounding: 0,
    code: "SOS",
    namePlural: "Somali shillings"
  },
  {
    name: "Syrian Pound",
    symbol: "SY£",
    symbolNative: "ل.س.‏",
    decimalDigits: 0,
    rounding: 0,
    code: "SYP",
    namePlural: "Syrian pounds"
  },
  {
    name: "Thai Baht",
    symbol: "฿",
    symbolNative: "฿",
    decimalDigits: 2,
    rounding: 0,
    code: "THB",
    namePlural: "Thai baht"
  },
  {
    name: "Tunisian Dinar",
    symbol: "DT",
    symbolNative: "د.ت.‏",
    decimalDigits: 3,
    rounding: 0,
    code: "TND",
    namePlural: "Tunisian dinars"
  },
  {
    name: "Tongan Paʻanga",
    symbol: "T$",
    symbolNative: "T$",
    decimalDigits: 2,
    rounding: 0,
    code: "TOP",
    namePlural: "Tongan paʻanga"
  },
  {
    name: "Turkish Lira",
    symbol: "TL",
    symbolNative: "TL",
    decimalDigits: 2,
    rounding: 0,
    code: "TRY",
    namePlural: "Turkish Lira"
  },
  {
    name: "Trinidad and Tobago Dollar",
    symbol: "TT$",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "TTD",
    namePlural: "Trinidad and Tobago dollars"
  },
  {
    name: "New Taiwan Dollar",
    symbol: "NT$",
    symbolNative: "NT$",
    decimalDigits: 2,
    rounding: 0,
    code: "TWD",
    namePlural: "New Taiwan dollars"
  },
  {
    name: "Tanzanian Shilling",
    symbol: "TSh",
    symbolNative: "TSh",
    decimalDigits: 0,
    rounding: 0,
    code: "TZS",
    namePlural: "Tanzanian shillings"
  },
  {
    name: "Ukrainian Hryvnia",
    symbol: "₴",
    symbolNative: "₴",
    decimalDigits: 2,
    rounding: 0,
    code: "UAH",
    namePlural: "Ukrainian hryvnias"
  },
  {
    name: "Ugandan Shilling",
    symbol: "USh",
    symbolNative: "USh",
    decimalDigits: 0,
    rounding: 0,
    code: "UGX",
    namePlural: "Ugandan shillings"
  },
  {
    name: "Uruguayan Peso",
    symbol: "$U",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "UYU",
    namePlural: "Uruguayan pesos"
  },
  {
    name: "Uzbekistan Som",
    symbol: "UZS",
    symbolNative: "UZS",
    decimalDigits: 0,
    rounding: 0,
    code: "UZS",
    namePlural: "Uzbekistan som"
  },
  {
    name: "Venezuelan Bolívar",
    symbol: "Bs.F.",
    symbolNative: "Bs.F.",
    decimalDigits: 2,
    rounding: 0,
    code: "VEF",
    namePlural: "Venezuelan bolívars"
  },
  {
    name: "Vietnamese Dong",
    symbol: "₫",
    symbolNative: "₫",
    decimalDigits: 0,
    rounding: 0,
    code: "VND",
    namePlural: "Vietnamese dong"
  },
  {
    name: "CFA Franc BEAC",
    symbol: "FCFA",
    symbolNative: "FCFA",
    decimalDigits: 0,
    rounding: 0,
    code: "XAF",
    namePlural: "CFA francs BEAC"
  },
  {
    name: "CFA Franc BCEAO",
    symbol: "CFA",
    symbolNative: "CFA",
    decimalDigits: 0,
    rounding: 0,
    code: "XOF",
    namePlural: "CFA francs BCEAO"
  },
  {
    name: "Yemeni Rial",
    symbol: "YR",
    symbolNative: "ر.ي.‏",
    decimalDigits: 0,
    rounding: 0,
    code: "YER",
    namePlural: "Yemeni rials"
  },
  {
    name: "South African Rand",
    symbol: "R",
    symbolNative: "R",
    decimalDigits: 2,
    rounding: 0,
    code: "ZAR",
    namePlural: "South African rand"
  },
  {
    name: "Zambian Kwacha",
    symbol: "ZK",
    symbolNative: "ZK",
    decimalDigits: 0,
    rounding: 0,
    code: "ZMK",
    namePlural: "Zambian kwachas"
  },
  {
    name: "Zimbabwean Dollar",
    symbol: "ZWL$",
    symbolNative: "ZWL$",
    decimalDigits: 0,
    rounding: 0,
    code: "ZWL",
    namePlural: "Zimbabwean Dollar"
  }
]

export const CountryData = [
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
  },
  {
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
  },

  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
  },
  {
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    name: "Reunion",
    dial_code: "+262",
    code: "RE",
  },
  {
    name: "Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    name: "Venezuela",
    dial_code: "+58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
  },
  {
    name: "Western Sahara",
    dial_code: "+212",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "VG",
  },
  {
    name: "Eswatini",
    dial_code: "+268",
  },
  {
    name: "Lao People Democratic Republic",
    dial_code: "+856",
  },
  {
    name: "French Southern Territories",
    dial_code: "+262 ",
    code: "ZM",
  },
  {
    name: "Curacao",
    dial_code: "+599",
    code: "ZW",
  },
];